import "./styles/AdminCatalog.css"
import Table from "../../../ui/table/Table"
import adminCatalogColumns from "./adminCatalogColumns"
import React, { FC, useEffect, useState } from "react"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import { CatalogProps, Release } from "../../../../types"
import {
  fetchCatalogData,
  selectCatalogFilter,
  updateReleaseLabelCamp,
  updateReleaseStatus,
  deleteRelease
} from "../../../../actions/catalogActions"
import { RELEASE_FILTER, successFilledToastOptions, USER_ROLE } from "../../../../constants"
import { toast } from "react-toastify"
import CatalogFilterComponents from "../../catalog/CatalogFilterComponents"
import { KButton, KInput, KSpan, KTooltip } from "kahuna-base-react-components"
//@ts-ignore
import UpcScanIcon from "../../../ui/img/navigate/upc-scan.svg"
import ForceUploadModal from "./modals/ForceUploadModal"

const AdminCatalog: FC<CatalogProps> = (props) => {
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState<string>("")
  const [searchUpcText, setSearchUpcText] = useState<string>("")
  const [searchArtistText, setSearchArtistText] = useState<string>("")
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [searchUpcOpen, setSearchUpcOpen] = useState(false)
  const [searchArtistOpen, setSearchArtistOpen] = useState(false)
  const [selectedReleaseToUpload, setSelectedReleaseToUpload] = useState<Release | undefined>(undefined)

  const { selectCatalogFilter, selectedCatalogFilter } = props

  const isAdmin = props.loggedInUser?.roleId === USER_ROLE.SuperAdmin

  const onClickLabelCampCheckbox = (id: number, checked: boolean, title: string) => {
    if (window.confirm(`Are you sure you want ${title} to be ${checked ? "VISIBLE" : "NOT VISIBLE"} to users`)) {
      props.updateReleaseLabelCamp(id, checked).then((resp) => {
        if (!resp) {
          alert("Error updating visibilty!")
        }
      })
    }
  }

  const onSelectStatus = (id: number, status: string, title: string) => {
    if (window.confirm(`Are you sure you want to change status to ${status.toUpperCase()} for ${title} `)) {
      props.updateReleaseStatus(id, status).then((resp) => {
        if (!resp) {
          alert("Error updating status!")
        }
      })
    }
  }

  const onDelete = (id: number, title: string) => {
    if (window.confirm(`Are you sure you want to delete for ${title} `)) {
      props.deleteRelease(id).then((resp) => {
        if (resp) {
          toast.success("Successfully Deleted Release.", successFilledToastOptions)
        } else {
          alert("Error delete release!")
        }
      })
    }
  }

  const onSubmitBmv = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "bmv" })
  }

  const onSubmitMesamMsg = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "mesam_msg" })
  }

  const onSubmitEditorialPitching = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "editorial_pitching" })
  }

  const onSubmitState51 = (release: Release) => {
    setSelectedReleaseToUpload({ ...release, forceUploadType: "state_51" })
  }

  const columns = adminCatalogColumns(
    onClickLabelCampCheckbox,
    onSelectStatus,
    onDelete,
    onSubmitBmv,
    onSubmitMesamMsg,
    onSubmitEditorialPitching,
    onSubmitState51,
    isAdmin
  )

  const limit = 10

  useEffect(() => {
    const timer = setTimeout(() => {
      selectCatalogFilter(isAdmin ? [] : ["active"], RELEASE_FILTER.ALL, [])
    }, 100)
    return () => clearTimeout(timer)
  }, [])

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    await props.fetchCatalogData(limit, (pageNumber - 1) * limit, 0, selectedCatalogFilter)
    setLoading(false)
    setPageNumber(pageNumber)
  }

  useEffect(() => {
    const fetchSync = async () => {
      setLoading(true)
      await props.fetchCatalogData(limit, 0, 0, selectedCatalogFilter)
    }
    fetchSync().then(() => setLoading(false))
  }, [selectedCatalogFilter])

  const onSearchRelease = (search: string, searchKey: string) => {
    if (searchKey === "title") {
      setSearchText(search)
      setSearchUpcText("")
      setSearchArtistText("")
    } else if (searchKey === "upc") {
      setSearchUpcText(search)
      setSearchText("")
      setSearchArtistText("")
    } else if (searchKey === "artist") {
      setSearchUpcText("")
      setSearchText("")
      setSearchArtistText(search)
    }

    clearTimeout(timeoutId)
    setTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        setLoading(true)
        await props.fetchCatalogData(
          limit,
          (pageNumber - 1) * limit,
          0,
          selectedCatalogFilter,
          searchKey === "title" ? search : undefined,
          searchKey === "upc" ? search : undefined,
          searchKey === "artist" ? search : undefined
        )
        setLoading(false)
      }, 800)
    )
  }

  const renderFilters = () => (
    <div className="flex gap-3">
      {searchUpcOpen ? (
        <div
          style={{ width: 250 }}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              setSearchUpcOpen(false)
            }
          }}
        >
          <KInput
            onChange={(value) => onSearchRelease(value, "upc")}
            value={searchUpcText}
            placeholder="Search Upc.."
            leftIcon="/catalog_icons/search-grey.svg"
            padding="14px"
            background="#F7F7F7"
            hoverBackground="#fff"
          />
        </div>
      ) : (
        <KTooltip
          content={
            <div className="p-1">
              <KSpan text="UPC Search" />
            </div>
          }
          position="bottom"
          children={
            <KButton
              leftIcon={UpcScanIcon}
              background="#F7F7F7"
              onClick={() => setSearchUpcOpen(true)}
              hoverBackground="white"
              padding="14px"
              width="auto"
              height="auto"
            />
          }
        />
      )}
      {searchArtistOpen ? (
        <div
          style={{ width: 250 }}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              setSearchArtistOpen(false)
            }
          }}
        >
          <KInput
            onChange={(value) => onSearchRelease(value, "artist")}
            value={searchArtistText}
            placeholder="Search Artist.."
            leftIcon="/catalog_icons/search-grey.svg"
            padding="14px"
            background="#F7F7F7"
            hoverBackground="#fff"
          />
        </div>
      ) : (
        <KTooltip
          content={
            <div className="p-1">
              <KSpan text="Artist Search" />
            </div>
          }
          position="bottom"
          children={
            <KButton
              leftIcon={"/account_icons/user-search.svg"}
              background="#F7F7F7"
              onClick={() => setSearchArtistOpen(true)}
              hoverBackground="white"
              padding="14px"
              width="auto"
              height="auto"
            />
          }
        />
      )}

      <CatalogFilterComponents
        newReleasePermission={isAdmin}
        filterDataBySearchText={(value) => onSearchRelease(value, "title")}
      />
    </div>
  )

  return (
    <div className="catalog flex-grow pl-1">
      <div className="mt-[56px] mb-16">
        <Table
          title="Releases"
          pagination
          minRowCount={limit}
          limit={limit}
          columns={columns}
          data={props.paginatedReleases.results}
          count={props.paginatedReleases.count}
          topRightElement={renderFilters()}
          onClickPage={onClickPage}
          rowHeightInPx={40}
          loading={loading}
        />
      </div>
      <ForceUploadModal release={selectedReleaseToUpload} setRelease={setSelectedReleaseToUpload} />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    loggedInUser: state.auth.user,
    paginatedReleases: state.catalogData.paginatedReleases,
    selectedCatalogFilter: state.catalogData.selectedCatalogFilter
  }
}

export default connect(mapStateToProps, {
  fetchCatalogData,
  selectCatalogFilter,
  updateReleaseLabelCamp,
  updateReleaseStatus,
  deleteRelease
})(AdminCatalog)
