import * as React from "react"
import { Field, Form } from "react-final-form"
import { renderInput } from "../../../ui/form/Input"
import { Mail, X } from "heroicons-react"
import Button from "../../../ui/form/Button"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { FC, useEffect, useState } from "react"
import { UserForm, AdminUserFormProps, UserExpenseForm, Affiliate } from "../../../../types"
import adminUserValidator from "./adminUserValidator"
import adminUserExpenseValidator from "./adminUserExpenseValidator"

import {
  fetchUserExpenseData,
  paymentMethodsAdminEntry,
  saveUser,
  saveUserExpenseData,
  selectUser,
  fetchAffiliateList
} from "../../../../actions/adminActions"
import {
  tableCellClasses,
  USER_ROLE,
  userRoleOptions,
  superAdminSelectOption,
  errorFilledToastOptions,
  successFilledToastOptions,
  USER_PERMISSION
} from "../../../../constants"
import { renderSearchSelect } from "../../../ui/form/select/SearchSelect"
import AdminPaymentMethodModal from "./AdminPaymentMethodModal"
import { renderToggle } from "../../../ui/form/Toggle"
import AdminAffiliateFromUserModal from "./AdminAffiliateFromUserModal"
import AdminAffiliateToUserModal from "./AdminAffiliateToUserModal"
import { toast } from "react-toastify"
import { affiliate, users } from "../../../../apis"
import { mapListToSnakeCase } from "../../../../utility"
import { KSpan, KTooltip } from "kahuna-base-react-components"
import { fetchUserData } from "../../../../actions"
import { useNavigate } from "react-router-dom"
import AdminUserCreditModal from "./AdminUserCreditModal"
import AdminNotificationSendModal from "./AdminNotificationSendModal"
import AdminUserAdvanceModal from "./AdminUserAdvanceModal"

enum TabOptions {
  USER_INFORMATION,
  USER_EXPENSE,
  ADMIN
}

const TaxTypeOptions = [
  { value: 1, label: "KDV" },
  { value: 2, label: "Stopaj" }
]
const PaymentPeriodTypeOptions = [
  { value: 1, label: "Quarterly" },
  { value: 2, label: "Monthly" }
]
const CurrencyTypeOptions = [
  { value: 1, label: "TRY" },
  { value: 2, label: "USD" },
  { value: 3, label: "EUR" },
  { value: 4, label: "GBP" }
]

const ToggleField = ({ label, permissionId, submitting }) => {
  return (
    <div className="flex gap-4 mt-3">
      <label>
        <span className="text-sm text-gray-700 mt-1 mr-3">{label}</span>
        <Field name="permissionIds">
          {({ input }) => {
            const handleChange = () => {
              const currentPermissions = input.value || []
              const newPermissions = currentPermissions.includes(permissionId)
                ? currentPermissions.filter((id) => id !== permissionId)
                : [...currentPermissions, permissionId]

              input.onChange(newPermissions)
            }

            return renderToggle({
              checked: input.value.includes(permissionId),
              type: "checkbox",
              onChange: handleChange,
              disabled: submitting,
              name: permissionId
            })
          }}
        </Field>
      </label>
    </div>
  )
}

const AdminUserForm: FC<AdminUserFormProps> = (props) => {
  const { refreshSummaryForUser, setRefreshSummaryForUser } = props

  if (!props.user) return null

  const [selectedTab, setSelectedTab] = useState(TabOptions.USER_INFORMATION)
  const [paymentModalOpen, setPaymentModalOpen] = useState(false)
  const [userCreditModalOpen, setUserCreditModalOpen] = useState(false)
  const [userAdvanceModalOpen, setUserAdvanceModalOpen] = useState(false)
  const [affiliateFromModalOpen, setAffiliateFromModalOpen] = useState(false)
  const [affiliateToModalOpen, setAffiliateToModalOpen] = useState(false)
  const [notificationSendModalOpen, setNotificationSendModalOpen] = useState(false)

  const isSuperUser = props.loggedInUser?.roleId === USER_ROLE.SuperAdmin

  const navigate = useNavigate()

  useEffect(() => {
    if (isSuperUser && selectedTab === TabOptions.USER_EXPENSE && props.user.id) {
      props.fetchUserExpenseData(props.user.id)
    }
    if (isSuperUser && selectedTab === TabOptions.ADMIN && props.user.id) {
      props.fetchAffiliateList(props.user.id)
    }
  }, [selectedTab, props.user.id])

  let roleOptions
  if (props.loggedInUser?.roleId === USER_ROLE.SuperAdmin) roleOptions = userRoleOptions
  if (props.loggedInUser?.roleId !== USER_ROLE.SuperAdmin && props.user.roleId !== USER_ROLE.SuperAdmin) {
    roleOptions = userRoleOptions.filter((option) => option.value !== USER_ROLE.SuperAdmin)
  }
  if (props.loggedInUser?.roleId !== USER_ROLE.SuperAdmin && props.user?.roleId === USER_ROLE.SuperAdmin) {
    roleOptions = superAdminSelectOption
  }
  const onFormSubmit = async (values: UserForm) => {
    if (
      values.permissionIds &&
      values.permissionIds.includes(USER_PERMISSION.UPLOAD_MESAM) &&
      values.permissionIds.includes(USER_PERMISSION.UPLOAD_MSG)
    ) {
      toast.error("Choose one: Mesam / Msg", errorFilledToastOptions)
      return
    }

    props.saveUser(values)
  }

  const onUserExpenseFormSubmit = async (values: UserExpenseForm) => {
    props.saveUserExpenseData(values)
  }

  const createUserFormFromUser = (): UserForm => {
    return {
      id: props.user.id,
      username: props.user.username,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      password: "",
      roleId: props.user.roleId,
      permissionIds: props.user.permissionUser?.map((pu) => pu.permission.id),
      lcUsername: props.user.lcUsername,
      lcPassword: "",
      sendEmail: false,
      bmwCustomId: props.user.bmwCustomId,
      validateEmail: props.user.validateEmail
    }
  }

  const onClickCross = () => {
    props.selectUser(null)
  }

  const borderBottom = { borderBottom: "1px solid #374151" }
  const cursorPointer = { cursor: "pointer" }

  const renderUserForm = () => {
    return (
      <Form
        initialValues={createUserFormFromUser()}
        onSubmit={onFormSubmit}
        validate={adminUserValidator}
        render={({ handleSubmit, pristine, submitting, form }) => (
          <>
            <form
              className="w-full max-w-sm m-auto flex-column p-4"
              onSubmit={handleSubmit}
              style={{ paddingBottom: 8 }}
            >
              <div className="h-[auto] w-[350px]">
                <div className="flex flex-row gap-4 mb-2">
                  <Field name="firstName" render={renderInput} placeholder="First name" disabled={submitting} />
                  <Field name="lastName" render={renderInput} placeholder="Last name" disabled={submitting} />
                </div>
                <Field
                  name="username"
                  render={renderInput}
                  placeholder="Username"
                  className={"mb-4"}
                  autoComplete="new-password"
                  inlineElement={<Mail />}
                  disabled={submitting}
                />
                <Field
                  name="password"
                  render={renderInput}
                  placeholder="Password"
                  className={"mb-4"}
                  type={"text"}
                  autoComplete="new-password"
                  disabled={submitting}
                />
                <div className={"mb-4 relative"}>
                  <Field
                    name="roleId"
                    render={renderSearchSelect}
                    placeholder="Select user type"
                    disabled={submitting}
                    options={roleOptions}
                  />
                </div>
                <div className="mb-2">
                  <span style={{ fontWeight: 400 }}>LABELCAMP CREDENTIALS</span>
                </div>
                <Field
                  name="lcUsername"
                  render={renderInput}
                  placeholder="Label Camp Username"
                  className={"mb-4"}
                  inlineElement={<Mail />}
                  disabled={submitting}
                />
                <Field
                  name="lcPassword"
                  render={renderInput}
                  placeholder="Label Camp Password"
                  className={"mb-4"}
                  type={"text"}
                  autoComplete="new-password"
                  disabled={submitting}
                />
                <div
                  className="mt-4 flex items-center pb-2 pt-2"
                  style={{ borderTop: "1px dashed grey", borderBottom: "1px dashed grey" }}
                >
                  <KSpan text="Email Validation on Authentication" />
                  <Field
                    name="validateEmail"
                    render={renderToggle}
                    className={"ml-2"}
                    disabled={submitting}
                    type="checkbox"
                  />
                </div>
                <div className="flex justify-between">
                  <ToggleField label="New Release" permissionId={USER_PERMISSION.NEW_RELEASE} submitting={submitting} />
                  <ToggleField label="Get Advance" permissionId={USER_PERMISSION.GET_ADVANCE} submitting={submitting} />
                </div>

                <div className="flex mt-2 justify-between">
                  <ToggleField label="Upload MSG" permissionId={USER_PERMISSION.UPLOAD_MSG} submitting={submitting} />
                  <ToggleField
                    label="Upload MESAM"
                    permissionId={USER_PERMISSION.UPLOAD_MESAM}
                    submitting={submitting}
                  />
                </div>

                <div className="flex mt-2 justify-between">
                  <ToggleField label="Upload BMV" permissionId={USER_PERMISSION.UPLOAD_BMV} submitting={submitting} />
                  <ToggleField
                    label="Upload State51"
                    permissionId={USER_PERMISSION.UPLOAD_STATE51}
                    submitting={submitting}
                  />
                </div>

                {form.getState().values.permissionIds?.includes(USER_PERMISSION.UPLOAD_BMV) && (
                  <KTooltip
                    backgroundColor={"#595959"}
                    width="300px"
                    children={
                      <Field
                        name="bmwCustomId"
                        render={renderInput}
                        placeholder="BMV Custom Id (Min: 2, Max: 3)"
                        className={"mt-4"}
                        disabled={submitting}
                      />
                    }
                    content={
                      <KSpan
                        color="white"
                        text={"BMV Custom Id (Min: 2, Max: 3). Replace with X in example => X_TESTT_Nsax...."}
                      />
                    }
                  />
                )}

                {!props.user.id && (
                  <div
                    className="mt-4 flex items-center pb-2 pt-2"
                    style={{ borderTop: "1px dashed grey", borderBottom: "1px dashed grey" }}
                  >
                    <KSpan text="Send Credentials Via Email" />
                    <Field
                      name="sendEmail"
                      render={renderToggle}
                      className={"ml-2"}
                      disabled={submitting}
                      type="checkbox"
                    />
                  </div>
                )}
              </div>
              <Button className="mt-8" text="Save" disabled={submitting || pristine} />
            </form>
          </>
        )}
      />
    )
  }

  const renderUserExpenseForm = () => {
    if (!props.selectedUserExpense) {
      return undefined
    }

    return (
      <Form
        initialValues={props.selectedUserExpense}
        onSubmit={onUserExpenseFormSubmit}
        validate={adminUserExpenseValidator}
        render={({ handleSubmit, pristine, submitting }) => (
          <>
            <form className="w-full max-w-sm m-auto flex-column p-4" onSubmit={handleSubmit}>
              <div className="w-[350px]">
                <div className="flex flex-row gap-4 mb-2">
                  <div>
                    <p className="text-gray-700">Admin Fee</p>
                    <Field name="adminFee" render={renderInput} placeholder="AF" disabled={submitting} />
                  </div>
                  <div>
                    <p className="text-gray-700">Distribution Fee</p>
                    <Field name="distributionFee" render={renderInput} placeholder="DF" disabled={submitting} />
                  </div>
                </div>
                <div className="flex flex-row gap-4 mb-4">
                  <div style={{ width: "50%" }}>
                    <p className="text-gray-700">Tax Type</p>
                    <div className="relative">
                      <Field
                        name="taxType"
                        render={renderSearchSelect}
                        placeholder="Select Tax Type"
                        disabled={submitting}
                        options={TaxTypeOptions}
                      />
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <p className="text-gray-700">Preferred Currency</p>
                    <div className="relative">
                      <Field
                        name="preferredCurrency"
                        render={renderSearchSelect}
                        placeholder="Select Curreny Type"
                        disabled={submitting}
                        options={CurrencyTypeOptions}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-gray-700">Payment Period Type</p>
                <div className="relative">
                  <Field
                    name="paymentPeriod"
                    render={renderSearchSelect}
                    placeholder="Select Curreny Type"
                    disabled={submitting}
                    options={PaymentPeriodTypeOptions}
                  />
                </div>
                <div className="mt-4 mb-8">
                  <p className="text-gray-700">Publishing Kahuna Share</p>
                  <Field name="publishingKahunaShare" render={renderInput} placeholder="5" disabled={submitting} />
                </div>
              </div>
              <Button className="mb-0" text="Save" disabled={submitting || pristine} />
            </form>
          </>
        )}
      />
    )
  }

  const saveAffiliate = async (affiliateList: Affiliate[]) => {
    if (!props.user.id) {
      return
    }

    try {
      const response = await affiliate(`/`, "post", {
        from_user_id: props.user.id,
        affiliate_list: mapListToSnakeCase(affiliateList)
      })
      if (response.status === 200) {
        toast.success("Affiliate saved successfully", successFilledToastOptions)
        setAffiliateFromModalOpen(false)
        props.fetchAffiliateList(props.user.id)
      } else {
        toast.error("Error saving affiliate", errorFilledToastOptions)
      }
    } catch (err) {
      toast.error("Error saving affiliate", errorFilledToastOptions)
    }
  }

  const onChangeUser = () => {
    users(`/change_user/?user_id=${props.user.id}`, "get")
      .then((resp) => {
        if (resp?.data?.token) {
          localStorage.setItem("token", resp.data.token)
          props.fetchUserData().then(() => {
            navigate("/")
          })
        } else {
          toast.error("Error chaging account", errorFilledToastOptions)
        }
      })
      .catch((err) => toast.error("Error chaging account", errorFilledToastOptions))
  }

  const renderAdminForm = () => {
    return (
      <div className="p-4">
        <AdminPaymentMethodModal
          open={paymentModalOpen}
          onClose={() => setPaymentModalOpen(false)}
          paymentMethodsAdminEntry={props.paymentMethodsAdminEntry}
          userId={props.user.id}
        />
        <AdminUserCreditModal
          open={userCreditModalOpen}
          onClose={() => setUserCreditModalOpen(false)}
          userId={props.user.id}
        />
        <AdminUserAdvanceModal
          open={userAdvanceModalOpen}
          onClose={() => setUserAdvanceModalOpen(false)}
          userId={props.user.id}
        />
        <AdminAffiliateFromUserModal
          open={affiliateFromModalOpen}
          onClose={() => setAffiliateFromModalOpen(false)}
          user={props.user}
          saveAffiliate={saveAffiliate}
          affiliateList={props.affiliateFromList}
        />
        <AdminAffiliateToUserModal
          open={affiliateToModalOpen}
          onClose={() => setAffiliateToModalOpen(false)}
          affiliateList={props.affiliateToList}
        />
        <AdminNotificationSendModal
          open={notificationSendModalOpen}
          onClose={() => setNotificationSendModalOpen(false)}
          user={props.user}
        />
        <div className="w-[350px]">
          <Button className="mb-0" text="Payment Methods" onClick={() => setPaymentModalOpen(true)} />
        </div>
        <div className="w-[350px]">
          <Button className="mb-0" text="User Credits" onClick={() => setUserCreditModalOpen(true)} />
        </div>
        <div className="w-[350px]">
          <Button className="mb-0" text="User Advance" onClick={() => setUserAdvanceModalOpen(true)} />
        </div>
        <div className="w-[350px] mt-12">
          <Button
            className="mb-0"
            text={`Affiliate From List (${props.affiliateFromList?.length})`}
            onClick={() => setAffiliateFromModalOpen(true)}
          />
        </div>
        <div className="w-[350px] mt-3">
          <Button
            className="mb-0"
            text={`Affiliate To List (${props.affiliateToList?.length})`}
            onClick={() => setAffiliateToModalOpen(true)}
          />
        </div>
        {props.loggedInUser.roleId === USER_ROLE.SuperAdmin && (
          <div className="w-[350px] mt-12">
            <Button className="mb-0" text={`Go To Profile`} onClick={() => onChangeUser()} />
          </div>
        )}
        <div className="w-[350px] mt-3">
          <Button className="mb-0" text={`Send Notification`} onClick={() => setNotificationSendModalOpen(true)} />
        </div>
      </div>
    )
  }

  const renderTabSelection = () => {
    switch (selectedTab) {
      case TabOptions.USER_INFORMATION:
        return renderUserForm()
      case TabOptions.USER_EXPENSE:
        return renderUserExpenseForm()
      case TabOptions.ADMIN:
        return renderAdminForm()
      default:
        return <div></div>
    }
  }

  return (
    <div>
      <div>
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          checked={refreshSummaryForUser}
          onChange={() => {
            setRefreshSummaryForUser(!refreshSummaryForUser)
          }}
        />
        <label className="text-sm ml-2 text-gray-700">Refresh Summary For Selected User</label>
      </div>
      <div className="h-[auto] mt-4 bg-white elev-b-sm rounded-lg overflow-hidden">
        <div
          className={`bg-gray-50 text-xs text-gray-500 font-medium uppercase border-b flex justify-between ${tableCellClasses.sm}`}
        >
          {!isSuperUser ? (
            <p>User information</p>
          ) : (
            <>
              <p
                style={selectedTab === TabOptions.USER_INFORMATION ? borderBottom : cursorPointer}
                onClick={() => setSelectedTab(TabOptions.USER_INFORMATION)}
              >
                User information
              </p>
              <p> | </p>
              <p
                style={selectedTab === TabOptions.USER_EXPENSE ? borderBottom : cursorPointer}
                onClick={() => setSelectedTab(TabOptions.USER_EXPENSE)}
              >
                User Expense
              </p>
              <p> | </p>
              <p
                style={selectedTab === TabOptions.ADMIN ? borderBottom : cursorPointer}
                onClick={() => setSelectedTab(TabOptions.ADMIN)}
              >
                Admin
              </p>
            </>
          )}
          <X className="h-4 mr-[-6px] cursor-pointer" onClick={onClickCross} />
        </div>

        {renderTabSelection()}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.admin.selectedUser,
    loggedInUser: state.auth.user,
    selectedUserExpense: state.admin.selectedUserExpense,
    affiliateFromList: state.admin.affiliateFromList,
    affiliateToList: state.admin.affiliateToList
  }
}

export default connect(mapStateToProps, {
  selectUser,
  saveUser,
  fetchUserExpenseData,
  saveUserExpenseData,
  paymentMethodsAdminEntry,
  fetchAffiliateList,
  fetchUserData
})(AdminUserForm)
