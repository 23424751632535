import React, { useEffect, useState } from "react"
import { releases } from "../../../../apis"
import Table from "../../../ui/table/Table"
import releaseUpcCodesColumns from "./trackIsrcCodesColumns"
import { AssetRegistryIsrcCodes } from "../../../../types"
import { mapListToCamelCase, mapToCamelCase } from "../../../../utility"
import { KButton } from "kahuna-base-react-components"
import { errorFilledToastOptions } from "../../../../constants"
import { toast } from "react-toastify"

const TrackIsrcCodes = () => {
  const [loading, setLoading] = useState(false)
  const [isrcCodes, setIsrcCodes] = useState<AssetRegistryIsrcCodes[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [availableCount, setAvailableCount] = useState<number>(1)
  const [limit, setLimit] = useState(10)

  const onDelete = (id: number) => {
    if (!window.confirm(`Are you sure want to delete ID: ${id}?`)) {
      return
    }

    setLoading(true)

    releases(`/release/asset_registry_isrc_codes_delete/?id=${id}`, "delete").then((resp) => {
      if (resp.data.success) {
        setIsrcCodes(isrcCodes.filter((row) => row.id !== id))
      } else {
        toast.error(`Error: ${resp.data.message}`, errorFilledToastOptions)
      }
      setLoading(false)
    })
  }

  const onAddNew = () => {
    setLoading(true)
    const isrc = window.prompt("Please enter isrc code")
    releases(`/release/asset_registry_isrc_codes_add/?isrc=${isrc}`, "post").then((resp) => {
      if (resp.data.result) {
        setIsrcCodes([mapToCamelCase(resp.data.result), ...isrcCodes])
        setLoading(false)
      }
    })
  }

  const colums = releaseUpcCodesColumns(onDelete)

  useEffect(() => {
    setLoading(true)
    releases(`/release/asset_registry_isrc_codes/?limit=${limit}`, "get").then((resp) => {
      setIsrcCodes(mapListToCamelCase(resp.data.results))
      setCount(resp.data.count)
      setAvailableCount(resp.data.available)
      setLoading(false)
    })
  }, [])

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    releases(`/release/asset_registry_isrc_codes/?offset=${(pageNumber - 1) * limit}&limit=${limit}`, "get").then(
      (resp) => {
        setIsrcCodes(mapListToCamelCase(resp.data.results))
        setLoading(false)
        setPageNumber(pageNumber + 1)
      }
    )
  }

  return (
    <div className="catalog flex-grow pl-1">
      <div className="mt-8 flex justify-end">
        <KButton
          width="220px"
          text="+ Add New Isrc Code"
          onClick={() => onAddNew()}
          background="black"
          textColor="white"
        />
      </div>
      <div className="mb-16">
        <Table
          title={`Track Isrc Codes (${availableCount} / ${count})`}
          pagination
          minRowCount={limit}
          onClickPage={onClickPage}
          limit={limit}
          columns={colums}
          data={isrcCodes}
          count={count}
          rowHeightInPx={40}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default TrackIsrcCodes
