import { KButton, KInput, KLogo, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React, { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ArtistSmartLink, ArtistSmartLinkProps } from "../../../types"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import "./styles/smartlink.css"
import { smartLinks, users } from "../../../apis"
import CustomizePage from "./pages/CustomizePage"
import PublishPage from "./pages/PublishPage"
import { mapToCamelCase } from "../../../utility"
import { fetchUserData } from "../../../actions"
import { lang } from "../../../constants/languages"

export enum SmartLinkTabs {
  SOCIAL_MEDIA_LINKS = 1,
  RELEASES = 2,
  EVENTS = 3
}

export enum SmartLinkSteps {
  CUSTOMIZE = 1,
  PUBLISH = 2
}

const headerHeight = 88

const ArtistSmartLinkPage: FC<ArtistSmartLinkProps> = (props) => {
  const { user } = props

  const [selectedTab, setSelectedTab] = useState(SmartLinkTabs.SOCIAL_MEDIA_LINKS)
  const [selectedStep, setSelectedStep] = useState(SmartLinkSteps.CUSTOMIZE)
  const [artistSmartLink, setArtistSmartLink] = useState<ArtistSmartLink>()
  const [linkText, setLinkText] = useState("")

  const navigate = useNavigate()

  const fetchSmartLink = () => {
    users(`/smart_link/`, "get").then((resp) => {
      if (resp.data) {
        setArtistSmartLink(mapToCamelCase(resp.data))
        setLinkText(resp.data.link)
      }
    })
  }

  useEffect(() => {
    fetchSmartLink()
    props.fetchUserData()
  }, [])

  return (
    <div style={{ height: "100vh", backgroundColor: "#F0F0F0", padding: 10 }}>
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 2px 2px 0px lightgray"
        }}
      >
        <div className="grid" style={{ height: `${headerHeight}px` }}>
          <div
            className="flex pl-10 pr-10"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div style={{ textAlign: "left", display: "inline-block", cursor: "pointer" }} onClick={() => navigate(-1)}>
              <KLogo
                hoverEnabled={true}
                width={48}
                height={48}
                logoColor={"white"}
                borderRadius={1}
              />
            </div>
            <div>
              {linkText && artistSmartLink ? (
                <KButton
                  height="36px"
                  background="black"
                  fontWeight={300}
                  textColor="white"
                  text={lang.common.preview}
                  onClick={() => window.open(`https://artists.kahuna.io/artist/${linkText}`, "_blank")}
                />
              ) : (
                <span className="powered-by-span">Powered by Kahuna</span>
              )}
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-row items-start justify-center pb-4"
          style={{ width: "100%", height: `calc(100% - ${headerHeight}px)` }}
        >
          {selectedStep === SmartLinkSteps.CUSTOMIZE && (
            <CustomizePage
              linkText={linkText}
              setLinkText={setLinkText}
              selectedStep={selectedStep}
              setSelectedStep={setSelectedStep}
              artistSmartLink={artistSmartLink}
              setArtistSmartLink={setArtistSmartLink}
            />
          )}
          {selectedStep === SmartLinkSteps.PUBLISH && (
            <PublishPage selectedTab={selectedTab} setSelectedTab={setSelectedTab} user={user} isAdmin={true} />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user
  }
}

export default connect(mapStateToProps, { fetchUserData })(ArtistSmartLinkPage)
