import { KButton, KDropdown, KInput, KTextArea, KTitleSpan } from "kahuna-base-react-components"
import React, { useState } from "react"
import { Registration } from "../../../../types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { statusOptions } from "./registrationColumns"
import { REGISTERED_USER_TYPES } from "../../../../constants"

const RegistrationDetailModel = ({
  openModal,
  setOpenModal,
  meetDetails,
  description,
  setDescription,
  dbStatus,
  setDbStatus,
  saveRegistration,
  startTime
}: {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  meetDetails: Registration
  description: { [key: string]: string }
  setDescription: any
  dbStatus: { [key: string]: string }
  setDbStatus: any
  saveRegistration: (
    meetingId: string,
    description: string,
    db_status: string,
    type: string,
    start_time: string,
    created_at: string,
    name: string,
    country: string,
    email: string,
    phoneNumber: string,
    role: string,
    artists: string,
    hearingSource: string
  ) => void
  startTime: { [key: string]: string }
}) => {
  const [databaseStatus, setDatabaseStatus] = useState<string>(dbStatus[meetDetails.calendly_id])
  const [meetDetailsDescription, setMeetDetailsDescription] = useState<string>(description[meetDetails.calendly_id])

  return (
    <div className="fixed left-0 top-0 w-[100vw] h-[100vh] flex items-center justify-center">
      <div className="pt-8 pb-4 px-6 w-[500px] shadow-md rounded-[10px] bg-[#fff]">
        <div className="flex flex-col items-center gap-8">
          <div className="flex flex-col gap-8 justify-center">
            <KTitleSpan text={"Details"} fontSize={24} lineHeight="28px" color="#000" />
          </div>
          <div className="flex flex-col gap-4 items-center w-full ">
            <div className="flex flex-row gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <KTitleSpan text="Name:" fontSize={14} lineHeight="16px" />
                <KInput
                  value={meetDetails.name}
                  placeholder="Name"
                  padding="8px 14px"
                  disabled
                  activeBackground="#F5F5F5"
                  onChange={(value) => {
                    //do nothing
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <KTitleSpan text="Email:" fontSize={14} lineHeight="16px" />
                <KInput
                  value={meetDetails.email}
                  placeholder="Email"
                  padding="8px 14px"
                  disabled
                  activeBackground="#F5F5F5"
                  onChange={(value) => {
                    //do nothing
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <KTitleSpan text="Phone Number:" fontSize={14} lineHeight="16px" />
                <KInput
                  value={meetDetails.phone_number}
                  placeholder="Phone Number"
                  padding="8px 14px"
                  disabled
                  activeBackground="#F5F5F5"
                  onChange={(value) => {
                    //do nothing
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <KTitleSpan text="Country:" fontSize={14} lineHeight="16px" />
                <KInput
                  value={meetDetails.country}
                  placeholder="Country"
                  padding="8px 14px"
                  disabled
                  activeBackground="#F5F5F5"
                  onChange={(value) => {
                    //do nothing
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-2 w-full">
              <div className="flex flex-col gap-2 w-full">
                <KTitleSpan text="Role:" fontSize={14} lineHeight="16px" />
                <KInput
                  value={meetDetails.role}
                  placeholder="Role"
                  padding="8px 14px"
                  disabled
                  activeBackground="#F5F5F5"
                  onChange={(value) => {
                    // do nothing
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <KTitleSpan text="How to Hear About Us:" fontSize={14} lineHeight="16px" />
                <KInput
                  value={meetDetails.hearing_source}
                  placeholder="How to hear about us"
                  padding="8px 14px"
                  disabled
                  activeBackground="#F5F5F5"
                  onChange={(value) => {
                    //do nothing
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <KTitleSpan text="Artist(s):" fontSize={14} lineHeight="16px" />
              <KInput
                value={meetDetails.artists}
                placeholder="Artist(s)"
                padding="8px 14px"
                disabled
                activeBackground="#F5F5F5"
                onChange={(value) => {
                  // do nothing
                }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <KTitleSpan text="Admin notes:" fontSize={14} lineHeight="16px" />
              <KTextArea
                value={meetDetailsDescription}
                placeholder="Admin notes"
                padding="8px 14px"
                background="#FFF"
                onChange={(value) => {
                  setMeetDetailsDescription(value)
                }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <KTitleSpan text="Database Status:" fontSize={14} lineHeight="16px" />
              <KDropdown
                placeholder="DB Status"
                options={statusOptions}
                rightIcon="/analytics_icons/caret-down-new.svg"
                padding="8px 14px"
                background="#FFF"
                defaultValuePrimitive={dbStatus[meetDetails.calendly_id]}
                onSelect={(selected) => {
                  if (!selected) {
                    setDatabaseStatus("-")
                  } else {
                    setDatabaseStatus((selected as KSelectOption)?.value2 || "-")
                  }
                }}
              />
            </div>
            <div className="flex mt-8 gap-3" style={{ width: "100%" }}>
              <KButton
                text="Cancel"
                height="48px"
                onClick={() => {
                  setOpenModal(false)
                }}
                background="#fff"
                textColor="#000"
              />
              <KButton
                background="#000"
                textColor="#FFF"
                height="48px"
                text="Save"
                disabled={
                  dbStatus[meetDetails.calendly_id] === databaseStatus &&
                  description[meetDetails.calendly_id] === meetDetailsDescription
                }
                onClick={() => {
                  saveRegistration(
                    meetDetails.calendly_id,
                    meetDetailsDescription,
                    databaseStatus,
                    REGISTERED_USER_TYPES.DEMO,
                    startTime[meetDetails.calendly_id],
                    meetDetails.created_at,
                    meetDetails.name,
                    meetDetails.country,
                    meetDetails.email,
                    meetDetails.phone_number,
                    meetDetails.role,
                    meetDetails.artists,
                    meetDetails.hearing_source
                  )
                  setDescription({ ...description, [meetDetails.calendly_id]: meetDetailsDescription })
                  setDbStatus({ ...dbStatus, [meetDetails.calendly_id]: databaseStatus })
                  setOpenModal(false)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationDetailModel
