import { Registration, TableColumn } from "../../../../types"
import { REGISTERED_USER_TYPES, TABLE_COLUMN_TYPE } from "../../../../constants"
import React from "react"
import { convertToTurkeyTime } from "../../../../utility"
import { KButton, KDropdown, KSpan, KTextArea } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"

export const statusOptions: KSelectOption[] = [
  { label: "Subscribed", value: 0, value2: "subscribed" },
  { label: "Attended demo", value: 1, value2: "demo" }
]

const registrationColumns = (
  description: { [key: string]: string },
  setDescription: any,
  onClickDetails: (meetingId: string) => void,
  dbStatus: { [key: string]: string },
  setDbStatus: any,
  saveRegistration: (
    meetingId: string,
    description: string,
    db_status: string,
    type: string,
    start_time: string,
    created_at: string,
    name: string,
    country: string,
    email: string,
    phoneNumber: string,
    role: string,
    artists: string,
    hearingSource: string
  ) => void
): TableColumn[] => [
  {
    header: "ID",
    headerStyle: {
      textAlign: "left",
      width: "16%",
      paddingLeft: "8px !important",
      paddingRight: "8px !important"
    },
    cellStyle: {
      paddingLeft: "8px !important"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => <KSpan text={row.calendly_id} color="#000" />
  },
  {
    header: "Artist(s)",
    headerStyle: {
      textAlign: "left",
      width: "12%",
      paddingLeft: "8px !important"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => <KSpan text={row.artists} color="#000" />
  },
  {
    header: "Date",
    headerStyle: {
      textAlign: "left",
      width: "9%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return <KSpan text={convertToTurkeyTime(row.start_time)} color="#000" />
    }
  },
  {
    header: "Created",
    headerStyle: {
      textAlign: "left",
      width: "9%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return <KSpan text={convertToTurkeyTime(row.created_at)} color="#000" />
    }
  },
  {
    header: "Calendly Status",
    headerStyle: {
      textAlign: "left",
      width: "9%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return <KSpan text={row.calendly_status} color="#000" />
    }
  },
  {
    header: "DB Status",
    headerStyle: {
      textAlign: "left",
      width: "8%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      const defaultValue = dbStatus[row.calendly_id]
      return (
        <div
          key={`status-kdropdown-${dbStatus[row.calendly_id]}-${row.calendly_id}`}
          className="flex flex-col gap-2 w-full"
        >
          <KDropdown
            width={130}
            placeholder="DB Status"
            options={statusOptions}
            rightIcon="/analytics_icons/caret-down-new.svg"
            defaultValuePrimitive={defaultValue}
            padding="8px 14px"
            menuWidth={150}
            menuLeftMargin={-14}
            background={defaultValue === "demo" || defaultValue === "subscribed" ? "#FFF" : "#F5F5F5"}
            activeBackground="#FFF"
            onSelect={(selected) => {
              const new_db_status = (selected as KSelectOption)?.value2 || "-"
              setDbStatus({ ...dbStatus, [row.calendly_id]: new_db_status })
              saveRegistration(
                row.calendly_id,
                description[row.calendly_id],
                new_db_status,
                REGISTERED_USER_TYPES.DEMO,
                row.start_time,
                row.created_at,
                row.name,
                row.country,
                row.email,
                row.phone_number,
                row.role,
                row.artists,
                row.hearing_source
              )
            }}
            isEllipsis
          />
        </div>
      )
    }
  },
  {
    header: "Admin Notes",
    headerStyle: {
      textAlign: "left",
      width: "30%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <div>
          <KTextArea
            value={description[row.calendly_id]}
            onChange={(value: string) => {
              setDescription({ ...description, [row.calendly_id]: value })
            }}
            onBlur={() => {
              if (description[row.calendly_id] !== row.description) {
                saveRegistration(
                  row.calendly_id,
                  description[row.calendly_id],
                  dbStatus[row.calendly_id],
                  REGISTERED_USER_TYPES.DEMO,
                  row.start_time,
                  row.created_at,
                  row.name,
                  row.country,
                  row.email,
                  row.phone_number,
                  row.role,
                  row.artists,
                  row.hearing_source
                )
              }
            }}
          />
        </div>
      )
    }
  },
  {
    header: "",
    headerStyle: {
      textAlign: "left",
      width: "7%"
    },
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: Registration) => {
      return (
        <KButton
          text="Details"
          width="auto"
          background="#FFF"
          onClick={() => {
            onClickDetails(row.calendly_id)
          }}
        />
      )
    }
  }
]

export default registrationColumns
