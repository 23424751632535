import { KButton, KInput, KSpan, KTextArea, KTitleSpan } from "kahuna-base-react-components"
import React, { FC, useEffect, useState } from "react"
import { ArtistSmartLink, Event, Release, SocialMedia, User } from "../../../../types"
import "../styles/smartlink.css"
import { smartLinks, users } from "../../../../apis"
import { errorFilledToastOptions, successFilledToastOptions } from "../../../../constants"
import { toast } from "react-toastify"
import SocialMediaPage from "../tabs/SocialMediaPage"
import ReleasePage from "../tabs/ReleasePage"
import EventsPage from "../tabs/EventsPage"
import { mapListToCamelCase, mapToSnakeCase } from "../../../../utility"
import { lang } from "../../../../constants/languages"

export enum SmartLinkTabs {
  SOCIAL_MEDIA_LINKS = 1,
  RELEASES = 2,
  EVENTS = 3
}

type PublishPageProps = {
  selectedTab: number
  setSelectedTab: (selectedTab: number) => void
  user: User
  isAdmin: boolean
}

const PublishPage: FC<PublishPageProps> = (props) => {
  const { user, selectedTab, setSelectedTab, isAdmin } = props

  const [socialMediaList, setSocialMediaList] = useState<SocialMedia[]>([])
  const [eventList, setEventList] = useState<Event[]>([])
  const [releaseList, setReleaseList] = useState<Release[]>([])
  const [bio, setBio] = useState("")
  const [stageName, setStageName] = useState("")
  const [smallScreen, setSmallScreen] = useState<boolean>(false)

  useEffect(() => {
    if (user.bio) {
      setBio(user.bio)
    }

    if (user.stageName) {
      setStageName(user.stageName)
    } else {
      setStageName(`${user.firstName} ${user.lastName || ""}`.trim())
    }
  }, [])

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 850px)")
    const handleMediaChange = (e: MediaQueryListEvent) => setSmallScreen(e.matches)
    setSmallScreen(mediaQuery.matches)
    mediaQuery.addEventListener("change", handleMediaChange)

    return () => mediaQuery.removeEventListener("change", handleMediaChange)
  }, [])

  const fetchSocialMediaLinks = async () => {
    if (isAdmin) {
      const response = await users(`/social_media_links/`, "get")
      if (response.data.results) {
        setSocialMediaList(mapListToCamelCase(response.data.results))
      }
    } else {
      const response = await smartLinks(`/social_media_links/?user_id=${user.id}`)
      if (response.data.results) {
        setSocialMediaList(mapListToCamelCase(response.data.results))
      }
    }
  }

  const saveSocialMediaLink = async (socialMedia: SocialMedia) => {
    const response = await users(`/save_social_media/`, "post", mapToSnakeCase(socialMedia))
    if (response.data.results) {
      toast.success(lang.artist_space.success.link_add, successFilledToastOptions)
      setSocialMediaList(mapListToCamelCase(response.data.results))
    }
  }

  const deleteSocialMediaLink = async (id: number) => {
    const response = await users(`/delete_social_media/?id=${id}`, "delete")
    if (!response.data.success) {
      toast.error(lang.artist_space.error.error_on_deleting_event, errorFilledToastOptions)
    }

    if (response.data.success) {
      setSocialMediaList(socialMediaList.filter((socialMedia) => socialMedia.id !== id))
      toast.success(lang.artist_space.success.delete_social_media_link, successFilledToastOptions)
    }
  }

  const fetchEvents = async () => {
    if (isAdmin) {
      const response = await users(`/events/`, "get")
      if (response.data.results) {
        setEventList(mapListToCamelCase(response.data.results))
      }
    } else {
      const response = await smartLinks(`/events/?user_id=${user.id}`)
      if (response.data.results) {
        setEventList(mapListToCamelCase(response.data.results))
      }
    }
  }

  const saveEvent = async (event: Event) => {
    const response = await users(`/save_event/`, "post", {
      ...mapToSnakeCase(event),
      start_date: event.startDate,
      end_date: event.endDate
    })

    if (response.data.results) {
      setEventList(mapListToCamelCase(response.data.results))
      toast.success(lang.artist_space.success.event_add, successFilledToastOptions)
      return true
    }

    toast.error(lang.artist_space.error.error_on_saving_event, errorFilledToastOptions)
    return false
  }

  const deleteEvent = async (id: number) => {
    const response = await users(`/delete_event/?id=${id}`, "delete")

    if (!response.data.success) {
      toast.error(lang.artist_space.error.error_on_deleting_event, errorFilledToastOptions)
      return false
    }

    setEventList(eventList.filter((event) => event.id !== id))
    toast.success(lang.artist_space.success.delete_event, successFilledToastOptions)
    return true
  }

  const fetchReleases = async () => {
    if (isAdmin) {
      const response = await users(`/releases/`, "get")
      if (response.data.results) {
        setReleaseList(mapListToCamelCase(response.data.results))
      }
    } else {
      const response = await smartLinks(`/releases/?user_id=${user.id}`)
      if (response.data.results) {
        setReleaseList(mapListToCamelCase(response.data.results))
      }
    }
  }

  const onSaveBio = async () => {
    if (!bio || bio === user.bio) {
      return
    }

    const resp = await users(`/${user.id}/`, "put", mapToSnakeCase({ username: user.username, bio }))
    if (resp) {
      toast.success(lang.artist_space.success.bio_save, successFilledToastOptions)
    }
  }

  const onSaveStageName = async () => {
    if (!stageName || stageName === user.stageName) {
      return
    }

    const resp = await users(`/${user.id}/`, "put", mapToSnakeCase({ username: user.username, stageName }))
    if (resp) {
      toast.success(lang.artist_space.success.stage_name_saved, successFilledToastOptions)
    }
  }

  const renderNavigationPill = (iconPath: string, text: string, tab: number) => {
    return (
      <div
        className="flex group-hover:!bg-[#f0f0f0] cursor-pointer"
        onClick={() => setSelectedTab(tab)}
        style={{
          backgroundColor: tab === selectedTab ? "#f3f3f3" : "#ffffff",
          borderRadius: 10,
          padding: smallScreen ? "6px" : "8px 10px"
        }}
      >
        <img src={iconPath} style={{ width: 20, height: 20 }} className="mr-2" />
        <KSpan text={text} color={tab === selectedTab ? "#000" : "#666"} fontWeight={400} />
      </div>
    )
  }

  return (
    <div
      className="justify-start items-start w-[calc(67%+112px)] min-[1600px]:w-[calc(67%+112px)] max-[850px]:!w-full  max-[850px]:!px-6 p-6 px-20"
      style={{ height: "100%", overflowY: "scroll" }}
    >
      <div>
        <div className="inline-block relative w-auto">
        <img src={user.photo || "/account_icons/avatar.svg"} style={{ width: 80, height: 80, borderRadius: 980 }} />
        <img src="/account_icons/top_status.svg" style={{ position: "absolute", right: "-10px", top: "0px"  }} />
        </div>
        
        <div className="mt-1">
          {isAdmin ? (
            <div className="publish-stage-name pb-1">
              <KInput
                fontSize="40px"
                padding="0px"
                shadowDisabled={true}
                height={48}
                value={stageName}
                onChange={(value) => setStageName(value)}
                onBlur={() => onSaveStageName()}
                placeholder={lang.artist_space.stage_name_placeholder}
              />
            </div>
          ) : (
            <KTitleSpan text={stageName} fontSize={40} lineHeight="48px" letterSpacing="-0.4px" />
          )}
        </div>
        <div className="mt-2">
          {isAdmin ? (
            <KTextArea
              value={bio}
              onChange={(value) => setBio(value)}
              placeholder={lang.artist_space.bio_placeholder}
              boxShadow={"0px"}
              width={580}
              padding="0px"
              background="white"
              onBlur={() => onSaveBio()}
            />
          ) : (
            <KSpan text={bio} />
          )}
        </div>
      </div>
      <div className="flex mt-6 gap-2">
        {renderNavigationPill(
          "/navigation_icons/profile_dropdownmenu_icons/link-unlink-filled.svg",
          lang.artist_space.platforms_title,
          SmartLinkTabs.SOCIAL_MEDIA_LINKS
        )}
        {renderNavigationPill("/navigation_icons/music.svg", lang.artist_space.albums_title, SmartLinkTabs.RELEASES)}
        {renderNavigationPill("/account_icons/user-music-filled.svg", lang.artist_space.events_title, SmartLinkTabs.EVENTS)}
      </div>
      {selectedTab === SmartLinkTabs.SOCIAL_MEDIA_LINKS && (
        <SocialMediaPage
          socialMediaList={socialMediaList}
          fetchSocialMediaLinks={fetchSocialMediaLinks}
          saveSocialMediaLink={saveSocialMediaLink}
          deleteSocialMediaLink={deleteSocialMediaLink}
          smallScreen={smallScreen}
          isAdmin={isAdmin}
        />
      )}
      {selectedTab === SmartLinkTabs.RELEASES && (
        <ReleasePage
          releaseList={releaseList}
          smallScreen={smallScreen}
          isAdmin={isAdmin}
          fetchReleases={fetchReleases}
        />
      )}
      {selectedTab === SmartLinkTabs.EVENTS && (
        <EventsPage
          eventList={eventList}
          fetchEvents={fetchEvents}
          saveEvent={saveEvent}
          deleteEvent={deleteEvent}
          smallScreen={smallScreen}
          isAdmin={isAdmin}
        />
      )}
    </div>
  )
}

export default PublishPage
