import React, { useEffect, useState } from "react"
import { KInput, KSpan } from "kahuna-base-react-components"
import { saveUserData } from "../../../../actions"
import Title from "../Title"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import { User } from "../../../../types"
import { lang } from "../../../../constants/languages"

const CopyrightAndProduction = ({ saveUserData, user }: { saveUserData: (values: User) => void; user: User }) => {
  const [initialUser, setInitialUser] = useState<User | null>(user)
  const [myUser, setMyUser] = useState<User>(user)

  const currentYear = new Date().getFullYear()

  const onBlurEvent = async (values: User) => {
    if (initialUser === myUser) {
      return
    }

    setInitialUser(myUser)
    saveUserData(values)
  }

  useEffect(() => {
    setMyUser(user)
  }, [user])

  return (
    <div className="flex flex-col gap-6 pb-6">
      <div>
        <Title
          sectionTitle={lang.account.copyright_and_production.copyright}
          sectionDetail="Edit your social media links"
          iconPath="/account_icons/link.svg"
        />
      </div>
      <div className="w-full flex flex-row gap-[16px] flex-wrap max-[1200px]:!flex-col">
        <div className="w-[calc(48%)] max-[1200px]:!w-[99%]">
          <KInput
            value={myUser.copyrightHolder}
            placeholder={lang.account.copyright_and_production.copyright_holder_placeholder}
            onChange={(value) => {
              setMyUser((prev) => {
                return { ...prev, copyrightHolder: value }
              })
            }}
            onBlur={() => {
              onBlurEvent(myUser)
            }}
            padding="14px"
          />
        </div>
        <div
          className="w-[calc(48%)] flex items-center max-[1200px]:!w-[99%]"
          style={{
            borderRadius: 10,
            background: "var(--bg-weak-100, #F0F0F0)",
            padding: 14
          }}
        >
          <KSpan text={currentYear.toString()} />
        </div>
      </div>
      <div className="mt-4">
        <Title
          sectionTitle={lang.account.copyright_and_production.production}
          sectionDetail="Edit your social media links"
          iconPath="/account_icons/link.svg"
        />
      </div>
      <div className="w-full flex flex-row gap-[16px] flex-wrap max-[1200px]:!flex-col">
        <div className="w-[calc(48%)] max-[1200px]:!w-[99%]">
          <KInput
            placeholder={lang.account.copyright_and_production.production_holder_placeholder}
            value={myUser.productionHolder}
            onChange={(value) => {
              setMyUser((prev) => {
                return { ...prev, productionHolder: value }
              })
            }}
            onBlur={() => {
              onBlurEvent(myUser)
            }}
            padding="14px"
          />
        </div>
        <div
          className="w-[calc(48%)] flex items-center max-[1200px]:!w-[99%]"
          style={{
            borderRadius: 10,
            background: "var(--bg-weak-100, #F0F0F0)",
            padding: 14
          }}
        >
          <KSpan text={currentYear.toString()} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps, { saveUserData })(CopyrightAndProduction)
