import LoginLayout from "../LoginLayout"
import { connect } from "react-redux"
import { ForgotPasswordFormValues, FormPageProps } from "../../../../types"
import { forgotPassword } from "../../../../actions/loginActions"
import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
//@ts-ignore
import EmailIcon from "../../../ui/img/auth/e-mail.svg"
import { lang } from "../../../../constants/languages"

const ForgotPassword = (props: FormPageProps<ForgotPasswordFormValues>) => {
  const [form, setForm] = useState<ForgotPasswordFormValues>({ email: "", ipAddress: "" })
  const email = new URLSearchParams(window.location.search).get("email")
  const navigate = useNavigate()

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json")
      setForm({ ...form, ipAddress: res.data.ip, email: email || "" })
    }

    getData().then()
  }, [])

  useEffect(() => {
    setForm({ ...form, email: email || "" })
  }, [email])

  const onSubmit = () => {
    props.onSubmit(form, () => navigate(`/forgot-password/${form.email}`))
  }

  return (
    <LoginLayout>
      <div className={"grid align-middle justify-center items-center"} style={{ width: "100%", height: "90%" }}>
        <div className={"grid"}>
          <div className={"flex"}>
            <KTitleSpan text={lang.forgot_password.title} />
          </div>
          <div className={"flex mt-4"}>
            <KSpan text={lang.forgot_password.desc} />
          </div>
          <div className={"mt-8"}>
            <KInput
              value={form.email || ""}
              onChange={(text) => setForm({ ...form, email: text })}
              placeholder={lang.forgot_password.email_placeholder}
              leftIcon={EmailIcon}
            />
          </div>
          <div className={"mt-6"}>
            <KButton
              onClick={() => onSubmit()}
              text={lang.forgot_password.reset_password_button}
              background="#111"
              textColor="white"
            />
            <div className="mt-2">
              <KButton
                shadowDisabled={true}
                background="transparent"
                onClick={() => navigate(`/login?username=${form.email}`)}
                text={lang.forgot_password.return_to_login_button}
              />
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

export default connect(null, { onSubmit: forgotPassword })(ForgotPassword)
