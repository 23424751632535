import { Action, Dispatch } from "redux"
import { handleAuthorizedRequest, notifications } from "../apis"
import { errorFilledToastOptions } from "../constants"
import { toast } from "react-toastify"
import { lang } from "../constants/languages"
import { mapPaginatedDataToCamelCase } from "../utility"
import { RootState } from "../store"
import { UserNotification } from "../types"

export const NOTIFICATIONS_ACTION = {
  FETCH_UNREAD_NOTIFICATIONS_NUMBER: "FETCH_UNREAD_NOTIFICATIONS_NUMBER",
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS"
}

export const getUnreadNotificationsNumber = () => async (dispatch: Dispatch<Action>) => {
  return await handleAuthorizedRequest(
    async () => {
      const response = await notifications("/get_unread_count/", "get")
      if (!(response.status === 200)) {
        toast.error(lang.notifications.error_on_fetching_unread_notifications_number, errorFilledToastOptions)
        return false
      }

      dispatch({
        type: NOTIFICATIONS_ACTION.FETCH_UNREAD_NOTIFICATIONS_NUMBER,
        payload: response.data
      })

      return true
    },
    lang.notifications.error_on_fetching_unread_notifications_number,
    dispatch
  )
}

export const getNotifications =
  (pageNumber: number) => async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const limit = 5
    return await handleAuthorizedRequest(
      async () => {
        const response = await notifications(
          `/get_notifications/?limit=${limit}&offset=${(pageNumber - 1) * limit}`,
          "get"
        )
        if (!(response.status === 200)) {
          toast.error(lang.notifications.error_on_fetching_notifications, errorFilledToastOptions)
          return false
        }

        const prevState = getState().notifications.paginatedNotifications

        const updatedNotifications =
          pageNumber === 1
            ? { ...response.data }
            : { ...prevState, results: [...prevState.results, ...response.data.results], count: response.data.count }

        dispatch({
          type: NOTIFICATIONS_ACTION.FETCH_NOTIFICATIONS,
          payload: mapPaginatedDataToCamelCase<UserNotification>(updatedNotifications)
        })

        return true
      },
      lang.notifications.error_on_fetching_notifications,
      dispatch
    )
  }

export const markAsRead =
  (id: string, readAll?: boolean) => async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    return await handleAuthorizedRequest(
      async () => {
        const data = !readAll ? { id, read_all: false } : { read_all: true }

        const response = await notifications(`/mark_as_read/`, "put", data)
        if (!(response.status === 200)) {
          toast.error(lang.notifications.error_on_marking_as_read, errorFilledToastOptions)
          return false
        }

        const prevState = getState().notifications.paginatedNotifications

        const updatedNotifications = [...prevState.results].map((notification) => {
          if (readAll) {
            return { ...notification, read: true }
          } else if (notification.id.toString() === id) {
            return { ...notification, read: true }
          } else {
            return notification
          }
        })

        dispatch({
          type: NOTIFICATIONS_ACTION.FETCH_NOTIFICATIONS,
          payload: mapPaginatedDataToCamelCase<UserNotification>({ ...prevState, results: updatedNotifications })
        })

        return true
      },
      lang.notifications.error_on_marking_as_read,
      dispatch
    )
  }
