import { Dialog } from "@headlessui/react"
import OwnerCard from "./OwnerCard"
import StatusCard from "./StatusCard"
import React, { useEffect, useState } from "react"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { RELEASE_FILTER } from "../../../constants"
import { selectCatalogFilter } from "../../../actions/catalogActions"
import { connect } from "react-redux"
import { RootState } from "../../../store"
import { CatalogFilter } from "../../../types"
import { releases } from "../../../apis"
import { lang } from "../../../constants/languages"

enum TabOptionEnum {
  VIEW,
  FILTER
}

const FilterOptionsDialog = ({
  openFilters,
  setOpenFilters,
  selectCatalogFilter,
  selectedCatalogFilter
}: {
  openFilters: boolean
  setOpenFilters: React.Dispatch<React.SetStateAction<boolean>>
  selectedCatalogFilter: CatalogFilter
  selectCatalogFilter: (choosenStatus: string[], ownerStatus: string, choosenGenre: string[]) => void
}) => {
  const [choosenStatus, setChoosenStatus] = useState<string[]>(selectedCatalogFilter.choosenStatus)
  const [ownerStatus, setOwnerStatus] = useState<string>(selectedCatalogFilter.ownerStatus)
  const [selectedTab, setSelectedTab] = useState(TabOptionEnum.VIEW)
  const [genreList, setGenreList] = useState([])
  const [selectedGenreList, setSelectedGenreList] = useState<string[]>(selectedCatalogFilter.choosenGenre)
  const [genreSearchText, setGenreSeachText] = useState<string>("")
  const [filteredGenreList, setFilteredGenreList] = useState([])

  useEffect(() => {
    if (openFilters) {
      releases(`/release/genre_list/`, "get").then((resp) => {
        setGenreList(resp.data)
        setFilteredGenreList(resp.data)
      })
      setChoosenStatus(selectedCatalogFilter.choosenStatus)
      setOwnerStatus(selectedCatalogFilter.ownerStatus)
      setSelectedGenreList(selectedCatalogFilter.choosenGenre)
    }
  }, [openFilters])

  const renderView = () => {
    return (
      <div className="flex flex-col gap-4 px-6">
        <div className="flex flex-row gap-4">
          <OwnerCard
            iconPath="/catalog_icons/music-album.svg"
            text={lang.catalog.filter_components.dialog_all_title}
            ownerStatus={ownerStatus}
            setOwnerStatus={setOwnerStatus}
            releaseFilter={RELEASE_FILTER.ALL}
          />
          <OwnerCard
            iconPath="/catalog_icons/user-circle.svg"
            text={lang.catalog.filter_components.dialog_owned_by_me_title}
            ownerStatus={ownerStatus}
            setOwnerStatus={setOwnerStatus}
            releaseFilter={RELEASE_FILTER.OWNER}
          />
          <OwnerCard
            iconPath="/catalog_icons/users.svg"
            text={lang.catalog.filter_components.dialog_owned_by_others_title}
            ownerStatus={ownerStatus}
            setOwnerStatus={setOwnerStatus}
            releaseFilter={RELEASE_FILTER.CONTRIBUTOR}
          />
        </div>
      </div>
    )
  }

  const selectGenre = (genre: string) => {
    if (selectedGenreList.includes(genre)) {
      setSelectedGenreList([...selectedGenreList.filter((selectedGenre) => selectedGenre !== genre)])
    } else {
      setSelectedGenreList([...selectedGenreList, genre])
    }
  }

  const onSearchGenre = (text: string) => {
    if (!text) {
      setFilteredGenreList(genreList)
    }
    setGenreSeachText(text)
    const filteredList = genreList.filter((item: string) => item?.toLowerCase().includes(text?.toLowerCase()))
    setFilteredGenreList(filteredList)
  }

  const renderFilter = () => {
    return (
      <div className="flex flex-col gap-4 px-6">
        <KTitleSpan text={lang.catalog.filter_components.dialog_status_title} fontSize={20} lineHeight="28px" />
        <div className="flex flex-row gap-4 mb-6">
          <StatusCard
            text={lang.catalog.release_status.active}
            value={"active"}
            choosenStatus={choosenStatus}
            setChoosenStatus={setChoosenStatus}
          />
          <StatusCard
            text={lang.catalog.release_status.reviewing}
            value={"reviewing"}
            choosenStatus={choosenStatus}
            setChoosenStatus={setChoosenStatus}
          />
          <StatusCard
            text={lang.catalog.release_status.draft}
            value={"draft"}
            choosenStatus={choosenStatus}
            setChoosenStatus={setChoosenStatus}
          />
          <StatusCard
            text={lang.catalog.release_status.archived}
            value={"archived"}
            choosenStatus={choosenStatus}
            setChoosenStatus={setChoosenStatus}
          />
        </div>
        <KTitleSpan text={lang.catalog.filter_components.dialog_genre_title} fontSize={20} lineHeight="28px" />
        <KInput
          onChange={(text) => onSearchGenre(text)}
          value={genreSearchText}
          placeholder={lang.catalog.filter_components.dialog_genre_search_text_placeholder}
          leftIcon="/search-new.svg"
          background="#f5f5f5"
          hoverBackground="#fff"
          padding="14px"
        />
        <div className="flex flex-wrap gap-2 overflow-x-auto w-full" style={{ maxHeight: "120px" }}>
          {filteredGenreList
            .filter((genre) => genre !== null && genre !== "")
            .map((genre) => (
              <div
                key={`genre_selection_${genre}`}
                className={`flex items-center rounded-[10px] cursor-pointer border-[1px] border-[#F3F3F3] px-3 py-2 ${
                  selectedGenreList.includes(genre) ? "bg-[#000]" : "bg-[#fff]"
                }`}
                onClick={() => selectGenre(genre)}
              >
                <KSpan text={genre} color={!selectedGenreList.includes(genre) ? "#000" : "#fff"} fontSize={12} lineHeight="16px" fontWeight={500} />
              </div>
            ))}
        </div>
      </div>
    )
  }

  const renderTab = () => {
    if (selectedTab === TabOptionEnum.VIEW) {
      return renderView()
    } else if (selectedTab === TabOptionEnum.FILTER) {
      return renderFilter()
    }
  }

  return (
    <Dialog open={openFilters} onClose={() => setOpenFilters(false)}>
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="py-6 shadow-md rounded-[10px] bg-[#fff] flex flex-col gap-5" style={{ width: 750 }}>
          <div className="flex justify-center">
            <div className="flex justify-center p-1 gap-1" style={{ backgroundColor: "#F5F5F5", borderRadius: 10 }}>
              <KButton
                text={lang.catalog.filter_components.dialog_view_button}
                onClick={() => setSelectedTab(TabOptionEnum.VIEW)}
                height="36px"
                borderRadius={8}
                background={selectedTab === TabOptionEnum.VIEW ? "white" : "transparent"}
                shadowDisabled={true}
                textColor={selectedTab === TabOptionEnum.VIEW ? "#111111" : "#989898"}
              />
              <KButton
                text={lang.catalog.filter_components.dialog_filter_button}
                onClick={() => setSelectedTab(TabOptionEnum.FILTER)}
                height="36px"
                borderRadius={8}
                background={selectedTab === TabOptionEnum.FILTER ? "white" : "transparent"}
                shadowDisabled={true}
                textColor={selectedTab === TabOptionEnum.FILTER ? "#111111" : "#989898"}
              />
            </div>
          </div>
          {renderTab()}
          <div className="flex flex-row w-full items-center justify-between px-6 pt-6">
            <KButton
              text={lang.catalog.filter_components.dialog_clear_all_button}
              width="auto"
              textColor="#666"
              shadowDisabled={true}
              onClick={() => {
                setChoosenStatus([])
                setOwnerStatus(RELEASE_FILTER.ALL)
                setSelectedGenreList([])
              }}
              background="#fff"
            />
            <div className="flex gap-4 items-center">
              <KButton
                background="transparent"
                shadowDisabled={true}
                text={lang.catalog.filter_components.dialog_close_button}
                width="auto"
                onClick={() => setOpenFilters(false)}
              />
              <KButton
                text={lang.catalog.filter_components.dialog_apply_filters_button}
                width="auto"
                height="48px"
                background="black"
                textColor="white"
                onClick={() => {
                  selectCatalogFilter(choosenStatus, ownerStatus, selectedGenreList)
                  setOpenFilters(false)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedCatalogFilter: state.catalogData.selectedCatalogFilter
  }
}

export default connect(mapStateToProps, { selectCatalogFilter })(FilterOptionsDialog)
