import { SortParams, TableProps } from "../../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { tableCellClasses, tableTitleClasses } from "../../../../constants"
import { Filter, FilterOutline } from "heroicons-react"
import { isEqual } from "lodash"
import AnalysisListComponent from "./AnalysisListComponent"
import { KSpan } from "kahuna-base-react-components"
import { lang } from "../../../../constants/languages"

const AnalysisTable: FC<TableProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    minRowCount = 10,
    rowHeightInPx,
    count,
    onClickPage,
    loading,
    onSelectRow,
    setTableAPI,
    hidden,
    onSortOrFilterChange,
    additionalFilters,
    inactiveRows,
    platform
  } = props

  interface Data {
    quarter: string
    revenue: number
    total_payment: number
    currency: string
    index: number
    payment_day?: string[]
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>()
  const [sortParams, setSortParams] = useState<SortParams>(new SortParams())
  const [filtersShown, setFiltersShown] = useState(false)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const [filteredData, setFilteredData] = useState<Data[]>(data)
  const additionalFiltersRef = useRef(additionalFilters)

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      onSortOrFilterChange(newFilters, sortParams.field, sortParams.direction, false).then()
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  if (hidden) return null

  return (
    <div className={`${className}`}>
      <div className="flex flex-row justify-between">
        <div className={`flex font-medium font-sans text-gray-900 mt-2  ${tableTitleClasses[size]}`}>
          <KSpan
            text={title!.toString()}
            fontSize={18}
            fontWeight={500}
            lineHeight="24px"
            letterSpacing="-0.27px"
            color="#111"
          ></KSpan>
          {columns.some((col) => col.filter) && (
            <span onClick={() => setFiltersShown(!filtersShown)}>
              {filtersShown ? (
                <Filter className={"inline h-3 mb-[2px] cursor-pointer"} />
              ) : (
                <FilterOutline className={"inline h-3 mb-[2px] cursor-pointer"} />
              )}
            </span>
          )}
        </div>

        {topRightElement}
      </div>
      <div className="mt-4 bg-white elev-b-sm rounded-lg w-full overflow-hidden !shadow-none">
        <div className="flex flex-row justify-between items-center h-[52px] py-2 px-4">
          <div className="flex w-[60%] py-2 items-center justify-start">
            <KSpan text={lang.overview.analysis_top_songs.song} fontWeight={500} color="#000" />
          </div>
          <div className="flex w-[20%] py-2 items-center justify-center">
            <KSpan text={lang.overview.analysis_top_songs.streams} fontWeight={500} color="#000" />
          </div>
          <div className="flex w-[20%] py-2 items-center justify-center">
            <KSpan text={lang.overview.analysis_top_songs.change} fontWeight={500} color="#000" />
          </div>
        </div>
        <ul>

          {!loading &&
            data?.map((row, i) => {
              return <AnalysisListComponent key={`list-component-${i}`} i={i} row={row} platform={platform} />
            })}
          {minRowCount &&
            minRowCount >= filteredData?.length &&
            Array(loading ? minRowCount : minRowCount - data?.length)
              .fill(0)
              .map((e, i) => i + 1)
              .map((row) => (
                <li key={`additional-row-${row}`} className="rounded-lg flex flex-row">
                  {columns.map((col, j) => {
                    return (
                      <span
                        style={col.cellStyle}
                        className={`${tableCellClasses[size]} rounded-t-lg `}
                        key={`additional-data-cell-${row}-${j}`}
                      >
                        <div className={`h-[${rowHeightInPx || 40}px]`}>
                          {loading && (
                            <div className="flex flex-row items-center justify-start h-full space-x-5 animate-pulse">
                              <div style={{ width: "100%" }} className={`h-[20px] bg-gray-300 rounded-md`} />
                            </div>
                          )}
                        </div>
                      </span>
                    )
                  })}
                </li>
              ))}
        </ul>
      </div>
    </div>
  )
}

export default AnalysisTable
