import { KInput, KButton, KTooltip, KSpan } from "kahuna-base-react-components"
import React, { useState } from "react"
import FilterOptionsDialog from "./FilterOptionsDialog"
import { useNavigate } from "react-router-dom"
import { lang } from "../../../constants/languages"

const CatalogFilterComponents = ({
  filterDataBySearchText,
  newReleasePermission
}: {
  filterDataBySearchText: (searchText: string) => void
  newReleasePermission: boolean
}) => {
  const [searchText, setSearchText] = useState<string>("")

  const handleChange = (newValue: string) => {
    setSearchText(newValue)
    filterDataBySearchText(newValue)
  }

  const [openFilters, setOpenFilters] = useState<boolean>(false)
  const [searchOpen, setSearchOpen] = useState(false)

  const navigate = useNavigate()

  return (
    // TODO: icon missing in platform selection dropdown list
    <>
      <div className="flex flex-row justify-between gap-3 items-center pr-3">
        {searchOpen ? (
          <div
            style={{ width: 250 }}
            onKeyDown={(event) => {
              if (event.key === "Escape") {
                setSearchOpen(false)
              }
            }}
          >
            <KInput
              onChange={handleChange}
              value={searchText}
              placeholder={lang.catalog.filter_components.search_text_placeholder}
              leftIcon="/catalog_icons/search-grey.svg"
              padding="14px"
              background="#F7F7F7"
              hoverBackground="#fff"
            />
          </div>
        ) : (
          <KButton
            leftIcon="/catalog_icons/search-filled.svg"
            background="#F7F7F7"
            onClick={() => setSearchOpen(true)}
            hoverBackground="#F3F3F3"
            padding="14px"
            width="auto"
            height="auto"
          />
        )}

        <KButton
          leftIcon="/catalog_icons/filters.svg"
          background="#F7F7F7"
          onClick={() => {
            setOpenFilters(true)
          }}
          hoverBackground="#F3F3F3"
          padding="14px"
          width="auto"
          height="auto"
        />

        {newReleasePermission && (
          <KButton
            leftIcon="/catalog_icons/plus-filled.svg"
            background="#F7F7F7"
            onClick={() => navigate(`/catalog/new-release`)}
            hoverBackground="#F3F3F3"
            width="auto"
            height="auto"
            padding="14px"
          />
        )}
      </div>
      <FilterOptionsDialog openFilters={openFilters} setOpenFilters={setOpenFilters}></FilterOptionsDialog>
    </>
  )
}

export default CatalogFilterComponents
