import { AnalysisSummary, TableColumn } from "../../../../../types"
import { chartLabels, TABLE_COLUMN_TYPE } from "../../../../../constants"
import { CSSProperties } from "react"

const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }
const cellStyle: CSSProperties = { fontWeight: 400, lineHeight: "20px", color: "#111827" }

const userColumn = {
  header: "User",
  type: TABLE_COLUMN_TYPE.TEXT,
  attribute: "user",
  headerStyle,
  cellStyle,
  sortable: true,
  sortFilterField: "user__username"
}

const labelColumn = {
  header: "Label",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) =>
    Object.keys(chartLabels)
      .map((key) => chartLabels[key][row.label])
      .filter((l) => !!l)[0] || row.label,
  headerStyle,
  cellStyle
}

const currencyColumn = {
  header: "Currency",
  type: TABLE_COLUMN_TYPE.TEXT,
  attribute: "currency",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "currency"
}

const grossColumn = {
  header: "Gross",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => row.totalGross.toFixed(2),
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_gross"
}

const splitGrossColumn = {
  header: "Split Gross",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return Number(row.totalAmount).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_amount"
}

const splitNetColumn = {
  header: "Split Net",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return Number(row.totalNetAll).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_net_all"
}

const totalApprovedColumn = {
  header: "Approved Total",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return Number(row.totalApproved).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_approved"
}

const kahunaShareColumn = {
  header: "Kahuna Share",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return (Number(row.totalGross) - Number(row.totalNet)).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_approved"
}

const advanceTotalColumn = {
  header: "Advance Total",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return Number(row.advanceTotal).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "advance_total"
}

const remainingAdvanceTotalColumn = {
  header: "Remaining Advance",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return (Number(row.advanceTotal) - Number(row.paidTotal)).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "paid_total"
}

const shareColumn = {
  header: "Share",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return Number((row.totalAmount / row.totalGross) * 100).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: false
}

const approvedColumn = {
  header: "Reports Approved",
  type: TABLE_COLUMN_TYPE.BOOL,
  attribute: "approved",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "approved"
}

const paymentApprovedColumn = {
  header: "Payment Approved",
  type: TABLE_COLUMN_TYPE.BOOL,
  attribute: "paymentApproved",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "paymentApproved"
}

const platformExist = {
  header: "Platform Exist",
  type: TABLE_COLUMN_TYPE.BOOL,
  attribute: "platformExist",
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "platformExist"
}

const approvedTotalNetColumn = {
  header: "Approved Total Net",
  type: TABLE_COLUMN_TYPE.FUNCTION,
  render: (row: AnalysisSummary) => {
    return Number(row.totalNet).toFixed(2)
  },
  headerStyle: { ...headerStyle, width: "10%" },
  cellStyle,
  sortable: true,
  sortFilterField: "total_net"
}

export const reportsApprovalPlatformColumns: TableColumn[] = [
  userColumn,
  labelColumn,
  currencyColumn,
  splitGrossColumn,
  splitNetColumn,
  approvedColumn,
  paymentApprovedColumn
]

export const reportsApprovalTrackColumns: TableColumn[] = [
  userColumn,
  labelColumn,
  currencyColumn,
  grossColumn,
  splitGrossColumn,
  splitNetColumn,
  approvedTotalNetColumn,
  shareColumn
]

export const reportsApprovalStoreFrontColumns: TableColumn[] = [
  userColumn,
  labelColumn,
  currencyColumn,
  splitGrossColumn,
  splitNetColumn,
  approvedTotalNetColumn
]

export const reportsApprovalAllPlatformTotalColumns: TableColumn[] = [
  labelColumn,
  splitGrossColumn,
  splitNetColumn,
  currencyColumn,
  platformExist
]

export const reportsApprovalAllAccountColumns: TableColumn[] = [
  userColumn,
  grossColumn,
  splitGrossColumn,
  kahunaShareColumn,
  splitNetColumn,
  totalApprovedColumn,
  currencyColumn,
  advanceTotalColumn,
  remainingAdvanceTotalColumn
]
