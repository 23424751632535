import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react"

import { KButton, KSpan } from "kahuna-base-react-components"
import { TrackRegistryConsentFile } from "../../../types"
import { releases } from "../../../apis"
import Loading from "../Loading"
import { errorFilledToastOptions } from "../../../constants"
import { toast } from "react-toastify"
import { lang } from "../../../constants/languages"
import LinearProgress from "../../layouts/catalog/release-registry/component/linear-progress/LinearProgress"
//@ts-ignore
import ErrorIcon from "../../../assets/release-registry-icons/close-circle-red.svg"
//@ts-ignore
import PdfIcon from "../../../../public/catalog_icons/pdf-icon.svg"
//@ts-ignore
import CompletedIcon from "../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import LoadingIcon from "../../../assets/release-registry-icons/loader.svg"
//@ts-ignore
import DeleteFileIcon from "../../../assets/release-registry-icons/trash.svg"
//@ts-ignore
import DeleteFileRedIcon from "../../../assets/release-registry-icons/trash-red.svg"
//@ts-ignore
import DownloadIcon from "../../../assets/release-registry-icons/download-new.svg"

interface UploadFileComponentProps {
  trackRegistryConsentFileList: TrackRegistryConsentFile[]
  setTrackRegistryConsentFileList: (files: TrackRegistryConsentFile[]) => void
  isrc: string
  upc: string
}

const UploadContributorConsentFileComponent: FC<UploadFileComponentProps> = (props) => {
  const { trackRegistryConsentFileList, setTrackRegistryConsentFileList, isrc, upc } = props

  const [loading, setLoading] = useState(false)
  const [onHover, setOnHover] = useState(false)
  const [errorInUpload, setErrorInUpload] = useState<boolean>(false)

  const fileInputRef = useRef<HTMLInputElement>(null)
  const loadingDivRef = useRef<HTMLDivElement>(null)

  const [currentUploadingFile, setCurrentUploadingFile] = useState<File | undefined>(undefined)

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileUpload = (file: File | undefined) => {
    if (!file) return

    const formData = new FormData()
    formData.append("file", file)
    releases(`/track-registry-consent-file/upload/?upc=${upc}&isrc=${isrc}`, "post", formData)
      .then((resp) => {
        if (resp.data) {
          setTrackRegistryConsentFileList(resp.data)
          setCurrentUploadingFile(undefined)
        } else {
          setErrorInUpload(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        setErrorInUpload(true)
        setLoading(false)
      })
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target

    if (fileInput.files && fileInput.files.length > 0) {
      setLoading(true)
      setErrorInUpload(false)
      const file = fileInput.files[0]
      setCurrentUploadingFile(file)
      handleFileUpload(file)
    }
  }

  const scrollToBottom = () => {
    if (loadingDivRef.current) {
      loadingDivRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [loading])

  const onRemoveFile = (id: number) => {
    setLoading(true)
    if (!window.confirm(lang.catalog.track_detail.are_you_sure)) {
      setLoading(false)
      return
    }

    releases(`/track-registry-consent-file/delete/?id=${id}`, "delete").then((resp) => {
      if (resp.data.success) {
        setTrackRegistryConsentFileList([...trackRegistryConsentFileList].filter((file) => file.id !== id))
      } else {
        toast.error(lang.catalog.track_detail.error_on_removing_file, errorFilledToastOptions)
      }
      setLoading(false)
    })
  }

  const onDownloadFile = (file: TrackRegistryConsentFile) => {
    window.open(file.file_url, "_blank")
  }

  const renderCurrentUpload = () => (
    <div className="flex flex-col mt-4 gap-3" style={{ width: "100%" }}>
      <div
        key={``}
        className="flex flex-col p-4 pl-[14px]"
        style={{
          border: errorInUpload ? "1px solid #FF5865" : "1px solid #F3F3F3",
          borderRadius: "10px",
          gap: loading ? "16px" : "0px"
        }}
      >
        <div className="flex flex-row gap-4 w-full  justify-between">
          <div key={""} className={"flex justify-between items-center "}>
            <div className="flex flex-row items-center justify-start gap-3">
              <div className="h-10 flex items-center justify-center rounded-[4px]">
                <img className="h-10 w-auto rounded-[4px]" src="/catalog_icons/pdf-icon.svg" alt="pdf-icon" />
              </div>
            </div>
            <div className="flex flex-col">
              <div
                className="cursor-pointer flex flex-row gap-1 items-center"
                onClick={() => {
                  // do nothing
                }}
              >
                <KSpan text={currentUploadingFile?.name || ""} fontWeight={500} color="#000" />
              </div>
              <div className="flex flex-row gap-1 items-center">
                <img src={errorInUpload ? ErrorIcon : LoadingIcon} />
                <span className="flex flex-row items-center pt-0.5">
                  <KSpan
                    text={errorInUpload ? "Failed" : "Uploading..."}
                    fontSize={12}
                    lineHeight="12px"
                    color="#1F1F1F"
                  />
                </span>
              </div>
              {errorInUpload && (
                <div
                  className="p-0 flex mt-1"
                  onClick={() => {
                    handleFileUpload(currentUploadingFile)
                  }}
                >
                  <KSpan
                    text="Try Again"
                    fontSize={12}
                    fontWeight={500}
                    lineHeight="16px"
                    hoverStyle={{
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                    color="#E52B2B"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="self-start">
            <KButton
              icon={errorInUpload ? DeleteFileRedIcon : DeleteFileIcon}
              shadowDisabled={true}
              padding="2px"
              width="24px"
              height="24px"
              borderRadius={6}
              background="transparent"
              disabled={loading ? true : false}
              hoverBackground="#F3F3F3"
              onClick={() => {
                if (errorInUpload) {
                  setCurrentUploadingFile(undefined)
                  setErrorInUpload(false)
                }
              }}
            />
          </div>
        </div>
        <div ref={loadingDivRef}>{loading && <LinearProgress />}</div>
      </div>
    </div>
  )

  const renderFileList = () => (
    <div className="flex flex-col mt-4 gap-3" style={{ width: "100%" }}>
      {trackRegistryConsentFileList.map((file: TrackRegistryConsentFile, index: number) => {
        return (
          <div
            key={`file-${file}-${index}`}
            className="flex flex-col gap-4 p-4 pl-[14px]"
            style={{
              border: "1px solid #F3F3F3",
              borderRadius: "10px"
            }}
          >
            <div className="flex flex-row gap-4 w-full  justify-between">
              <div key={""} className={"flex justify-between items-center "}>
                <div className="flex flex-row items-center justify-start gap-3">
                  <div className="h-10 flex items-center justify-center rounded-[4px]">
                    <img className="h-10 w-auto rounded-[4px]" src="/catalog_icons/pdf-icon.svg" alt="pdf-icon" />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div
                    className="cursor-pointer flex flex-row gap-1 items-center"
                    onClick={() => {
                      onDownloadFile(file)
                    }}
                  >
                    <KSpan text={file.title} fontWeight={500} color="#000" />
                    <img src={DownloadIcon} alt="download-icon" width={14} height={14} />
                  </div>
                  <div className="flex flex-row gap-1 items-center">
                    <img src={CompletedIcon} />
                    <span className="flex flex-row items-center pt-0.5">
                      <KSpan text={"Completed"} fontSize={12} lineHeight="12px" color="#1F1F1F" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="self-start">
                <KButton
                  icon={DeleteFileIcon}
                  shadowDisabled={true}
                  padding="2px"
                  width="24px"
                  height="24px"
                  borderRadius={6}
                  background="transparent"
                  disabled={loading}
                  hoverBackground="#F3F3F3"
                  onClick={() => {
                    if (file.id) onRemoveFile(file.id)
                  }}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )

  return (
    <div className="flex flex-col items-center gap-2">
      <div
        className="flex flex-col items-center justify-center gap-5 p-8 rounded-[10px] mt-4 w-full"
        style={{
          backgroundColor: onHover ? "#F3F3F3" : "#F7F7F7"
        }}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        {/*loading && <Loading />*/}
        <div className={"flex justify-center"} style={{ height: 40 }}>
          <img src={onHover ? "/catalog_icons/upload-hover.svg" : "/catalog_icons/upload.svg"} />
        </div>
        <div className={"flex flex-col gap-1 justify-center "}>
          <div className="justify-center flex">
            <KSpan text={lang.catalog.track_detail.upload_consent_file_title} color={"#111"} fontWeight={500} />
          </div>
          <KSpan text={lang.catalog.track_detail.upload_consent_file_desc} fontSize={12} lineHeight="16px" />
        </div>
        <div
          style={{
            pointerEvents: "auto"
          }}
        >
          <KButton
            text={lang.catalog.track_detail.upload_consent_file_button}
            onClick={handleBrowseClick}
            height="32px"
            padding="6px 10px"
            background="#FFF"
            textColor="#111"
          />
        </div>
        <input
          style={{ display: "none" }}
          ref={fileInputRef}
          id="file"
          type="file"
          accept=".pdf"
          onChange={(event) => handleFileChange(event)}
        />
      </div>
      {renderFileList()}
      {(loading || errorInUpload) && currentUploadingFile && renderCurrentUpload()}
    </div>
  )
}

export default UploadContributorConsentFileComponent
