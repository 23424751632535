import React, { useEffect, useState } from "react"
//@ts-ignore
import NotificationIcon from "../../../assets/notifications_icons/notification.svg"
//@ts-ignore
import CheckCircleIcon from "../../../assets/notifications_icons/check-circle-black.svg"
//@ts-ignore
import StickyNoteIcon from "../../../assets/notifications_icons/sticky-note.svg"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { GroupedNotifications, UserNotification, PaginatedData } from "../../../types"
import { buttonConnectedURL, buttonText, getDayDifference, groupByDates, notificationContent } from "../../../utility"
import { connect } from "react-redux"
import { getNotifications, getUnreadNotificationsNumber, markAsRead } from "../../../actions/notificationsActions"
import { RootState } from "../../../store"
import { lang } from "../../../constants/languages"
import NotFound from "../../NotFound"

const NotificationsPage = ({
  getUnreadNotificationsNumber,
  getNotifications,
  paginatedNotifications,
  markAsRead
}: {
  getUnreadNotificationsNumber: () => Promise<any>
  getNotifications: (pageNumber: number) => Promise<any>
  paginatedNotifications: PaginatedData<UserNotification>
  markAsRead: (id: string, readAll?: boolean) => Promise<any>
}) => {
  const [groupedNotifications, setGroupedNotifications] = useState<GroupedNotifications>({} as GroupedNotifications)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [next, setNext] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    getNotifications(pageNumber)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }, [pageNumber])

  useEffect(() => {
    if (!(paginatedNotifications.results && paginatedNotifications.count)) return

    const groupedElements = groupByDates(paginatedNotifications.results)
    setGroupedNotifications(groupedElements)
    const hasNext = paginatedNotifications.results.length < paginatedNotifications.count
    setNext(hasNext)
  }, [paginatedNotifications.results])

  return (
    <div className="w-full flex items-center justify-center pb-14 pt-[100px]">
      <div className=" w-[90%] max-w-[700px] flex flex-col gap-8">
        <div className="flex flex-col gap-6 justify-between items-center">
          <div className="flex items-center justify-center">
            <div
              className="w-[72px] aspect-square flex items-center justify-center"
              style={{
                border: "1px solid #F3F3F3",
                background: "#FFF",
                borderRadius: "999px"
              }}
            >
              <img src={NotificationIcon} alt="notification-icon" />
            </div>
          </div>
          <KTitleSpan text={lang.notifications.title} color="#000" fontSize={32} lineHeight="40px" />
        </div>
        <div className="flex flex-col gap-6">
          {Object.keys(groupedNotifications).length > 0 &&
            Object.keys(groupedNotifications).map((key: string, index: number) => {
              return (
                <div key={`${key}-${index}`} className="flex flex-col gap-3">
                  <div className="flex flex-row ml-3 justify-between items-center h-12">
                    <div className="flex flex-row gap-2">
                      <KSpan text={`${key.toUpperCase()}`} color="#000" fontWeight={500} />
                    </div>
                    {index === 0 && (
                      <KButton
                        text={lang.notifications.mark_all_as_read}
                        leftIcon={CheckCircleIcon}
                        width="auto"
                        onClick={() => {
                          markAsRead("", true).then(() => getUnreadNotificationsNumber())
                        }}
                        textColor="#000"
                        fontWeight={500}
                        background="transparent"
                        shadowDisabled
                        gap="4px"
                      />
                    )}
                  </div>
                  {groupedNotifications[key].notifications.length > 0 &&
                    groupedNotifications[key].notifications.map((notification: UserNotification, subIndex: number) => {
                      return (
                        <div
                          key={`${notification.param1}-${subIndex}`}
                          className="min-h-16 flex flex-row justify-between items-start gap-5 py-3 px-3 cursor-pointer"
                          style={{
                            opacity: notification.read ? "0.50" : "1"
                          }}
                          onClick={() => {
                            if (!notification.read) {
                              markAsRead(notification.id.toString()).then(() => getUnreadNotificationsNumber())
                            }
                          }}
                        >
                          <div
                            className="w-2 h-2 rounded-full self-center shrink-0"
                            style={{
                              backgroundColor: notification.read ? "transparent" : "#0C66E4"
                            }}
                          />
                          <div className="grow flex flex-row gap-3 items-start">
                            <div className="h-10 w-10 flex items-center justify-center rounded-full bg-[#F7F7F7] shrink-0">
                              <img src={StickyNoteIcon} alt="sticky-note-icon" />
                            </div>
                            <div className="flex flex-col gap-0.5">
                              <KSpan text={notificationContent(notification)} color="#666" />
                              <KSpan
                                text={getDayDifference(notification.created)}
                                color="#666"
                                fontSize={12}
                                lineHeight="16px"
                              />
                            </div>
                          </div>
                          <div className="w-auto shrink-0 flex gap-3">
                            {notification.buttonParam && (notification.urlParam || notification.relatedUrl) && (
                              <KButton
                                text={buttonText(notification)}
                                background="#F7F7F7"
                                hoverBackground="#E3E3E3"
                                onClick={() => {
                                  const buttonConnecttedURL = buttonConnectedURL(notification)
                                  if (buttonConnecttedURL) {
                                    window.location.href = buttonConnecttedURL
                                  }
                                }}
                                fontWeight={500}
                                textColor="#000"
                                height="36px"
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
              )
            })}
        </div>
        {paginatedNotifications.count === 0 && (
          <div className="flex justify-center">
            <NotFound title={lang.not_found.notification.title} description={lang.not_found.notification.desc} imageWidth={225} />
          </div>
        )}
        {next && (
          <KButton
            text={lang.notifications.view_more_button}
            background="#F7F7F7"
            hoverBackground="#E3E3E3"
            onClick={() => {
              setPageNumber((prev) => prev + 1)
            }}
            fontWeight={500}
            disabled={loading || !next}
            textColor="#000"
            height="36px"
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    paginatedNotifications: state.notifications.paginatedNotifications
  }
}

export default connect(mapStateToProps, { getUnreadNotificationsNumber, getNotifications, markAsRead })(
  NotificationsPage
)
