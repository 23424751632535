import { TableProps } from "../../../../types"
import React, { FC, useEffect, useRef, useState } from "react"
import { tableTitleClasses } from "../../../../constants"
import { Filter, FilterOutline } from "heroicons-react"
import { isEqual } from "lodash"
import { KSpan } from "kahuna-base-react-components"
import ReleaseDetailListComponent from "./ReleaseDetailListComponent"
import TrackDetail from "../track-detail/TrackDetail"
import { lang } from "../../../../constants/languages"

const ReleaseDetailTable: FC<TableProps> = (props) => {
  const {
    topRightElement,
    className,
    size = "lg",
    title,
    columns,
    data,
    count,
    loading,
    setTableAPI,
    hidden,
    additionalFilters
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [filtersShown, setFiltersShown] = useState(false)
  const [filters, setFilters] = useState<{ [key: string]: string | number | boolean }>({})
  const additionalFiltersRef = useRef(additionalFilters)
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])
  const [selectedTrack, setSelectedTrack] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (setTableAPI) setTableAPI({ currentPage, setCurrentPage })
  }, [currentPage, count])

  useEffect(() => {
    if (!isEqual(additionalFiltersRef.current, additionalFilters)) {
      const newFilters = { ...filters, ...additionalFilters }
      setFilters(newFilters)
      additionalFiltersRef.current = additionalFilters
    }
  }, [additionalFilters])

  if (hidden) return null

  return (
    <div className={`${className}`}>
      <TrackDetail selectedTrack={selectedTrack} setSelectedTrack={setSelectedTrack} />
      <div className="flex flex-row justify-between items-center">
        <div className={`flex font-medium font-sans text-gray-900   ${tableTitleClasses[size]}`}>
          <div className="flex flex-row gap-2">
            <KSpan
              text={title!.toString()}
              fontSize={18}
              fontWeight={500}
              lineHeight="24px"
              letterSpacing="-0.27px"
              color="#111"
            />
          </div>
          {columns.some((col) => col.filter) && (
            <span onClick={() => setFiltersShown(!filtersShown)}>
              {filtersShown ? (
                <Filter className={"inline h-3 mb-[2px] cursor-pointer"} />
              ) : (
                <FilterOutline className={"inline h-3 mb-[2px] cursor-pointer"} />
              )}
            </span>
          )}
        </div>

        {topRightElement}
      </div>
      <div className="mt-4 bg-white elev-b-sm rounded-lg w-full overflow-hidden !shadow-none">
        {!loading && data.length > 0 && (
          <div className="flex flex-row justify-between items-center h-[52px] py-2 px-6 gap-1.5">
            <div className="flex w-[5%] py-2 items-center justify-start">
              <KSpan text={"No"} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[5%] py-2 items-center justify-start" />
            <div className="flex w-[35%] py-2 items-center justify-start">
              <KSpan text={lang.catalog.release_detail.track_title} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[15%] py-2 items-center justify-start">
              <KSpan text={lang.catalog.release_detail.share_holders} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[20%] py-2 items-center justify-start">
              <KSpan text={lang.catalog.release_detail.royalty_share} fontWeight={500} color="#000" />
            </div>
            <div className="flex w-[20%] py-2 items-center justify-start">
              <KSpan text={lang.catalog.release_detail.roles} fontWeight={500} color="#000" />
            </div>
          </div>
        )}
        <ul className="px-[3px]">
          {!loading &&
            data?.map((row, i) => {
              return (
                <ReleaseDetailListComponent
                  key={`release-detail-list-component-${i}`}
                  row={row}
                  i={i}
                  playingAudioRefs={playingAudioRefs}
                  setPlayingAudioRefs={setPlayingAudioRefs}
                  setSelectedTrack={setSelectedTrack}
                />
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default ReleaseDetailTable
