import { Action, Dispatch } from "redux"
import { toast } from "react-toastify"
import { publicInvitation } from "../apis"
import { errorFilledToastOptions, successFilledToastOptions } from "../constants"

export const PUBLIC_ACTION = {
  APPROVE_INVITATION: "APPROVE_INVITATION"
}

export const acceptInvitation = (token: string) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await publicInvitation.put("/", { data: token })
    dispatch({
      type: PUBLIC_ACTION.APPROVE_INVITATION,
      payload: response.data
    })
    toast.success("You have successfully approved your invitation.", successFilledToastOptions)
  } catch (e) {
    if (e.response) {
      dispatch({
        type: PUBLIC_ACTION.APPROVE_INVITATION,
        payload: e.response.data
      })
      toast.error(`Error approving invitation: ${e.response.data.message}`, errorFilledToastOptions)
    }
  }
}
