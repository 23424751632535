import { KSpan } from "kahuna-base-react-components"
import React from "react"
import "./styles/Catalog.css"

const OwnerCard = ({
  iconPath,
  text,
  ownerStatus,
  releaseFilter,
  setOwnerStatus
}: {
  iconPath: string
  text: string
  ownerStatus: string
  releaseFilter: string
  setOwnerStatus: (ownerStatus: string) => void
}) => {
  return (
    <div
      className={`owner-card w-[233px] cursor-pointer p-5 rounded-[10px] border-[1px]`}
      style={{ borderColor: ownerStatus === releaseFilter ? "#000" : "#F3F3F3" }}
      onClick={() => setOwnerStatus(releaseFilter)}
    >
      <div className="flex flex-col justify-between gap-14">
        <span className="w-8 h-8">
          <img src={iconPath} className="w-full h-full" />
        </span>
        <KSpan
          text={text}
          fontSize={18}
          fontWeight={500}
          lineHeight="24px"
          letterSpacing="-0.27px"
          color="#111"
        />
      </div>
    </div>
  )
}

export default OwnerCard
