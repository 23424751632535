// @ts-ignore
import image from "./ui/img/not-found.png"
import "./styles/NotFound.css"
import React, { FC } from "react"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"

type NotFoundProps = {
  title: string
  titleSize?: number
  description: string
  descriptionSize?: number
  buttonText?: string
  buttonLink?: string
  imageWidth: number
}

const NotFound: FC<NotFoundProps> = (props) => {
  return (
    <div className="not-found mx-auto md:pt-0 grid items-center justify-between">
      <div className="flex justify-center pointer-events-none select-none">
        <img src={image} style={{ width: props.imageWidth || 500, height: "auto" }} />
      </div>
      <div className="flex justify-center mt-6 pointer-events-none select-none">
        <KTitleSpan text={props.title} fontSize={props.titleSize || 20} lineHeight="auto" />
      </div>
      <div className="flex justify-center mt-1 pointer-events-none select-none">
        <KSpan text={props.description} fontSize={props.descriptionSize || 16} color="#000" lineHeight="auto" />
      </div>

      {props.buttonLink && (
        <div className="flex justify-center">
          <div className="mt-6" style={{ width: "fit-content" }}>
            <KButton
              text={props.buttonText || ""}
              background="#F7F7F7"
              onClick={() => window.open(`https://artists.kahuna.io${props.buttonLink}`)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default NotFound
