import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton, KInput, KDropdown } from "kahuna-base-react-components"
import React from "react"
import { AssetRegistryPublishingShareHolder } from "../../../../../types"
import { ROLE_NAMES } from "../../../../../constants"
import { lang } from "../../../../../constants/languages"

const PublishingShareHolderModal = ({
  selectedPublishingShareHolder,
  setSelectedPublishingShareHolder,
  handleConfirm
}: {
  selectedPublishingShareHolder: AssetRegistryPublishingShareHolder | undefined
  setSelectedPublishingShareHolder: (
    selectedPublishingShareHolder: AssetRegistryPublishingShareHolder | undefined
  ) => void
  handleConfirm: () => void
}) => {
  return (
    <Dialog
      open={selectedPublishingShareHolder !== undefined}
      onClose={() => {
        setSelectedPublishingShareHolder(undefined)
      }}
    >
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4 items-center justify-center">
              <span className="w-[72px] aspect-square rounded-[48px] shadow-md flex justify-center items-center mb-6">
                <img className="w-8 h-8" src="/catalog_icons/edit-line.svg"></img>
              </span>
              <KTitleSpan
                text={lang.catalog.asset_registry.tracks_and_assets.edit_contributor_share_holder.replace(
                  "<param>",
                  ROLE_NAMES[selectedPublishingShareHolder?.role || 1]
                )}
                fontSize={24}
                lineHeight="24px"
              />
              <KSpan text={selectedPublishingShareHolder?.name || ""} fontSize={20} />
              <div className="mt-1" />
              <KInput
                leftIcon="/catalog_icons/music-line.svg"
                placeholder={`${lang.catalog.asset_registry.tracks_and_assets.share} (10%)`}
                type="number"
                value={selectedPublishingShareHolder?.share || ""}
                onChange={(value) => {
                  if (selectedPublishingShareHolder) {
                    setSelectedPublishingShareHolder({ ...selectedPublishingShareHolder, share: value })
                  }
                }}
              />
            </div>
            <div className="flex gap-3 justify-center items-center mt-2">
              <KButton
                text={lang.common.cancel}
                onClick={() => {
                  setSelectedPublishingShareHolder(undefined)
                }}
                background="#fff"
                shadowDisabled={true}
              ></KButton>
              <KButton
                text={lang.common.confirm}
                textColor="white"
                background="black"
                disabled={!selectedPublishingShareHolder?.share}
                onClick={() => {
                  handleConfirm()
                }}
              ></KButton>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PublishingShareHolderModal
