import { KSpan } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"

const StatusCard = ({
  text,
  value,
  choosenStatus,
  setChoosenStatus
}: {
  text: string
  value: string
  choosenStatus: string[]
  setChoosenStatus: (choosenStatus: string[]) => void
}) => {

  const handleSelect = () => {
    if (!choosenStatus.includes(value.toLowerCase())) {
      setChoosenStatus([...choosenStatus, value.toLowerCase()])
    } else {
      setChoosenStatus(choosenStatus.filter((item) => item !== value.toLowerCase()))
    }
  }

  return (
    <div
      className={`px-3 py-2 rounded-[10px] cursor-pointer border-[1px] border-[#F3F3F3] flex items-center ${
        choosenStatus.includes(value.toLowerCase()) ? "bg-[#000]" : "bg-[#fff]"
      }`}
      onClick={handleSelect}
    >
      <KSpan text={text} color={!choosenStatus.includes(value.toLowerCase()) ? "#000" : "#fff"} fontSize={12} lineHeight="16px" fontWeight={500}  />
    </div>
  )
}

export default StatusCard
