import "./styles/Catalog.css"
import catalogColumns from "./columns/catalogColumns"
import React, { FC, useEffect, useState } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { CatalogProps, Release } from "../../../types"
import { fetchCatalogData, selectCatalogFilter } from "../../../actions/catalogActions"
import CatalogTable from "./CatalogTable"
import CatalogFilterComponents from "./CatalogFilterComponents"
import { PERMISSIONS } from "../../../constants"
import { lang } from "../../../constants/languages"

const Catalog: FC<CatalogProps> = (props) => {
  const { selectedCatalogFilter } = props
  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState<Release[]>([])
  const [timeoutId, setTimeoutId] = useState(undefined)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const limit = 10
  useEffect(() => {
    const fetchSync = async () => {
      await props.fetchCatalogData(limit, 0, props.user.id!, selectedCatalogFilter)
    }
    fetchSync().then(() => setLoading(false))
  }, [selectedCatalogFilter])

  useEffect(() => {
    setTableData(props.paginatedReleases.results)
  }, [props.paginatedReleases])

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    await props.fetchCatalogData(limit, (pageNumber - 1) * limit, props.user.id!, selectedCatalogFilter)
    setLoading(false)
    setPageNumber(pageNumber)
  }

  const filterDataBySearchText = (searchText: string) => {
    clearTimeout(timeoutId)
    setTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        setLoading(true)
        await props.fetchCatalogData(limit, (pageNumber - 1) * limit, props.user.id!, selectedCatalogFilter, searchText)
        setLoading(false)
      }, 800)
    )
  }

  return (
    <div className="catalog flex-grow mt-6">
      <CatalogTable
        title={lang.catalog.title}
        pagination
        minRowCount={limit}
        limit={limit}
        columns={catalogColumns}
        data={tableData}
        count={props.paginatedReleases.count}
        topRightElement={
          <CatalogFilterComponents
            filterDataBySearchText={filterDataBySearchText}
            newReleasePermission={props.newReleasePermission}
          />
        }
        onClickPage={onClickPage}
        rowHeightInPx={40}
        loading={loading}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
    paginatedReleases: state.catalogData.paginatedReleases,
    selectedCatalogFilter: state.catalogData.selectedCatalogFilter,
    newReleasePermission: state.auth.user.permissionUser?.some((item) => {
      return item.permission && item.permission.id === PERMISSIONS.NewRelease
    })
  }
}

export default connect(mapStateToProps, { fetchCatalogData, selectCatalogFilter })(Catalog)
