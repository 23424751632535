import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
import { Release, User } from "../../../../../../types"
import { hashids } from "../../../../../../utility"
import ReleaseComponent from "../../../ReleaseComponent"
import { lang } from "../../../../../../constants/languages"
import { NewReleaseActions } from "../../../../../../actions/types"
import Loading from "../../../../../ui/Loading"

type FinishReleaseProps = {
  user: User
  releaseList: Release[]
  setSelectedPage: (selectedPage: number) => void
  selectRelease: (release?: Release, releaseId?: string) => void
}

const FinishRelease: FC<FinishReleaseProps> = (props) => {
  const { user, releaseList, setSelectedPage, selectRelease } = props
  const [loading, setLoading] = useState(false)

  const [hoverNewRelease, setHoverNewRelease] = useState(false)
  const [hoverMoveAlbum, setHoverMoveAlbum] = useState(false)

  const navigate = useNavigate()

  const takedownCredit = props.user.userCredit?.takedown || 0

  return (
    <div className="flex gap-6">
      {loading && <Loading />}
      <div style={{ width: 36 }} />
      <div>
        <div>
          <KTitleSpan
            text={`${lang.catalog.new_release.welcome_back}, ${user.firstName} ${user.lastName}`}
            fontSize={32}
          />
        </div>
        {releaseList.length > 0 && (
          <div style={{ marginTop: 24 }}>
            <KTitleSpan
              text={lang.catalog.new_release.finish_your_release}
              fontWeight={500}
              fontStyle="normal"
              lineHeight="28px"
              fontSize={20}
            />
          </div>
        )}
        {releaseList.map((release) => {
          return (
            <div
              key={release.id}
              style={{ marginTop: 12 }}
              onClick={() => {
                if (loading) {
                  return
                }

                setLoading(true)
                selectRelease(undefined, release.id?.toString())
                setTimeout(() => {
                  setLoading(false)
                  navigate(`/catalog/asset-registry/${hashids.encode(release.id?.toString() || "")}`)
                }, 1000)
              }}
            >
              <ReleaseComponent release={release} overrideBorder={false} fullScreen={false} />
            </div>
          )
        })}
        <div style={{ marginTop: 24 }}>
          <KTitleSpan
            text={lang.catalog.new_release.start_new_release}
            fontWeight={500}
            fontStyle="normal"
            lineHeight="28px"
            fontSize={20}
          />
        </div>
        <div
          className="flex cursor-pointer gap-2"
          style={{
            marginBottom: 24,
            padding: "24px 20px",
            borderRadius: 10,
            border: hoverNewRelease ? "1px solid #e7e7e7" : "1px solid transparent",
            marginTop: 12
          }}
          onMouseEnter={() => setHoverNewRelease(true)}
          onMouseLeave={() => setHoverNewRelease(false)}
          onClick={() => navigate("/catalog/asset-registry/new")}
        >
          <img src="/account_icons/plus.svg" style={{ marginRight: 8 }} />
          <div className="grid gap-1">
            <KSpan text={lang.catalog.new_release.create_new_release} color="#000" fontSize={16} />
            <KSpan text={lang.catalog.new_release.create_new_release_question} fontSize={14} />
          </div>
        </div>
        {takedownCredit > 0 && (
          <div style={{ paddingBottom: 24 }}>
            <KTitleSpan
              text={lang.catalog.new_release.takedown}
              fontWeight={500}
              fontStyle="normal"
              lineHeight="28px"
              fontSize={20}
            />
            <div
              className="flex cursor-pointer gap-2"
              style={{
                padding: "24px 20px",
                borderRadius: 10,
                border: hoverMoveAlbum ? "1px solid #e7e7e7" : "1px solid transparent",
                marginTop: 12
              }}
              onMouseEnter={() => setHoverMoveAlbum(true)}
              onMouseLeave={() => setHoverMoveAlbum(false)}
              onClick={() => setSelectedPage(NewReleaseActions.TAKEDOWN)}
            >
              <img src="/catalog_icons/music-library.svg" style={{ marginRight: 8 }} />
              <div className="grid gap-1">
                <KSpan text={lang.catalog.new_release.move_your_album} color="#000" fontSize={16} />
                <KSpan text={lang.catalog.new_release.move_your_album_question} fontSize={14} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FinishRelease
