import React, { FC, useEffect, useState } from "react"
import { ReleaseDetailProps, Track } from "../../../types"
import "./styles/Catalog.css"
import { connect } from "react-redux"
import { RootState } from "../../../store"
import { useNavigate, useParams } from "react-router-dom"
import trackColumns from "./columns/trackColumns"
import { fetchReleaseTracks, selectRelease } from "../../../actions/catalogActions"
import { hashids } from "../../../utility"
import ReleaseDetailTable from "./release-detail-components/ReleaseDetailTable"
import ReleaseDetailFilterComponents from "./release-detail-components/ReleaseDetailFilterComponent"
import ReleaseComponent from "./ReleaseComponent"
import { lang } from "../../../constants/languages"

const ReleaseDetail: FC<ReleaseDetailProps> = (props) => {
  const { userId, releases, release, selectRelease, fetchReleaseTracks, paginatedTracks, isAdmin } = props
  const [loading, setLoading] = useState(true)
  const params = useParams()
  const decodedId = hashids.decode(params.releaseId || "").toString()
  const limit = 50
  const [tableData, setTableData] = useState<Track[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    if (params.releaseId) {
      selectRelease(
        releases.find((r) => r.id?.toString() === decodedId),
        decodedId
      )
      const filteredUserId = isAdmin ? 0 : userId
      fetchReleaseTracks(limit, 0, decodedId, filteredUserId).then(() => setLoading(false))
    }

    window.scrollTo(0, 0)
  }, [])

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    const filteredUserId = isAdmin ? 0 : userId
    await fetchReleaseTracks(limit, (pageNumber - 1) * limit, decodedId, filteredUserId)
    setLoading(false)
  }

  const filterDataBySearchText = (searchText: string): void => {
    if (props.paginatedTracks.results.length > 0 && searchText.length > 0) {
      const filteredData = props.paginatedTracks.results.filter((track) => {
        return track.title.toLowerCase().includes(searchText.toLowerCase())
      })
      setTableData(filteredData)
    } else {
      setTableData(props.paginatedTracks.results)
    }
  }

  useEffect(() => {
    setTableData(props.paginatedTracks.results)
  }, [props.paginatedTracks.results])

  return (
    <div className="release-detail bg-white">
      <div>
        <div className="flex">
          <ReleaseComponent
            release={release}
            overrideBorder={true}
            fullScreen={true}
            canEdit={release?.status === "draft"}
          />
        </div>
        <div className="">
          <div className="container pt-[60px] pb-[68px] !px-0">
            <ReleaseDetailTable
              title={lang.catalog.release_detail.title}
              columns={trackColumns}
              data={tableData}
              loading={loading}
              minRowCount={10}
              rowHeightInPx={40}
              topRightElement={<ReleaseDetailFilterComponents filterDataBySearchText={filterDataBySearchText} />}
              pagination
              limit={limit}
              count={paginatedTracks.count}
              onClickPage={onClickPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    releases: state.catalogData.paginatedReleases.results,
    paginatedTracks: state.catalogData.paginatedReleaseTracks,
    userId: state.auth.user.id,
    release: state.catalogData.selectedRelease,
    shareData: state.catalogData.shareData
  }
}

export default connect(mapStateToProps, { fetchReleaseTracks, selectRelease })(ReleaseDetail)
