import React, { FC, useEffect, useState } from "react"
import { Event } from "../../../../types"

import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"

import { formatTime } from "../../../../utility"

import AddEventModal from "../modal/AddEventModal"
import { lang } from "../../../../constants/languages"
import ConfirmationDialog from "../../../ui/modals/ConfirmationDialog"

type EventProps = {
  eventList: Event[]
  fetchEvents: () => void
  saveEvent?: (event: Event) => Promise<boolean>
  deleteEvent?: (id: number) => Promise<boolean>
  smallScreen: boolean
  isAdmin: boolean
}

const EventsPage: FC<EventProps> = (props) => {
  const { eventList, isAdmin } = props

  const [events, setEvents] = useState<Event[]>([])
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEvents(eventList)
  }, [eventList])

  useEffect(() => {
    props.fetchEvents()
  }, [])

  const onSaveEvent = async (event: Event) => {
    if (!props.saveEvent) {
      return
    }

    setLoading(true)
    const resp = props.saveEvent(event)
    if (resp) {
      setAddModalOpen(false)
    }

    setLoading(false)
  }

  const handleConfirmDelete = async () => {
    if (!idToDelete || !props.deleteEvent) {
      return
    }

    const resp = await props.deleteEvent(idToDelete)
    if (resp) {
      setIdToDelete(undefined)
    }

    setLoading(false)
  }

  return (
    <div className="flex flex-col gap-4 pb-6 pt-8">
      <div className="pl-3">
        <KTitleSpan
          text={lang.artist_space.events_title}
          fontSize={20}
          fontWeight={500}
          lineHeight="28px"
          color="#000"
        />
      </div>
      <div
        className="grid"
        style={{
          gridTemplateColumns: !props.smallScreen
            ? "repeat(auto-fit, minmax(calc(33.3% - 16px), calc(33.3% - 16px)))"
            : "",
          gridGap: 16
        }}
      >
        {isAdmin && (
          <div
            className="user-event-div justify-between cursor-pointer"
            onClick={() => setAddModalOpen(true)}
            style={{ background: "#F7F7F7" }}
          >
            <img src="/account_icons/plus-filled.svg" />
            <KTitleSpan
              text={lang.artist_space.new_event}
              fontSize={20}
              fontWeight={500}
              lineHeight="28px"
              fontStyle="normal"
              color="#666666"
            />
          </div>
        )}

        {events?.map((event) => {
          //@ts-ignore
          const startTime = formatTime(new Date(event.startDate))
          //@ts-ignore
          const endTime = formatTime(new Date(event.endDate))
          //@ts-ignore
          const startMonth = lang.common.months_short[new Date(event.startDate).getMonth() || 0].toUpperCase()
          //@ts-ignore
          const startDay = new Date(event.startDate).getDate().toString()

          return (
            <div className="user-event-div" key={event.id}>
              <div className="relative" style={{ width: "100%", height: "100%" }}>
                {isAdmin && (
                  <div className="absolute" style={{ right: -4 }}>
                    <KButton
                      leftIcon="/account_icons/delete-bin-red.svg"
                      background="#FFF"
                      padding="4px"
                      width="26px"
                      height="26px"
                      onClick={() => setIdToDelete(event.id)}
                    />
                  </div>
                )}
                <div className="grid" style={{ height: "100%", overflowX: "hidden" }}>
                  <div className="flex gap-2">
                    <div className="event-calendar-icon">
                      <div
                        style={{
                          background: "#FF5865",
                          height: 6,
                          width: "100%",
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10
                        }}
                      />
                      <div className="flex justify-center" style={{ marginTop: 5 }}>
                        <KSpan
                          color="#FF5865"
                          fontSize={11}
                          text={startMonth}
                          lineHeight="12px"
                          fontWeight={500}
                          letterSpacing="0.22px"
                        />
                      </div>
                      <div className="flex justify-center">
                        <KTitleSpan
                          color="#000000"
                          fontSize={20}
                          text={startDay}
                          lineHeight="28px"
                          fontWeight={500}
                          letterSpacing="0.22px"
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ overflow: "hidden", maxHeight: 56 }}>
                        <KTitleSpan text={event.title || ""} fontWeight={500} lineHeight="28px" fontSize={16} />
                      </div>
                      <div style={{ overflow: "hidden", maxHeight: 50 }}>
                        <KSpan
                          text={`${startTime} - ${endTime} / ${event.address}`}
                          fontSize={13}
                          fontWeight={400}
                          lineHeight="20px"
                          letterSpacing="-0.084px"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 grid items-end">
                    <div style={{ padding: 0.8 }}>
                      <KButton
                        text={lang.artist_space.book_now_button}
                        onClick={() => {
                          window.open(event.url, "_blank")
                        }}
                        background="transparent"
                        height="36px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <AddEventModal open={addModalOpen} setOpen={setAddModalOpen} handleConfirm={onSaveEvent} loading={loading} />
      <ConfirmationDialog
        openConfirmation={idToDelete !== undefined}
        setOpenConfirmation={() => setIdToDelete(undefined)}
        handleConfirm={handleConfirmDelete}
        loading={loading}
      />
    </div>
  )
}

export default EventsPage
