export const countryOptionsTr = [
  { label: "Afganistan", value: 93 },
  { label: "Almanya", value: 49 },
  { label: "Amerika Birleşik Devletleri", value: 1 },
  { label: "Andorra", value: 376 },
  { label: "Angola", value: 244 },
  { label: "Antigua ve Barbuda", value: 1 },
  { label: "Arjantin", value: 54 },
  { label: "Arnavutluk", value: 355 },
  { label: "Avustralya", value: 61 },
  { label: "Avusturya", value: 43 },
  { label: "Azerbaycan", value: 994 },
  { label: "Bahamalar", value: 1 },
  { label: "Bahreyn", value: 973 },
  { label: "Bangladeş", value: 880 },
  { label: "Barbados", value: 1 },
  { label: "Belarus", value: 375 },
  { label: "Belize", value: 501 },
  { label: "Belçika", value: 32 },
  { label: "Benin", value: 229 },
  { label: "Birleşik Arap Emirlikleri", value: 971 },
  { label: "Birleşik Krallık", value: 44 },
  { label: "Bolivya", value: 591 },
  { label: "Bosna-Hersek", value: 387 },
  { label: "Botsvana", value: 267 },
  { label: "Brezilya", value: 55 },
  { label: "Brunei", value: 673 },
  { label: "Bulgaristan", value: 359 },
  { label: "Burkina Faso", value: 226 },
  { label: "Burundi", value: 257 },
  { label: "Butan", value: 975 },
  { label: "Cezayir", value: 213 },
  { label: "Cibuti", value: 253 },
  { label: "Çad", value: 235 },
  { label: "Çekya", value: 420 },
  { label: "Çin", value: 86 },
  { label: "Danimarka", value: 45 },
  { label: "Dominik Cumhuriyeti", value: 1 },
  { label: "Dominika", value: 1 },
  { label: "Doğu Timor (Timor-Leste)", value: 670 },
  { label: "Ekvador", value: 593 },
  { label: "Ekvator Ginesi", value: 240 },
  { label: "El Salvador", value: 503 },
  { label: "Endonezya", value: 62 },
  { label: "Eritre", value: 291 },
  { label: "Ermenistan", value: 374 },
  { label: "Estonya", value: 372 },
  { label: "Esvatini", value: 268 },
  { label: "Etiyopya", value: 251 },
  { label: "Fas", value: 212 },
  { label: "Fiji", value: 679 },
  { label: "Fildişi Sahili", value: 225 },
  { label: "Filipinler", value: 63 },
  { label: "Finlandiya", value: 358 },
  { label: "Fransa", value: 33 },
  { label: "Gabon", value: 241 },
  { label: "Gambiya", value: 220 },
  { label: "Gana", value: 233 },
  { label: "Gine", value: 224 },
  { label: "Gine-Bissau", value: 245 },
  { label: "Grenada", value: 1 },
  { label: "Guatemala", value: 502 },
  { label: "Guyana", value: 592 },
  { label: "Güney Afrika", value: 27 },
  { label: "Güney Kore", value: 82 },
  { label: "Güney Sudan", value: 211 },
  { label: "Gürcistan", value: 995 },
  { label: "Haiti", value: 509 },
  { label: "Hindistan", value: 91 },
  { label: "Hollanda", value: 31 },
  { label: "Honduras", value: 504 },
  { label: "Hırvatistan", value: 385 },
  { label: "Irak", value: 964 },
  { label: "İran", value: 98 },
  { label: "İrlanda", value: 353 },
  { label: "İspanya", value: 34 },
  { label: "İsrail", value: 972 },
  { label: "İsveç", value: 46 },
  { label: "İsviçre", value: 41 },
  { label: "İtalya", value: 39 },
  { label: "İzlanda", value: 354 },
  { label: "Jamaika", value: 1 },
  { label: "Japonya", value: 81 },
  { label: "Kamboçya", value: 855 },
  { label: "Kamerun", value: 237 },
  { label: "Kanada", value: 1 },
  { label: "Karadağ", value: 382 },
  { label: "Katar", value: 974 },
  { label: "Kazakistan", value: 7 },
  { label: "Kenya", value: 254 },
  { label: "Kiribati", value: 686 },
  { label: "Kolombiya", value: 57 },
  { label: "Komorlar", value: 269 },
  { label: "Kongo", value: 242 },
  { label: "Kosova", value: 383 },
  { label: "Kosta Rika", value: 506 },
  { label: "Kuveyt", value: 965 },
  { label: "Kuzey Kore", value: 850 },
  { label: "Kuzey Makedonya", value: 389 },
  { label: "Küba", value: 53 },
  { label: "Kıbrıs", value: 357 },
  { label: "Kırgızistan", value: 996 },
  { label: "Laos", value: 856 },
  { label: "Lesotho", value: 266 },
  { label: "Letonya", value: 371 },
  { label: "Liberya", value: 231 },
  { label: "Libya", value: 218 },
  { label: "Lihtenştayn", value: 423 },
  { label: "Litvanya", value: 370 },
  { label: "Lübnan", value: 961 },
  { label: "Lüksemburg", value: 352 },
  { label: "Macaristan", value: 36 },
  { label: "Madagaskar", value: 261 },
  { label: "Malavi", value: 265 },
  { label: "Maldivler", value: 960 },
  { label: "Malezya", value: 60 },
  { label: "Mali", value: 223 },
  { label: "Malta", value: 356 },
  { label: "Marshall Adaları", value: 692 },
  { label: "Mauritius", value: 230 },
  { label: "Meksika", value: 52 },
  { label: "Mikronezya", value: 691 },
  { label: "Moldova", value: 373 },
  { label: "Monako", value: 377 },
  { label: "Moritanya", value: 222 },
  { label: "Mozambik", value: 258 },
  { label: "Moğolistan", value: 976 },
  { label: "Myanmar (Burma)", value: 95 },
  { label: "Mısır", value: 20 },
  { label: "Namibya", value: 264 },
  { label: "Nauru", value: 674 },
  { label: "Nepal", value: 977 },
  { label: "Nijer", value: 227 },
  { label: "Nijerya", value: 234 },
  { label: "Nikaragua", value: 505 },
  { label: "Norveç", value: 47 },
  { label: "Orta Afrika Cumhuriyeti", value: 236 },
  { label: "Pakistan", value: 92 },
  { label: "Palau", value: 680 },
  { label: "Panama", value: 507 },
  { label: "Papua Yeni Gine", value: 675 },
  { label: "Paraguay", value: 595 },
  { label: "Peru", value: 51 },
  { label: "Polonya", value: 48 },
  { label: "Portekiz", value: 351 },
  { label: "Romanya", value: 40 },
  { label: "Ruanda", value: 250 },
  { label: "Rusya", value: 7 },
  { label: "Saint Kitts ve Nevis", value: 1 },
  { label: "Saint Lucia", value: 1 },
  { label: "Saint Vincent ve Grenadinler", value: 1 },
  { label: "Samoa", value: 685 },
  { label: "San Marino", value: 378 },
  { label: "Sao Tome ve Principe", value: 239 },
  { label: "Senegal", value: 221 },
  { label: "Seyşeller", value: 248 },
  { label: "Sierra Leone", value: 232 },
  { label: "Singapur", value: 65 },
  { label: "Slovakya", value: 421 },
  { label: "Slovenya", value: 386 },
  { label: "Solomon Adaları", value: 677 },
  { label: "Somali", value: 252 },
  { label: "Sri Lanka", value: 94 },
  { label: "Sudan", value: 249 },
  { label: "Surinam", value: 597 },
  { label: "Suriye", value: 963 },
  { label: "Suudi Arabistan", value: 966 },
  { label: "Sırbistan", value: 381 },
  { label: "Şili", value: 56 },
  { label: "Tacikistan", value: 992 },
  { label: "Tanzanya", value: 255 },
  { label: "Tayland", value: 66 },
  { label: "Tayvan", value: 886 },
  { label: "Togo", value: 228 },
  { label: "Tonga", value: 676 },
  { label: "Trinidad ve Tobago", value: 1 },
  { label: "Tunus", value: 216 },
  { label: "Tuvalu", value: 688 },
  { label: "Türkiye", value: 90 },
  { label: "Türkmenistan", value: 993 },
  { label: "Uganda", value: 256 },
  { label: "Ukrayna", value: 380 },
  { label: "Umman", value: 968 },
  { label: "Ürdün", value: 962 },
  { label: "Yeni Zelanda", value: 64 },
  { label: "Yeşil Burun Adaları", value: 238 },
  { label: "Yunanistan", value: 30 }
]
