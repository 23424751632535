import { KSpan } from "kahuna-base-react-components"
import React, { useEffect, useRef } from "react"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { getUnreadNotificationsNumber } from "../../../actions/notificationsActions"

const Notifications = ({
  setOpen,
  unreadNotificationsNumber,
  getUnreadNotificationsNumber
}: {
  setOpen: (open: boolean) => void
  unreadNotificationsNumber: number
  getUnreadNotificationsNumber: () => Promise<any>
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLDivElement | null>(null)
  
  useEffect(() => {
    // Function to close the dropdown if clicked outside
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside)

    getUnreadNotificationsNumber()
    const intervalId = setInterval(() => {
      getUnreadNotificationsNumber()
    }, 1000 * 60 * 3) // 3 minutes

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div className="flex items-center justify-center relative">
      <div
        ref={buttonRef}
        className="flex items-center justify-center cursor-pointer"
        style={{
          borderRadius: "10px",
          height: "36px",
          width: "36px",
          padding: "8px"
          //boxShadow: "rgba(17, 17, 17, 0.04) 0px 0px 0px 1px, rgba(17, 17, 17, 0.04) 0px 1px 1px 0px"
        }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className="relative">
          <img src="/notification.svg" alt="notification-icon" />
          {unreadNotificationsNumber > 0 && (
            <div className="absolute w-4 h-4 flex items-center justify-center top-[-4px] right-[-4px] bg-[#FF5865] rounded-full">
              <KSpan text={unreadNotificationsNumber.toString()} color="#FFF" fontSize={8} lineHeight="12px" fontWeight={500} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    unreadNotificationsNumber: state.notifications.unreadNotificationsNumber
  }
}

export default connect(mapStateToProps, { getUnreadNotificationsNumber })(Notifications)
