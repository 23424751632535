import Login from "../../components/layouts/login/login-page/Login"
import ForgotPassword from "../../components/layouts/login/forgot-password/ForgotPassword"
import ResetPassword from "../../components/layouts/login/reset-password/ResetPassword"
import Account from "../../components/layouts/account/Account"
import { RouteProps } from "../../types"
import Catalog from "../../components/layouts/catalog/Catalog"
import ReleaseDetail from "../../components/layouts/catalog/ReleaseDetail"
import Analysis from "../../components/layouts/analysis/Analysis"
import Reports from "../../components/layouts/reports/Reports"
import AdminUserAsset from "../../components/layouts/admin/AdminUserAsset"
import { adminRoleList, allRoleList, DEFAULT_ROUTE, regularRoleList, USER_ROLE } from "../../constants"
import ReportsApproval from "../../components/layouts/admin/reports-approval/ReportsApproval"
import InvitationAccept from "../../components/layouts/public/invitation/InvitationAccept"
import AdminCatalog from "../../components/layouts/admin/admin-catalog/AdminCatalog"
import SchedulerLog from "../../components/layouts/admin/scheduler-logs/SchedulerLog"
import React from "react"
import AssetRegistry from "../../components/layouts/catalog/asset-registry/AssetRegistry"
import ReleaseRegistry from "../../components/layouts/catalog/release-registry/ReleaseRegistry"
import State51BatchIds from "../../components/layouts/admin/state-51-batch-ids/State51BatchIds"
import ReleaseUpcCodes from "../../components/layouts/admin/release-upc-codes/ReleaseUpcCodes"
import TrackIsrcCodes from "../../components/layouts/admin/track-isrc-codes/TrackIsrcCodes"
import AffiliatePage from "../../components/layouts/affiliate/AffiliatePage"
import ReleaseSmartLinkPage from "../../components/layouts/release-smart-link/ReleaseSmartLinkPage"
import ReleaseSmartLinkPublicPage from "../../components/layouts/release-smart-link/public/ReleaseSmartLinkPublicPage"
import ArtistSmartLinkPage from "../../components/layouts/artist-smart-link/ArtistSmartLinkPage"
import ArtistSmartLinkPublicPage from "../../components/layouts/artist-smart-link/public/ArtistSmartLinkPublicPage"
import AdminTerritoryDsp from "../../components/layouts/admin/territory-dsp/AdminTerritoryDsp"
import Registrations from "../../components/layouts/admin/registrations/Registrations"
import Overview from "../../components/layouts/overview/Overview"
import NewReleaseAction from "../../components/layouts/catalog/asset-registry/new-release-action/NewReleaseAction"
import StartYourJourney from "../../components/layouts/start-your-journey/StartYourJourney"
import NotificationsPage from "../../components/layouts/notifications/NotificationsPage"

export const ROUTES: RouteProps[] = [
  {
    path: "/",
    element: <div />,
    redirect: DEFAULT_ROUTE,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/login",
    element: <Login />,
    private: false
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    private: false
  },
  {
    path: "/forgot-password/:email",
    element: <ResetPassword />,
    private: false
  },
  {
    path: "/overview",
    element: <Overview />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/analytics",
    element: <Analysis />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/reports",
    element: <Reports isAdmin={false} />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/account/profile",
    element: <Account selected={"profile"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/account/language",
    element: <Account selected={"language"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/account/payment-methods",
    element: <Account selected={"paymentMethods"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/account/copyright-and-production",
    element: <Account selected={"copyrightAndProduction"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/account/social-media-links",
    element: <Account selected={"socialMediaLink"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/account/events",
    element: <Account selected={"events"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/account/contracts",
    element: <Account selected={"contracts"} />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/catalog",
    element: <Catalog />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/catalog/new-release",
    element: <NewReleaseAction />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/catalog/:releaseId",
    element: <ReleaseDetail isAdmin={false} />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/catalog/asset-registry/:releaseId",
    element: <AssetRegistry />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/catalog/release-registry/:releaseId",
    element: <ReleaseRegistry />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/admin/user-asset",
    element: <AdminUserAsset />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    path: "/admin/catalog",
    element: <AdminCatalog />,
    private: true,
    permittedRoles: adminRoleList
  },
  {
    path: "/admin/catalog/:releaseId",
    element: <ReleaseDetail isAdmin={true} />,
    private: true,
    permittedRoles: adminRoleList
  },
  {
    path: "/admin/royalty-reports",
    element: <Reports isAdmin={true} />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    path: "/admin/reports-approval",
    element: <ReportsApproval />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    path: "/admin/scheduler-logs",
    element: <SchedulerLog />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    path: "/admin/state51-batch-ids",
    element: <State51BatchIds />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    path: "/admin/upc-codes",
    element: <ReleaseUpcCodes />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    path: "/admin/isrc-codes",
    element: <TrackIsrcCodes />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin]
  },
  {
    path: "/admin/territory-dsp",
    element: <AdminTerritoryDsp />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin]
  },
  {
    path: "/admin/registrations",
    element: <Registrations />,
    private: true,
    permittedRoles: [USER_ROLE.SuperAdmin, USER_ROLE.Admin, USER_ROLE.MarketingAdmin]
  },
  {
    path: "/invitation",
    element: <InvitationAccept />,
    private: false
  },
  {
    path: "/artist/:smartLink",
    element: <ArtistSmartLinkPublicPage />,
    private: false,
    overrideDefaultRoute: true
  },
  {
    path: "/artist-space/",
    element: <ArtistSmartLinkPage />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/smart-link/:smartLink",
    element: <ReleaseSmartLinkPublicPage />,
    private: false,
    overrideDefaultRoute: true
  },
  {
    path: "/catalog/smart-link/:releaseId",
    element: <ReleaseSmartLinkPage />,
    private: true,
    permittedRoles: regularRoleList
  },
  {
    path: "/affiliate",
    element: <AffiliatePage />,
    private: true,
    permittedRoles: allRoleList
  },
  {
    path: "/start-your-journey",
    element: <StartYourJourney />,
    private: false,
    permittedRoles: allRoleList
  },
  {
    path: "/notifications",
    element: <NotificationsPage />,
    private: true,
    permittedRoles: allRoleList
  }  
]
