import React, { useEffect, useState } from "react"
import { releases } from "../../../../apis"
import Table from "../../../ui/table/Table"
import adminTerritoryDspColumns from "./adminTerritoryDspColumns"
import { TerritoryDsp } from "../../../../types"
import { mapListToCamelCase, mapToCamelCase } from "../../../../utility"
import { KButton } from "kahuna-base-react-components"
import { errorFilledToastOptions } from "../../../../constants"
import { toast } from "react-toastify"

const AdminTerritoryDsp = () => {
  const [loading, setLoading] = useState(false)
  const [dspList, setDspList] = useState<TerritoryDsp[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [count, setCount] = useState<number>(1)
  const [limit, setLimit] = useState(20)

  const onDelete = (id: number) => {
    if (!window.confirm(`Are you sure want to delete ID: ${id}?`)) {
      return
    }

    setLoading(true)

    releases(`/release/asset_registry_dsp_delete/?id=${id}`, "delete").then((resp) => {
      if (resp.data.success) {
        setDspList(dspList.filter((row) => row.id !== id))
      } else {
        toast.error(`Error: ${resp.data.message}`, errorFilledToastOptions)
      }
      setLoading(false)
    })
  }

  const onAddNew = () => {
    setLoading(true)
    const label = window.prompt("Please enter label")
    const image = window.prompt("Please enter image url")
    if (!label || !image) {
      return
    }

    releases(`/release/asset_registry_dsp_add/?label=${label}&image=${image}`, "post").then((resp) => {
      if (resp.data.result) {
        setDspList([mapToCamelCase(resp.data.result), ...dspList])
        setLoading(false)
      }
    })
  }

  const colums = adminTerritoryDspColumns(onDelete)

  useEffect(() => {
    setLoading(true)
    releases(`/release/asset_registry_dsp_list/?limit=${limit}`, "get").then((resp) => {
      setDspList(mapListToCamelCase(resp.data.results))
      setCount(resp.data.count)
      setLoading(false)
    })
  }, [])

  const onClickPage = async (pageNumber: number) => {
    setLoading(true)
    releases(`/release/asset_registry_dsp_list/?offset=${(pageNumber - 1) * limit}&limit=${limit}`, "get").then(
      (resp) => {
        setDspList(mapListToCamelCase(resp.data.results))
        setLoading(false)
        setPageNumber(pageNumber + 1)
      }
    )
  }

  return (
    <div className="catalog flex-grow">
      <div className="mt-8 flex justify-end">
        <KButton
          width="220px"
          text="+ Add New Territory Dsp"
          onClick={() => onAddNew()}
          background="black"
          textColor="white"
        />
      </div>
      <div className="mb-16">
        <Table
          title="Territory Dsp List"
          pagination
          minRowCount={limit}
          onClickPage={onClickPage}
          limit={limit}
          columns={colums}
          data={dspList}
          count={count}
          rowHeightInPx={40}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default AdminTerritoryDsp
