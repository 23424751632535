import React from "react"
import { KSpan } from "kahuna-base-react-components"
import { lang } from "../../../constants/languages"
const InfoSection = () => {
  return (
    <div className="flex flex-col gap-3 px-2">
      <a href="https://kahuna.io/pages/terms-of-services" target="_blank">
        <KSpan text={lang.common.terms_of_service}/>
      </a>
      <a href="https://kahuna.io/pages/privacy-policy" target="_blank">
        <KSpan text={lang.common.privacy_policy}/>
      </a>
    </div>
  )
}

export default InfoSection
