import * as React from "react"
import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import Title from "../Title"
import { KDropdown } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { lang, getSelectedLanguage } from "../../../../constants/languages"

type LanguageProps = {
  showTitle: boolean
  dropdownWidth?: number
}

const Language: FC<LanguageProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState<KSelectOption>()
  const [languageOptions, setLanguageOptions] = useState<KSelectOption[]>([])

  useEffect(() => {
    const languageOptions = [
      { label: lang.account.language["tr"], value: 1, value2: "tr" },
      { label: lang.account.language["en"], value: 2, value2: "en" }
    ]
    setLanguageOptions(languageOptions)

    setSelectedOption(languageOptions.find((option) => option.value2 === getSelectedLanguage()))
  }, [])

  const onSelectLanguage = (value) => {
    setSelectedOption(value)
    localStorage.setItem("language", value.value2)
    window.location.reload()
  }

  return (
    <>
      <div className="flex flex-col ">
        {props.showTitle && (
          <div className="flex flex-row justify-between items-center mb-6">
            <Title
              sectionTitle={lang.account.language.title}
              sectionDetail="Select Language"
              iconPath="/navigation_icons/profile_dropdownmenu_icons/language.svg"
            />
          </div>
        )}
        <div>
          {selectedOption && languageOptions.length > 0 && (
            <KDropdown
              width={props.dropdownWidth || 200}
              rightIcon="/analytics_icons/caret-down-new.svg"
              enableIndicator={true}
              defaultValue={selectedOption}
              options={languageOptions}
              onSelect={(value) => onSelectLanguage(value)}
            />
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {}
}

export default connect(mapStateToProps, {})(Language)
