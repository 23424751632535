import "../styles/Analysis.css"
import React, { FC, useEffect, useState } from "react"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import { fetchTopSongs, fetchTotals } from "../../../../actions/analysisActions"
import { AnalysisTopSongsProps, AnalysisTopSongs as TopSongs } from "../../../../types"
import analysisTopSongsColumns from "./analysisTopSongsColumns"
import { chartLabels, defaultTopSongsFilter } from "../../../../constants"
import AnalysisTable from "./AnalysisTable"
import TopSongsFilters from "./TopSongsFilters"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { lang } from "../../../../constants/languages"

const AnalysisTopSongs: FC<AnalysisTopSongsProps> = (props) => {
  const { fetchTopSongs, topSongs, dateOptions, fetchTotals } = props
  
  const [platformOptions, setPlatformOptions] = useState<KSelectOption[]>([{label: lang.common.chartLabels.platform_options_top_songs, value: 0, value2: "top_songs"}])

  const [loading, setLoading] = useState(false)
  const [platform, setPlatform] = useState(defaultTopSongsFilter)
  const [data, setData] = useState<TopSongs[]>([])


  const filterData = (platform: string, date?: string) => {
    setPlatform(platform)
    if (date) {
      // date degistiginde api request oluyor. date degismezse bosuna api req olmasin diye bu sekilde yaptim.
      setLoading(true)
      fetchTopSongs(date).then(() => setLoading(false))
    }
  }
  
 /* useEffect(() => {
    const getTotals = async () => {
      await fetchTotals()
    }

    getTotals().then(() => setLoading(true))
  }, [])*/

  useEffect(() => {
    const valueList: string[] = []
    const optionList: KSelectOption[] = []
    if (platform) {
      const filteredSongs = topSongs.filter((ts) => ts.platform!.toLowerCase() === platform.toLowerCase())
      setData(filteredSongs)
    }


    topSongs.forEach((topSong, index) => {
      const option = {
        label: chartLabels["platform"][topSong.platform] || topSong.platform,
        value: index,
        value2: topSong.platform
      }

      if (!valueList.includes(topSong.platform)) {
        optionList.push(option)
        valueList.push(topSong.platform)
      }
    })
    const optionListSorted = optionList.sort((a, b) => a.label.localeCompare(b.label))

    setPlatformOptions(optionListSorted)
  }, [topSongs])

  useEffect(() => {
    if (platform) {
      const filteredSongs = topSongs.filter((ts) => ts.platform!.toLowerCase() === platform.toLowerCase())
      setData(filteredSongs)
    }
  }, [platform])

  return (
    <>
      <div className={"pb-[56px] pt-[56px]"}>
        <div className="flex flex-col justify-start">
          <AnalysisTable
            title={lang.overview.analysis_top_songs.title}
            columns={analysisTopSongsColumns}
            data={data}
            topRightElement={<TopSongsFilters dateOptions={dateOptions} filterData={filterData} platformOptions={platformOptions} />}
            platform={platform}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    analysis: state,
    date: state.analysis.selectedDate,
    dateOptions: state.analysis.totals,
    topSongs: state.analysis.topSongs
  }
}

export default connect(mapStateToProps, { fetchTopSongs, fetchTotals })(AnalysisTopSongs)
