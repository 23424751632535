import { NOTIFICATIONS_ACTION } from "../actions/notificationsActions"
import { Action, UserNotificationsState } from "../types"

export default (state = new UserNotificationsState(), action: Action) => {
  switch (action.type) {
    case NOTIFICATIONS_ACTION.FETCH_UNREAD_NOTIFICATIONS_NUMBER:
      return { ...state, unreadNotificationsNumber: action.payload }
      case NOTIFICATIONS_ACTION.FETCH_NOTIFICATIONS:
        return { ...state, paginatedNotifications: action.payload }
    default:
      return state
  }
}
