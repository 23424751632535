import { Dialog } from "@headlessui/react"
import { FC, useEffect, useState } from "react"
import { errorFilledToastOptions, successFilledToastOptions, tableCellClasses } from "../../../../constants"
import { X } from "heroicons-react"
import * as React from "react"
import { KButton, KCodeInput, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { codeValidation, smartLinks } from "../../../../apis"
import { toast } from "react-toastify"
import { AUTH_ACTION } from "../../../../actions/loginActions"
import axios from "axios"

//@ts-ignore
import SpotifyPlatformIcon from "../../../../assets/release-platform-icons/SpotifyBlack.png"
import { lang } from "../../../../constants/languages"

type PlaylistSelectModalProps = {
  open: boolean
  onClose: () => void
  options: KSelectOption[]
  onSelectPlaylist: (playlist: KSelectOption) => void
  preSaveId?: number
  albumTitle?: string
  releaseDate?: string
}
const PlaylistSelectModal: FC<PlaylistSelectModalProps> = (props) => {
  const { options, onClose, open, onSelectPlaylist, preSaveId, albumTitle, releaseDate } = props

  const [selectedPlaylist, setSelectedPlaylist] = useState<KSelectOption | undefined>(undefined)
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [checkTerms, setCheckTerms] = useState(false)

  const getEmailValidationCode = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")

    try {
      setLoading(true)
      const response = await codeValidation.post("/send_validation/?subject=Code Validation", { email, ip_address: res.data.ip })
      toast.dismiss(AUTH_ACTION.FORGOT_PASSWORD)
      if (response.status === 200) {
        toast.success(lang.catalog.smart_link.success.validation_code_sent, successFilledToastOptions)
      } else {
        toast.error(lang.catalog.smart_link.error.error_on_code_sending, errorFilledToastOptions)
      }
      setLoading(false)
      setCodeSent(true)
    } catch {
      toast.error(lang.catalog.smart_link.error.error_on_code_sending, errorFilledToastOptions)
      setLoading(false)
      setCodeSent(false)
    }
  }

  const submitCode = () => {
    console.log(code)
    smartLinks
      .post("/validate_email/", { email, code, pre_save_id: preSaveId })
      .then((resp) => {
        if (resp.data.success) {
          toast.success(lang.catalog.smart_link.success.thanks, successFilledToastOptions)
          onClose()
        } else {
          toast.error(lang.catalog.smart_link.error.invalid_code, errorFilledToastOptions)
        }
      })
      .catch(() => {
        toast.error(lang.catalog.smart_link.error.invalid_code, errorFilledToastOptions)
      })
  }

  const close = () => {
    setSelectedPlaylist(undefined)
    onClose()
  }

  const renderEmailValidation = () => {
    return codeSent ? (
      <div>
        <div className="justify-center flex">
          <KTitleSpan color="#000" fontWeight={500} text={"Check your inbox"} fontSize={24} />
        </div>
        <div className="mb-3 justify-center flex">
          <span style={{ fontFamily: "Inter", fontSize: 14, textAlign: "center" }}>
            {lang.catalog.smart_link.activation_code_sent}
          </span>
        </div>
        <div className="pt-6">
          <KCodeInput onChange={(text) => setCode(text)} />
        </div>
      </div>
    ) : (
      <div>
        <div className="justify-center flex">
          <KTitleSpan color="#000" fontWeight={500} text={lang.catalog.smart_link.subscribe} fontSize={24} />
        </div>
        <div className="mb-3 justify-center flex">
          <span style={{ fontFamily: "Inter", fontSize: 14 }}>{lang.catalog.smart_link.support_your_artist}</span>
        </div>
        <div className="pt-6">
          <KInput
            leftIcon="/account_icons/mail.svg"
            value={email}
            onChange={(text) => setEmail(text)}
            placeholder={lang.catalog.smart_link.email_address_placeholder}
          />
        </div>
        <div className="flex mt-4">
          <div>
            <KInput
              value={"checkTerms"}
              onChange={() => setCheckTerms(!checkTerms)}
              type={"checkbox"}
              shadowDisabled={true}
              width={16}
              height={16}
              accentColor={"black"}
            />
          </div>
          <div className={"mt-1"} style={{ maxWidth: 600 }}>
            <KSpan
              text="Join the artist mailing list and receive future marketing communications from this promoter in accordance with Kahuna's "
              color="#1F1F1F"
              fontWeight={500}
            />
            <a
              href="https://kahuna.io/pages/privacy-policy"
              target="_blank"
              className="ml-1"
              style={{ textDecoration: "underline" }}
            >
              <KSpan text="Privacy Policy." color="#1F1F1F" fontWeight={500} />
            </a>
          </div>
        </div>
      </div>
    )
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString(lang.common.locale, { month: "long", day: "numeric" })
  }

  return (
    <Dialog open={open} onClose={() => close()} className="relative z-50">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="mt-10 bg-white elev-b-sm rounded-lg overflow-hidden p-4" style={{ width: 450 }}>
          <div className="mt-4 pl-4 pr-4 pb-10">
            {preSaveId ? (
              <div>
                <div className="mb-4 justify-center flex">
                  <img className="select-playlist-icon" src="/account_icons/plus.svg" />
                </div>
                {renderEmailValidation()}
              </div>
            ) : (
              <div>
                <div className="mb-4 justify-center flex">
                  <img className="select-playlist-icon" src={SpotifyPlatformIcon} />
                </div>
                <div className="justify-center flex">
                  <KTitleSpan color="#000" fontWeight={500} text={lang.catalog.smart_link.almost_done} fontSize={24} />
                </div>
                <div className="mb-3 justify-center flex">
                  <span style={{ fontFamily: "Inter", fontSize: 14, textAlign: "center" }}>
                    <b>{albumTitle}</b> {lang.catalog.smart_link.will_be_added} <b>{formatDate(releaseDate)}</b>
                  </span>
                </div>
                <div className="pt-6 pb-6">
                  <div className="absolute">
                    <KDropdown
                      placeholder={lang.catalog.smart_link.select_playlist}
                      leftIcon="/account_icons/music-album.svg"
                      rightIcon="/account_icons/caret-down.svg"
                      width={385}
                      gap={"8px"}
                      selected={selectedPlaylist}
                      options={options}
                      //@ts-ignore
                      onSelect={(element: KSelectOption) => {
                        if (element) {
                          setSelectedPlaylist(element)
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex pt-4 ml-4 mr-4">
            <KButton background="transparent" shadowDisabled={true} text={lang.common.cancel} onClick={() => close()} />
            <KButton
              background="black"
              textColor="white"
              disabled={loading || (preSaveId !== undefined && !checkTerms)}
              text={codeSent ? lang.common.submit : lang.common.confirm}
              onClick={() => {
                if (codeSent) {
                  submitCode()
                } else if (preSaveId) {
                  getEmailValidationCode()
                } else if (selectedPlaylist) {
                  onSelectPlaylist(selectedPlaylist)
                }
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PlaylistSelectModal
