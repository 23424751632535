import React, { useEffect, useState } from "react"
//@ts-ignore
import NotificationIcon from "../../../assets/notifications_icons/notification.svg"
//@ts-ignore
import CheckCircleIcon from "../../../assets/notifications_icons/check-circle-black.svg"
//@ts-ignore
import StickyNoteIcon from "../../../assets/notifications_icons/sticky-note.svg"
//@ts-ignore
import OpenInNewTabIcon from "../../../assets/notifications_icons/open-new-tab.svg"
import { KButton, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import { notifications } from "../../../apis"
import { GroupedNotifications, UserNotification, PaginatedData } from "../../../types"
import { buttonConnectedURL, buttonText, getDayDifference, groupByDates, notificationContent } from "../../../utility"
import { connect } from "react-redux"
import { RootState } from "../../../store"
import { getNotifications, getUnreadNotificationsNumber, markAsRead } from "../../../actions/notificationsActions"
import { lang } from "../../../constants/languages"
import { useNavigate } from "react-router-dom"

const NotificationsModal = ({
  open,
  setOpen,
  getUnreadNotificationsNumber,
  getNotifications,
  paginatedNotifications,
  markAsRead
}: {
  open: boolean
  setOpen: (open: boolean) => void
  getUnreadNotificationsNumber: () => Promise<any>
  getNotifications: (pageNumber: number) => Promise<any>
  paginatedNotifications: PaginatedData<UserNotification>
  markAsRead: (id: string, readAll?: boolean) => Promise<any>
}) => {
  const [groupedNotifications, setGroupedNotifications] = useState<GroupedNotifications>({} as GroupedNotifications)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (open && paginatedNotifications.results.length === 0) {
      setLoading(true)
      getNotifications(1)
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
    }
  }, [open])

  useEffect(() => {
    const paginatedNotificationsSliced = [...paginatedNotifications.results].slice(0, 5)
    const groupedElements = groupByDates(paginatedNotificationsSliced)

    setGroupedNotifications(groupedElements)
  }, [paginatedNotifications.results])

  return (
    <div
      className="fixed z-[400] w-screen h-screen top-0 left-0 items-center justify-center bg-[#0000004d]"
      style={{
        display: open ? "flex" : "none"
      }}
      onClick={() => {
        setOpen(false)
      }}
    >
      {!loading && (
        <div
          className="w-[90%] max-w-[750px] flex flex-col gap-6 bg-white px-12 py-6 rounded-[10px] relative"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
          }}
        >
          <div className="absolute top-6 right-6">
            <KTooltip
              backgroundColor="#444"
              border="none"
              showArrow
              arrowColor="#444"
              content={
                <div className="min-w-max">
                  <KSpan color="#FFF" text={lang.notifications.open_in_full_screen} fontWeight={500} />
                </div>
              }
            >
              <KButton
                icon={OpenInNewTabIcon}
                onClick={() => {
                  navigate("/notifications")
                  setOpen(false)
                }}
                shadowDisabled
                background="#FFF"
              />
            </KTooltip>
          </div>
          <div className="flex flex-col gap-6 justify-between items-center">
            <div className="flex items-center justify-center">
              <div
                className="w-[72px] aspect-square flex items-center justify-center"
                style={{
                  border: "1px solid #F3F3F3",
                  background: "#FFF",
                  borderRadius: "999px"
                }}
              >
                <img src={NotificationIcon} alt="notification-icon" />
              </div>
            </div>
            <KTitleSpan text={lang.notifications.title} color="#000" fontSize={32} lineHeight="40px" />
          </div>
          <div className="flex flex-col gap-6">
            {Object.keys(groupedNotifications).length > 0 &&
              Object.keys(groupedNotifications).map((key: string, index: number) => {
                return (
                  <div key={`${key}-${index}`} className="flex flex-col gap-3">
                    <div className="flex flex-row ml-3 justify-between items-center h-12">
                      <div className="flex flex-row gap-2">
                        <KSpan text={`${key.toUpperCase()}`} color="#000" fontWeight={500} />
                      </div>
                      {index === 0 && (
                        <KButton
                          text={lang.notifications.mark_all_as_read}
                          leftIcon={CheckCircleIcon}
                          width="auto"
                          onClick={() => {
                            markAsRead("", true).then(() => getUnreadNotificationsNumber())
                          }}
                          textColor="#000"
                          fontWeight={500}
                          background="transparent"
                          shadowDisabled
                          gap="4px"
                        />
                      )}
                    </div>
                    {groupedNotifications[key].notifications.length > 0 &&
                      groupedNotifications[key].notifications.map(
                        (notification: UserNotification, subIndex: number) => {
                          return (
                            <div
                              key={`${notification.param1}-${subIndex}`}
                              className="min-h-16 flex flex-row justify-between items-start gap-5 py-3 px-3 cursor-pointer"
                              style={{
                                opacity: notification.read ? "0.50" : "1"
                              }}
                              onClick={() => {
                                if (!notification.read) {
                                  markAsRead(notification.id.toString()).then(() => getUnreadNotificationsNumber())
                                }
                              }}
                            >
                              <div
                                className="w-2 h-2 rounded-full self-center shrink-0"
                                style={{
                                  backgroundColor: notification.read ? "transparent" : "#0C66E4"
                                }}
                              />
                              <div className="grow flex flex-row gap-3 items-start">
                                <div className="h-10 w-10 flex items-center justify-center rounded-full bg-[#F7F7F7] shrink-0">
                                  <img src={StickyNoteIcon} alt="sticky-note-icon" />
                                </div>
                                <div className="flex flex-col gap-0.5">
                                  <KSpan text={notificationContent(notification)} color="#666" />
                                  <KSpan
                                    text={getDayDifference(notification.created)}
                                    color="#666"
                                    fontSize={12}
                                    lineHeight="16px"
                                  />
                                </div>
                              </div>
                              <div className="w-auto shrink-0 flex gap-3">
                                {notification.buttonParam && (notification.urlParam || notification.relatedUrl) && (
                                  <KButton
                                    text={buttonText(notification)}
                                    background="#F7F7F7"
                                    hoverBackground="#E3E3E3"
                                    onClick={() => {
                                      const buttonConnecttedURL = buttonConnectedURL(notification)
                                      if (buttonConnecttedURL) {
                                        window.location.href = buttonConnecttedURL
                                        setOpen(false)
                                      }
                                    }}
                                    fontWeight={500}
                                    textColor="#000"
                                    height="36px"
                                  />
                                )}
                              </div>
                            </div>
                          )
                        }
                      )}
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    paginatedNotifications: state.notifications.paginatedNotifications
  }
}

export default connect(mapStateToProps, { getUnreadNotificationsNumber, getNotifications, markAsRead })(
  NotificationsModal
)
