import React, { useEffect, useState } from "react"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { saveRegistration, StartYourJourneyPages, Details } from "../StartYourJourney"
import { SelectOption } from "../../../../types"
import { REGISTERED_USER_TYPES } from "../../../../constants"

const CalendlyPage = (props: {
  selectedPage: number
  setSelectedPage: (page: number) => void
  details: Details
  setDetails: (details: Details) => void
  completedSteps: { first: boolean; second: boolean; third: boolean; fourth: boolean }
  setCompletedSteps: (steps: { first: boolean; second: boolean; third: boolean; fourth: boolean }) => void

}) => {


  useCalendlyEventListener({
    onDateAndTimeSelected: (e) => {
      // do nothing
    },
    onEventScheduled: (e) => {
      const calendlyId = e.data.payload.event.uri
      const calendlyIdText = calendlyId.replace("https://api.calendly.com/scheduled_events/", "")
      props.setSelectedPage(StartYourJourneyPages.THANK_YOU)
      const artistsName = props.details.artist.map((artist:SelectOption) => artist.label).join(", ")
      saveRegistration(
        calendlyIdText,
        "",
        "",
        REGISTERED_USER_TYPES.DEMO,
        "",
        new Date().toISOString(),
        props.details.contactName,
        props.details.country,
        props.details.email,
        props.details.phoneNumber,
        props.details.role?.label || "",
        artistsName,
        props.details.hearingSource
      )
    }
  })

  const prefill = {
    name: props.details.contactName,
    email: props.details.email
  }

  //TODO: might be neccessary, do not delete for now
  /* const fetchEventDetails = async (meet_id: string, retries = 5) => {
        try {
            const response = await axios.get(`https://api.calendly.com/scheduled_events/${meet_id}/invitees`, {
                headers: {
                  Authorization: `Bearer ${token}`, 
                  'Content-Type': 'application/json'
                }
              })
        } catch (err) {
          if (retries > 0) {
            setTimeout(() => fetchEventDetails(meet_id, retries - 1), 2000) // Retry after 2 seconds
          } else {
          }
        }
      }*/

  return (
    <div className="w-full">
      <InlineWidget
        url="https://calendly.com/meetkahuna/30min"
        prefill={prefill}
        styles={{
          height: "100vh",
          width: "100%",
          border: 0
        }}
      />
    </div>
  )
}

export default CalendlyPage
