import { formatDateTurkish, getDuration, turkishCharMap } from "."
import { state51GenreMapping, state51LanguageMapping } from "../constants"
import { LCReleaseForm } from "../types"

export const notAllowedProductRelationships = ["company", "default-offer", "distributor", "offers", "performers", "tracks"]
export const notAllowedProductAttributes = [
  "created-at",
  "updated-at",
  "cover-url",
  "full-cover-url",
  "rights-completed",
  "metadata-completed",
  "tracks-metadata-completed",
  "booklet-url",
  "has-play-page",
  "audio-completed",
  "validatable",
  "default-product-offer-completed",
  "is-mfit",
  "is-destroyable",
  "audio16bits",
  "audio24bits",
  "all16bits",
  "all24bits",
  "audios",
  "is-back-catalogue",
  "roles",
  "status",
  "product-type-name",
  "front-cover-md5",
  "computed-rights",
  "release-date",
  "default-offer-validation"
]

export const notAllowedOfferRelationships = ["created-at"]
export const notAllowedOfferAttributes = [
  "created-at",
  "updated-at",
  "release-type",
  "displayed-territories",
  "active",
  "is-default",
  "name",
  "preview-before-release-date",
  "status",
  "usage"
]

export const notAllowedTrackRelationships = ["label", "default-track-offer", "records"]
export const notAllowedTrackAttributes = [
  "roles",
  "created-at",
  "updated-at",
  "original-id",
  "preview-url",
  "length",
  "audio16bits",
  "audio24bits",
  "all16bits",
  "all24bits",
  "audios",
  "master-types"
]
export const notAllowedTrackOfferRelationships = ["created-at"]
export const notAllowedTrackOfferAttributes = ["created-at", "updated-at", "download", "stream"]


export const processCatalogNoString = (str: string, labelId: string) => {
  let catalogNo = str.replace(/[çÇğĞıİöÖşŞüÜ]/g, (match) => turkishCharMap[match])
  catalogNo = catalogNo.replace(/[aeiouAEIOU ]/g, "").toUpperCase()
  while (catalogNo.length < 8) {
    catalogNo += "0"
  }

  catalogNo = catalogNo.slice(0, 8)

  return `${catalogNo}${labelId}`
}

export const processCatalogString = (
  str: string,
  index: number,
  labelName: string,
  releaseForm: LCReleaseForm,
  bmwCustomIdKeyword?: string
) => {
  const userBmwCustomId = bmwCustomIdKeyword || labelName.substring(0, 2)

  let catalogNo = str.replace(/[çÇğĞıİöÖşŞüÜ]/g, (match) => turkishCharMap[match])
  catalogNo = catalogNo.replace(/[aeiouAEIOU ]/g, "").toUpperCase()
  while (catalogNo.length < 5) {
    catalogNo += "0"
  }
  catalogNo = catalogNo.slice(0, 5)

  const trackNumber = index.toString().padStart(4, "0")

  const labelId = releaseForm.product.data.relationships.label?.data?.id
  let channelId = "UCpi46yeWw2cWYM2K5lP_yCw"
  if (labelId === "208632" || labelId === "359442") {
    channelId = "UCWLXZVmRTZjqg7VBK4yPs5g"
  }

  return `${userBmwCustomId}_${catalogNo}_${trackNumber}_CHANNEL_${channelId}`
}

export const buildBMVTrackList = (releaseForm: LCReleaseForm, options: any, bmwCustomIdKeyword?: string) => {
  return releaseForm.tracks?.map((track, index) => {
    const product = releaseForm.product.data

    const labelName =
      options.labelOptions?.find((label) => label.value === product.relationships.label?.data?.id)?.label || ""

    const releaseName = product.attributes.name
    const releaseNameFiltered = releaseName.replace(/\(.*?\)/g, "").trim()

    const releaseGenre = options.distributorProductSubGenreOptions.find(
      (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
    )?.label

    let releaseGenreMapped = ""
    if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
      releaseGenreMapped = state51GenreMapping[releaseGenre]
    }

    const requestBody = {
      filename: `${track.data.id}.mp3`,
      custom_id: processCatalogString(releaseName, index + 1, labelName, releaseForm, bmwCustomIdKeyword),
      asset_id: null,
      isrc: track.data.attributes["isrc-code"],
      add_asset_labels: `Kahuna Music|${labelName}|${releaseNameFiltered}`,
      upc: product.attributes["upc-code"],
      grid: null,
      song_title: track.data.attributes["title"],
      artist: track.data.attributes["artist"].join("|"),
      artist_isni: null,
      album: releaseName,
      genre: releaseGenreMapped,
      label: labelName,
      original_release_date: null,
      ownership: "WW",
      match_policy: "Monetize in all countries"
    }
    return Object.values(requestBody)
  })
}

export const buildState51TrackList = (releaseForm: LCReleaseForm, options: any) => {
  const product = releaseForm.product.data

  const labelName =
    options.labelOptions?.find((label) => label.value === product.relationships.label?.data?.id)?.label || ""

  const releaseGenre = options.distributorProductSubGenreOptions.find(
    (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
  )?.label

  let releaseGenreMapped = ""
  if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
    releaseGenreMapped = state51GenreMapping[releaseGenre]
  }

  const trackList = releaseForm.tracks?.map((track, index) => {
    const audioLanguage =
      options.languageOptions.find(
        (language) => language.value === track?.data.relationships["audio-language"]?.data?.id
      )?.label ||
      options.metadataLanguageOptions.find(
        (language) => language.value === releaseForm.product.data.relationships.language?.data?.id
      )?.label

    let languageMapped = "No linguistic content - zxx"
    if (audioLanguage && Object.keys(state51LanguageMapping).includes(audioLanguage)) {
      languageMapped = state51LanguageMapping[audioLanguage]
    }

    const parentalAdvisory = track.data.attributes["parental-advisory"] || false
    const clean = track.data.attributes["clean"] || false
    let explicitContent = "cleaned"
    if (!parentalAdvisory && !clean) explicitContent = "no"
    else if (parentalAdvisory && !clean) explicitContent = "yes"

    const requestBody = {
      id: `${track.data.id}`,
      "volume-number": track.data.attributes["volume-number"].toString().padStart(2, "0"),
      "track-number": track.data.attributes["track-number"].toString().padStart(2, "0"),
      isrc: track.data.attributes["isrc-code"],
      "explicit-content": explicitContent,
      title: track.data.attributes["title"],
      subtitle: track.data.attributes["subtitle"] || "",
      artist: track.data.attributes["artist"].join("|"),
      "production-line":
        track.data.attributes["production-line"] || releaseForm.product.data.attributes["production-line"],
      producer: track.data.attributes["producer"]?.join("|") || "",
      composer: track.data.attributes["composer"]?.join("|") || "",
      lyricist: track.data.attributes["lyricist"]?.join("|") || "",
      publisher: track.data.attributes["publisher"]?.join("|") || "",
      "audio-language": languageMapped
    }
    return requestBody
  })

  return {
    product: {
      "cover-url": releaseForm.product.data.attributes["full-cover-url"],
      "release-date":
        releaseForm.product.data.attributes["release-date"] || releaseForm.offer.data.attributes["release-date"],
      "label-internal-reference": releaseForm.product.data.attributes["label-internal-reference"],
      name: releaseForm.product.data.attributes["name"],
      subtitle: releaseForm.product.data.attributes["subtitle"] || "",
      artist: releaseForm.product.data.attributes["artist"].join("|"),
      "upc-code": releaseForm.product.data.attributes["upc-code"],
      "product-type-name": releaseForm.product.data.attributes["product-type-name"],
      "production-line": releaseForm.product.data.attributes["production-line"],
      "copyright-line": releaseForm.product.data.attributes["copyright-line"],
      label: labelName,
      genre: releaseGenreMapped,
      featuring: releaseForm.product.data.attributes["featuring"]?.join("|") || ""
    },
    track_list: trackList
  }
}

export const buildMesamMsgTrackList = (releaseForm: LCReleaseForm, options: any) => {
  return releaseForm.tracks?.map((track, index) => {
    const releaseGenre = options.distributorProductSubGenreOptions.find(
      (subgenre) => subgenre.value === releaseForm.offer.data?.relationships["subgenre"]?.data?.id
    )?.label

    let releaseGenreMapped = ""
    if (releaseGenre && Object.keys(state51GenreMapping).includes(releaseGenre)) {
      releaseGenreMapped = state51GenreMapping[releaseGenre]
    }

    const currentDate = new Date()

    const requestBody = {
      submissionDateAndTime: formatDateTurkish(currentDate),
      trackName: track.data.attributes["title"],
      trackName2: track.data.attributes["title"],
      lyrics: track.data.attributes["lyrics"]?.split("\n")[0] || "",
      genre: releaseGenreMapped,
      duration: getDuration(track),
      artist: track.data.attributes["artist"].join(" & "),
      producerName: "", // Do Nothing
      producerCode: "", // Do Nothing
      productionYear: releaseForm.product.data.attributes["production-year"],
      audioFileExist: "YES",
      lyricsExist: "YES",
      composer1Name: "", // Filled in backend  12
      composer1Share: "", // Filled in backend
      composer2Name: "", // Filled in backend  14
      composer2Share: "", // Filled in backend
      composer3Name: "", // Filled in backend 16
      composer3Share: "", // Filled in backend
      composer4Name: "", // Filled in backend 18
      composer4Share: "", // Filled in backend
      lyricist1Name: "", // Filled in backend 20
      lyricist1Share: "", // Filled in backend
      lyricist2Name: "", // Filled in backend 22
      lyricist2Share: "", // Filled in backend
      lyricist3Name: "", // Filled in backend 24
      lyricist3Share: "", // Filled in backend
      lyricist4Name: "", // Filled in backend 26
      lyricist4Share: "", // Filled in backend
      adaptor1: "", // Do Nothing
      adaptor2: "", // Do Nothing
      adaptor3: "", // Do Nothing
      adaptor4: "", // Do Nothing
      arranger1Name: "", // Filled in backend 32
      arranger1Share: "", // Filled in backend
      arranger2Name: "", // Filled in backend 34
      arranger2Share: "", // Filled in backend
      arranger3Name: "", // Filled in backend 36
      arranger3Share: "", // Filled in backend
      editorName: "", // Filled in backend 38
      editorShare: "", // Filled in backend
      submissionDay: currentDate.getDate(),
      submissionMonth: currentDate.getMonth() + 1,
      submissionYear: currentDate.getFullYear() % 10,
      isrc: track.data.attributes["isrc-code"] //43
    }
    return Object.values(requestBody)
  })
}
