import React, { FC } from "react"
//@ts-ignore
import ClockIcon from "../../../../assets/start_your_journey_icons/clock.svg"
import { KButton, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { lang } from "../../../../constants/languages"

type ThankYouProps = {

}

const ThankYouPage: FC<ThankYouProps> = (props) => {
  return (
    <div className="" style={{ height: "100%", width: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <div className={"h-full w-full flex flex-col justify-center items-center "}>
          <div className="px-4 flex flex-col w-[50%] min-w-[616px] max-w-[680px] max-[636px]:min-w-[100%] overflow-auto">
            <div className={"flex justify-center "}>
              <div
                className="w-24 h-24 p-4 flex items-center justify-center rounded-full"
                style={{
                  boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)",
                  border: "1px solid #F3F3F3"
                }}
              >
                <img src={ClockIcon} alt="clock" />
              </div>
            </div>
            <div className={"flex justify-start mt-6"}>
              <div className={"flex max-[850px]:!text-center"}>
                <KTitleSpan
                  text={lang.start_your_journey.thank_you_page.title}
                  fontSize={32}
                  lineHeight={"40px"}
                  fontWeight={500}
                />
              </div>
            </div>
            <div className={"flex flex-col justify-center"}>
              <div className={"flex mt-4"}>
                <div className={"flex"}>
                  <KSpan
                    text={lang.start_your_journey.thank_you_page.desc}
                    color="#666"
                    fontSize={16}
                  />
                </div>
              </div>
              <div className={"flex mt-8"}>
                <div
                  className={`flex`}
                  style={{ width: "100%" }}
                >
                  <KButton
                    background="#000"
                    padding="14px"
                    height="48p"
                    textColor="white"
                    onClick={() => {
                      window.location.href = "https://kahuna.io"
                    }}
                    text={lang.start_your_journey.thank_you_page.go_to_kahuna_website}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThankYouPage
