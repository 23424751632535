import { errorFilledToastOptions } from "../../../../constants"
import { lang } from "../../../../constants/languages"
import { PaymentMethod } from "../../../../types"
import { toast } from "react-toastify"

const paymentMethodValidator = (formValues: PaymentMethod) => {
  const errors: any = {}

  if (!formValues.bankName) {
    errors.bankName = lang.account.payment_methods.payment_form.error.bank_name_blank
    toast.error(lang.account.payment_methods.payment_form.error.bank_name_blank, errorFilledToastOptions)
  }
  if (!formValues.accountHolderName) {
    errors.accountHolderName = lang.account.payment_methods.payment_form.error.account_holder_name_blank
    toast.error(lang.account.payment_methods.payment_form.error.account_holder_name_blank, errorFilledToastOptions)
  }
  if (!formValues.iban) {
    errors.iban = lang.account.payment_methods.payment_form.error.iban_blank
    toast.error(lang.account.payment_methods.payment_form.error.iban_blank, errorFilledToastOptions)
  }

  return errors
}

export default paymentMethodValidator
