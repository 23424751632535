import { Action, Dispatch } from "redux"
import { handleAuthorizedRequest, userPayments, users } from "../apis"
import { toast } from "react-toastify"
import { successFilledToastOptions } from "../constants"

export const REPORTS_ACTION = {
  FETCH_DATA: "FETCH_DATA",
  DOWNLOAD_REPORT: "DOWNLOAD_REPORT"
}

export const fetchReportsData = (userId?: number, approveDisabled?: boolean) => async (dispatch: Dispatch<Action>) => {
  await handleAuthorizedRequest(
    async () => {
      const userIdParam = userId ? `?user_id=${userId}` : ""
      const approveDisabledParam = approveDisabled ? `&approve_disabled=1` : ""
      const response = await userPayments(`/quarterly_payments/${userIdParam}${approveDisabledParam}`, "get")
      const data = response.data.quarterly_data.map((item, index) => ({
        ...item,
        index: index + 1
      }))
      dispatch({
        type: REPORTS_ACTION.FETCH_DATA,
        payload: data
      })
    },
    "Error fetching payment history: ",
    dispatch
  )
}
export const downloadReport = (quarter: string, userId?: number, approveDisabled?: boolean) => async (dispatch: Dispatch<Action>) => {
  const messageQuarter = `${quarter.split("q")[0]} quarter ${quarter.split("q")[1]}`
  await handleAuthorizedRequest(
    async () => {
      const userIdParam = userId ? `&user_id=${userId}` : ""
      const approveDisabledParam = approveDisabled ? `&approve_disabled=1` : ""
      const response = await users(`/user_report_create/?date=${quarter}${userIdParam}${approveDisabledParam}`, "post")
      dispatch({
        type: REPORTS_ACTION.DOWNLOAD_REPORT,
        payload: response
      })
      toast.success(`Your report for ${messageQuarter} will be sent to your e-mail.`, successFilledToastOptions)
    },
    "Download report failed.",
    dispatch
  )
}
