import { KDropdown } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import React, { useEffect, useState } from "react"
import { lang } from "../../../../constants/languages"

const TopSongsFilters = ({dateOptions, filterData, platformOptions}:{
  dateOptions: { date: string; totalNet: number; }[];
  filterData: (platform:string, data?:string) => void
  platformOptions: KSelectOption[]
}) => {


   const convertToMonthYear = (basicDateFormat: string): string => {
    const year = basicDateFormat.slice(0, 4)
    const month = basicDateFormat.slice(4)

    const monthNames = lang.common.months_short

    return `${monthNames[Number(month) - 1]} ${year}`
  }

  const transformDateArrayIntoDateOptions = (options: { date: string; totalNet: number }[]): KSelectOption[] => {
    const objectArray: KSelectOption[] = []

    for (let i = 0; i < options.length; i++) {
      const newObj: KSelectOption = {
        label: convertToMonthYear(options[i].date),
        value: i,
        value2: options[i].date
      }
      objectArray.push(newObj)
    }
    return objectArray
  }
  const [dates, setDates] = useState<KSelectOption[]>(transformDateArrayIntoDateOptions(dateOptions))

  useEffect(() => {
    if (dates.length === 0) {
      setDates(transformDateArrayIntoDateOptions(dateOptions))
    }
  }, [dateOptions])

  const [platform, setPlatform] = useState<KSelectOption>(platformOptions[0])
  const [date, setDate] = useState<KSelectOption>({ label: "", value: -1, value2: "" })

  useEffect(() => {
    if (dates.length > 0) {
      setDate(dates[dates.length - 1])
    }
  }, [dates])

  const onSelect = (selected: KSelectOption | any) => {
    setPlatform(selected)
  }

  useEffect(() => {
    if (date.value2 && platform.value2) {
      filterData(platform.value2)
    }
  }, [platform])

  useEffect(() => {
    if (date.value2 && platform.value2) {
      filterData(platform.value2, date.value2)
    }
  }, [date])

  return (
    // TODO: icon missing in platform selection dropdown list
    <div className="flex flex-row justify-between gap-3">
      {date.value !== -1 && (
        <KDropdown
          options={dates}
          shadowDisabled={true}
          selected={date}
          defaultValue={date}
          width={160}
          rightIcon="/chevron-down.svg"
          leftIcon="/calendar_date.svg"
          onSelect={(selected: KSelectOption | any) => {
            setDate(selected)
          }}
          enableIndicator
        />
      )}
      {date.value !== -1 && <img src="/vector.svg" alt="vector-icon" />}
      <KDropdown
        options={platformOptions}
        shadowDisabled={true}
        defaultValue={platformOptions[0]}
        selected={platformOptions[0]}
        onSelect={(selected) => {
          onSelect(selected)
        }}
        width={160}
        rightIcon="/chevron-down.svg"
        isEllipsis={true}
        enableIndicator
      />
    </div>
  )
}

export default TopSongsFilters