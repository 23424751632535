import { KButton, KSpan, KTitleSpan, KTooltip } from "kahuna-base-react-components"
import React, { useState } from "react"
import { formatIsoDate, hashids } from "../../../utility"
import { Release, Artist } from "../../../types"
import { useNavigate, useParams } from "react-router-dom"
import { lang } from "../../../constants/languages"

const ReleaseComponent = ({
  release,
  overrideBorder,
  fullScreen,
  titleOverride,
  canEdit,
  tableComponent
}: {
  release: Release
  overrideBorder: boolean
  fullScreen: boolean
  titleOverride?: string
  canEdit?: boolean
  tableComponent?: boolean
}) => {
  const [hover, setHover] = useState(false)

  const navigate = useNavigate()
  const params = useParams()

  const getStatusIcon = () => {
    if (release.status === "active") return "/catalog_icons/active-status.svg"
    else if (release.status === "reviewing") return "/catalog_icons/reviewing-status.svg"
    else if (release.status === "archived") return "/catalog_icons/archived-status.svg"
    else if (release.status === "draft") return "/catalog_icons/draft-status.svg"
    else return "/catalog_icons/draft-status.svg"
  }

  const getStatusText = () => {
    const text = lang.catalog.release_status[release.status] || release.status || " - "
    const status = text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

    return `${status}`
  }

  const renderPill = (text: string) => {
    return (
      <div className="flex" style={{ borderRadius: 4, backgroundColor: "#F7F7F7", padding: 4 }} key={Math.random()}>
        <KSpan text={text} fontSize={11} fontWeight={500} lineHeight="12px" color="#111" letterSpacing="0.22px" />
      </div>
    )
  }

  const onClickSmartLink = () => {
    navigate(`/catalog/smart-link/${hashids.encode(release.id?.toString() || "")}`)
  }

  const renderFullScreenFontSize = () => {
    return tableComponent ? 18 : 24
  }

  const renderFullScreenLineHeight = () => {
    return tableComponent ? "18px" : "24px"
  }

  return (
    <div
      style={{ width: "100%" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`rounded-lg p-[16px] ${
        overrideBorder ? "table-row-border-override" : "table-row-hover"
      } cursor-pointer ${tableComponent && "hover:bg-[#F7F7F7] !border-none"} `}
    >
      <div className={`${fullScreen && !tableComponent ? "h-[72px]" : "h-[64px]"} flex justify-between gap-4`}>
        <div className={`flex ${fullScreen && "w-[50%]"} items-center`}>
          <img
            src={release.coverFileUrl}
            className={`${
              fullScreen && !tableComponent ? "w-[72px] h-[72px]" : "w-[64px] h-[64px]"
            } rounded-[6px] cursor-pointer`}
          />
          <div className="flex flex-col justify-center ml-4 gap-1">
            <div className="flex items-center">
              <KTitleSpan
                text={titleOverride || release.title}
                fontSize={fullScreen ? renderFullScreenFontSize() : 20}
                fontWeight={500}
                lineHeight={fullScreen ? renderFullScreenLineHeight() : "20px"}
              />
            </div>
            {!tableComponent && fullScreen && (
              <div className="flex items-center">
                <img src={getStatusIcon()} className="w-4 h-4 mr-1" />

                <KSpan
                  text={`${getStatusText()}: ${formatIsoDate(release.releaseDate)}`}
                  fontSize={14}
                  lineHeight="20px"
                  fontWeight={500}
                  color="#111"
                />
              </div>
            )}
            <span className="flex gap-1 items-center">
              {renderPill(release.productionYear || " - ")}
              {renderPill(
                `${release.totalTrack} ${
                  release.totalTrack > 1 ? lang.common.song_text_multi : lang.common.song_text_single
                }`
              )}
              {renderPill(release.genre || " - ")}
            </span>
          </div>
        </div>
        {fullScreen && (
          <div className="flex justify-start items-center w-[30%]">
            <div className="flex">
              <div className="grid" style={{ justifyItems: "center" }}>
                <div className="flex gap-1 flex-wrap">
                  {release.artistsList.map((artist: Artist) => renderPill(artist.name))}
                </div>
              </div>
            </div>
          </div>
        )}
        {fullScreen && (
          <div
            className="w-[20%] flex items-center"
            style={{
              justifyContent: tableComponent ? "flex-start" : "flex-end"
            }}
          >
            {tableComponent && (
              <KTooltip
                backgroundColor="#000"
                padding="0px 4px"
                height="20px"
                showArrow
                arrowColor="#000"
                content={
                  <div className="h-5 w-max flex items-center">
                    <KSpan text={formatIsoDate(release.releaseDate)} fontSize={12} lineHeight="16px" color="#FFF" />
                  </div>
                }
              >
                <span className="flex flex-row items-center shrink-0">
                  <img src={getStatusIcon()} className="w-4 h-4 mr-1" />
                  <KSpan text={getStatusText()} fontSize={14} lineHeight="20px" fontWeight={500} color="#111" />
                </span>
              </KTooltip>
            )}

            {!tableComponent && (
              <div
                style={{ alignContent: "center" }}
                className="ml-6"
                onClick={(event) => {
                  event.stopPropagation()
                  onClickSmartLink()
                }}
              >
                <KTooltip
                  backgroundColor={"#595959"}
                  width="100px"
                  position="left"
                  children={
                    <KButton
                      onClick={() => {}}
                      icon="/account_icons/link.svg"
                      background="white"
                      padding="0px"
                      width="36px"
                      height="36px"
                    />
                  }
                  content={<KSpan color="white" text={lang.catalog.release_component.smart_link_tooltip} />}
                />
              </div>
            )}
            {canEdit && !tableComponent && (
              <div style={{ alignContent: "center", marginLeft: 8 }}>
                <KButton
                  height="36px"
                  text={lang.catalog.release_component.edit_button}
                  background="transparent"
                  onClick={() => navigate(`/catalog/asset-registry/${params.releaseId}`)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ReleaseComponent
