import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import React, { FC } from "react"
import { ArtistSmartLink } from "../../../../types"
import "../styles/smartlink.css"
import { users } from "../../../../apis"
import { errorFilledToastOptions, successFilledToastOptions } from "../../../../constants"
import { toast } from "react-toastify"
import { lang } from "../../../../constants/languages"

export enum SmartLinkSteps {
  CUSTOMIZE = 1,
  PUBLISH = 2
}

type CustomizePageProps = {
  linkText: string
  setLinkText: (linkText: string) => void
  setSelectedStep: (selectedStep: number) => void
  selectedStep: number
  artistSmartLink?: ArtistSmartLink
  setArtistSmartLink: (artistSmartLink: ArtistSmartLink) => void
}

const CustomizePage: FC<CustomizePageProps> = (props) => {
  const { linkText, setLinkText, selectedStep, setSelectedStep, artistSmartLink, setArtistSmartLink } = props

  const saveSmartLinkUrl = () => {
    users("/save_smart_link/", "post", { link: linkText }).then((resp) => {
      if (resp.data.success) {
        toast.success(lang.artist_space.success.link_save, successFilledToastOptions)
        setArtistSmartLink({ ...artistSmartLink, link: linkText })
        setSelectedStep(SmartLinkSteps.PUBLISH)
      } else {
        toast.error(lang.artist_space.error.error_on_link_save, errorFilledToastOptions)
      }
    })
  }

  return (
    <div className="flex flex-row items-start justify-start gap-2.5 w-4/5 false">
      <div className="flex flex-col items-start rounded-b-[10px] p-6 gap-1" style={{ width: 248 }}>
        <div className="pl-4 pb-6">
          <KSpan text={lang.artist_space.steps} />
        </div>
        <div>
          <KButton
            gap="8px"
            text={lang.artist_space.customize_button}
            leftIcon="/account_icons/sparkle.svg"
            onClick={() => setSelectedStep(SmartLinkSteps.CUSTOMIZE)}
            background={selectedStep === SmartLinkSteps.CUSTOMIZE ? "#F3F3F3" : "transparent"}
            fontWeight={400}
            shadowDisabled={true}
          />
        </div>
        <div>
          <KButton
            gap="8px"
            text={lang.artist_space.publish_button}
            leftIcon="/navigation_icons/earth.svg"
            onClick={() => artistSmartLink && setSelectedStep(SmartLinkSteps.PUBLISH)}
            background={selectedStep === SmartLinkSteps.PUBLISH ? "#F3F3F3" : "transparent"}
            fontWeight={400}
            shadowDisabled={true}
          />
        </div>
      </div>
      <div className="items-center flex w-full min-[1000px]:pl-[100px] min-[1600px]:pl-[150px] min-[2400px]:pl-[200px] ">
        <div className="block min-[1000px]:w-4/5 min-[1600px]:w-3/5 min-[2400px]:!max-w-[900px]" style={{ minWidth: 616, maxWidth: 800 }}>
          <div>
            <KTitleSpan text={lang.artist_space.title} fontSize={32} fontWeight={500} />
          </div>
          <div className="mt-6">
            <KInput
              leftIcon="/account_icons/music-note-sparkle.svg"
              onChange={(text) => setLinkText(text)}
              value={linkText}
              padding="14px"
              placeholder="https://artists.kahuna.io/artist/xxx"
            />
          </div>
          <div className="mt-8">
            <KButton
              background="black"
              textColor="white"
              onClick={() => saveSmartLinkUrl()}
              text={lang.artist_space.save_changes_button}
              disabled={!linkText || artistSmartLink?.link === linkText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomizePage
