import { combineReducers } from "redux"
import authReducer from "./authReducer"
import paymentMethodsReducer from "./paymentMethodsReducer"
import catalogDataReducer from "./catalogDataReducer"
import adminReducer from "./adminReducer"
import analysisReducer from "./analysisReducer"
import reportsReducer from "./reportsReducer"
import publicReducer from "./publicReducer"
import usersReducer from "./usersReducer"
import notificationsReducer from "./notificationsReducer"

export default combineReducers({
  auth: authReducer,
  catalogData: catalogDataReducer,
  paymentMethods: paymentMethodsReducer,
  admin: adminReducer,
  analysis: analysisReducer,
  reportsData: reportsReducer,
  public: publicReducer,
  users: usersReducer,
  notifications: notificationsReducer
})
