import { KButton, KSpan } from "kahuna-base-react-components"
import React, { ChangeEvent, useRef, useState } from "react"
import { errorFilledToastOptions } from "../../../../constants"
import { toast } from "react-toastify"
import { lang } from "../../../../constants/languages"

const UploadImage = ({
  sectionTitle,
  sectionDetail,
  iconPath,
  buttonTitle,
  onClick,
  loading
}: {
  sectionTitle: string
  sectionDetail: string
  iconPath: string
  buttonTitle: string
  onClick: (photo: FormData) => void
  loading: boolean
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0]
      if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg") {
        toast.error(lang.account.profile.error.unsupported_file_format, errorFilledToastOptions)
        return
      }

      const formData = new FormData()

      formData.append("photo", file)
      if (onClick) {
        onClick(formData)
      }
    }
  }

  return (
    <div className="flex flex-row gap-4 items-start">
      <div className="w-[92px] aspect-square">
        <img className="w-[92px] h-[92px]" src={iconPath} style={{ borderRadius: 4 }} />
      </div>
      <div className="flex flex-col ">
        <KSpan
          text={sectionTitle}
          color="#000"
          fontWeight={500}
          lineHeight="24px"
          fontSize={16}
          letterSpacing="-0.176px"
        />
        <KSpan text={sectionDetail} color="#999" />
        <div className="mt-3">
          <input ref={fileInputRef} className="hidden" type="file" onChange={(event) => handleFileChange(event)} />
          <KButton
            text={buttonTitle}
            onClick={handleClick}
            background="#ffffff"
            height="32px"
            width="68px"
            textColor="#000"
            disabled={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadImage
