import React, { FC, useEffect, useState } from "react"
import { AffiliateChartProps, AnalysisChartProps, AnalysisSummary } from "../../../types"
import { calculateChartPercentage } from "../../../utility"
import { fetchSummaries, selectFilter, fetchAllSummaries } from "../../../actions/analysisActions"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, registerables, BarElement } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { KDropdown, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { lang } from "../../../constants/languages"

ChartJS.register(...registerables, ChartDataLabels, BarElement)

const AffiliateChart: FC<AffiliateChartProps> = (props) => {
  const { currency, fetchAllSummaries, allSummaries } = props

  interface myData {
    date: string
    totalNet: number
  }

  const [data, setData] = useState<AnalysisSummary[] | myData[]>([])
  const [summaries, setSummaries] = useState<AnalysisSummary[]>([])

  const [total, setTotal] = useState<number>(0)

  const [filterOptions, setFilterOptions] = useState<KSelectOption[]>([])

  const [selectedFilterOption, setSelectedFilterOption] = useState<KSelectOption>({
    value: 0,
    value2: "all_accounts",
    label: lang.affiliate.all_accounts
  })

  const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

  const [increased, setIncreased] = useState<boolean | null>(null)

  const [activeBarData, setActiveBarData] = useState<{ totalNet: number; date: string }>({ totalNet: 0, date: "" })

  const [maxValue, setMaxValue] = useState<number>(1) //maxValue in allSummaries

  const getFilteredMonths = (dateList: string[]): string[] => {
    // Convert the date strings to arrays of [year, month]
    if (dateList.length === 0) {
      return []
    }
    const dates = dateList.map((date) => {
      const year = parseInt(date.slice(0, 4), 10)
      const month = parseInt(date.slice(4, 6), 10)
      return { year, month }
    })

    // Find the min and max dates
    const minDate = dates.reduce((min, current) => {
      if (current.year < min.year || (current.year === min.year && current.month < min.month)) {
        return current
      }
      return min
    })

    const maxDate = dates.reduce((max, current) => {
      if (current.year > max.year || (current.year === max.year && current.month > max.month)) {
        return current
      }
      return max
    })

    // Generate the list of months from minDate to maxDate
    const months: string[] = []
    let currentYear = minDate.year
    let currentMonth = minDate.month

    while (currentYear < maxDate.year || (currentYear === maxDate.year && currentMonth <= maxDate.month)) {
      // Add the current month in YYYYMM format
      const monthString = `${currentYear}${currentMonth.toString().padStart(2, "0")}`
      months.push(monthString)

      // Move to the next month
      currentMonth++
      if (currentMonth > 12) {
        currentMonth = 1
        currentYear++
      }
    }

    return months
  }

  const convertToMonthYear = (basicDateFormat: string): string => {
    const year = basicDateFormat.slice(0, 4)
    const month = basicDateFormat.slice(4)

    const monthNames = lang.common.months_short

    return `${monthNames[Number(month) - 1]} ${year}`
  }

  useEffect(() => {
    if (hoveredIndex > 0) {
      if (data[hoveredIndex].totalNet > data[hoveredIndex - 1].totalNet) {
        setIncreased(true)
      } else {
        setIncreased(false)
      }
    } else {
      setIncreased(null)
    }

    if (hoveredIndex === -1) {
      setActiveBarData((prev: any) => {
        return { ...prev, totalNet: 0, date: "" }
      })
    } else if (hoveredIndex !== -1) {
      setActiveBarData((prev: any) => {
        return {
          ...prev,
          totalNet: Number(data[hoveredIndex]?.totalNet || 0).toFixed(2),
          date: data[hoveredIndex].date
        }
      })
    }
  }, [hoveredIndex])

  useEffect(() => {
    setHoveredIndex(-1)
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    
    fetchAllSummaries("platform", "all_platforms", true, "202201", `${currentYear}12`)
  }, [])

  useEffect(() => {
    if (summaries) {
      const users: string[] = [lang.affiliate.all_accounts]
      const maxTotalNet = Math.max(...summaries.map((item) => Number(item.totalNet)))
      if (Number(maxTotalNet.toFixed(2)) > 0) {
        setMaxValue(maxTotalNet)
      } else if (Number(maxTotalNet.toFixed(2)) === 0) {
        setMaxValue(1)
      }

      const dateList = summaries.map((summary) => summary.date)
      const newDatas: { date: string; totalNet: number }[] = []
      const filteredMonths: string[] = getFilteredMonths(dateList)
      filteredMonths.forEach((month, index) => {
        const summaryTotal = summaries
          .filter((summary) => summary.date === month)
          .reduce((accumulator, currentValue) => accumulator + Number(currentValue.totalNet), 0)

        newDatas.push({ date: month, totalNet: summaryTotal || 0 })
      })

      setData(newDatas)

      //const [total, setTotal] = useState(null) total state'in onceki hali bu sekildeydi. Ben yukaridaki gibi degistirdim
      let total = 0
      for (let i = 0; i < summaries.length; i++) {
        total += Number(summaries[i].totalNet)
        if (!users.includes(summaries[i].userName)) {
          users.push(summaries[i].userName)
        }
      }
      setTotal(parseFloat(total.toFixed(2)))

      if (filterOptions.length <= 2) {
        setFilterOptions(
          users.map((user, index) => {
            return {
              value: index,
              value2: user,
              label: user
            }
          })
        )
      }
    }
  }, [summaries])

  useEffect(() => {
    //TODO: use value or value2, not label
    if (selectedFilterOption.label === lang.affiliate.all_accounts) {
      setSummaries(allSummaries)
    } else {
      setSummaries([...allSummaries.filter((summary) => summary.userName === selectedFilterOption.label)])
    }
  }, [allSummaries, selectedFilterOption])

  const getCurrency = () => {
    if (currency === "EUR") return "€"
    else if (currency === "TRY") return "₺"
    else if (currency === "USD") return "$"
    else if (currency === "GBP") return "£"
    return ""
  }

  return (
    <div className="flex flex-col mt-[9px] col-span-3 px-6">
      <div className="flex justify-between items-center">
        <div className="w-[450px] flex items-center justify-between">
          <KSpan
            text={hoveredIndex === -1 ? lang.affiliate.summary_total : `${lang.affiliate.summary} - ${convertToMonthYear(activeBarData.date)}`}
            fontSize={16}
            color="#111"
            fontWeight={500}
            lineHeight="24px"
            letterSpacing="-0.176px"
          />
        </div>
        <div className="flex flex-row justify-end gap-3">
          <KDropdown
            width={250}
            options={filterOptions}
            defaultValue={selectedFilterOption}
            rightIcon="/chevron-down.svg"
            onSelect={(selected: KSelectOption | any) => {
              setSelectedFilterOption(selected)
            }}
            shadowDisabled={true}
          />
        </div>
      </div>
      <div className="h-auto flex flex-row justify-start items-center mb-5 gap-2">
        {currency && (
          <KTitleSpan text={getCurrency()} fontSize={40} lineHeight="48px" letterSpacing="-0.4px" color="#737373" />
        )}
        <KTitleSpan
          text={hoveredIndex === -1 ? `${total}` : `${activeBarData.totalNet}`}
          fontSize={40}
          lineHeight="48px"
          letterSpacing="-0.4px"
        />
        {increased !== null && (
          <span className="w-5 aspect-square">
            <img
              className="w-full h-full"
              src={increased ? "/analytics_icons/arrow-up-right.svg" : "/analytics_icons/arrow-down-left.svg"}
            />
          </span>
        )}
      </div>

      <div
        className="m-auto h-[80%]  w-full"
        onMouseLeave={() => {
          setHoveredIndex(-1)
        }}
        style={{ minHeight: 250 }}
      >
        {data.length > 0 && (
          <Bar
            data={{
              labels: data.map((summary: AnalysisSummary | myData) => convertToMonthYear(summary.date)),
              datasets: [
                {
                  label: "Total Net",
                  data: data.map((summary: AnalysisSummary | myData) =>
                    Number(Number(summary.totalNet).toFixed(2)) > 0
                      ? Number(Number(summary.totalNet).toFixed(2))
                      : maxValue.toFixed(2)
                  ),
                  backgroundColor: data.map((summary: AnalysisSummary | myData, index: number) =>
                    Number(Number(summary.totalNet).toFixed(2)) > 0
                      ? hoveredIndex === index
                        ? "black"
                        : "#E7E7E7"
                      : hoveredIndex === index
                      ? "#F0F0F0"
                      : "transparent"
                  ),
                  barThickness: "flex",
                  datalabels: {
                    display: false,
                    anchor: (context) => {
                      return calculateChartPercentage(context) > 5 ? "center" : "end"
                    },
                    align: "end",
                    offset: (context) => {
                      const pc = calculateChartPercentage(context)
                      return pc > 5 ? 0 : pc > 4 ? -70 : pc > 3 ? -45 : -30
                    }
                  }
                }
              ]
            }}
            options={{
              onHover: (event, elements, chart) => {
                if (elements.length > 0) {
                  const clicked = elements[0].index
                  setHoveredIndex(clicked)
                } else {
                  setHoveredIndex(-1)
                }
              },
              scales: {
                x: {
                  display: true, // Enable x-axis display
                  grid: {
                    display: false
                  },
                  ticks: {
                    display: true // Show x-axis ticks (e.g., months)
                  }
                },
                y: {
                  display: false,
                  grid: {
                    display: false
                  },
                  ticks: {
                    display: false
                  }
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                datalabels: {
                  display: false,
                  formatter: (value, ctx) => {
                    //@ts-ignore
                    const sum = ctx.chart.data.datasets[0].data.reduce((a: number, b: number) => a + b, 0) as number
                    return `${((value * 100) / sum).toFixed(1)}%`
                  }
                },
                tooltip: {
                  enabled: false,
                  callbacks: {
                    label: (context) => {
                      let label = context.dataset.label || ""
                      const sign = currency === "EUR" ? "€" : currency === "TRY" ? "₺" : "$"
                      if (label) {
                        label += ": "
                      }
                      if (context.parsed.y !== null) {
                        label += `${context.parsed.y.toFixed(2)} ${sign}`
                      }
                      return label
                    }
                  }
                }
              },
              maintainAspectRatio: false,
              responsive: true,
              elements: {
                bar: {
                  borderRadius: {
                    topRight: 8,
                    topLeft: 8,
                    bottomRight: 8,
                    bottomLeft: 8
                  },
                  backgroundColor: "#ff0"
                }
              }
            }}
          />
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    totals: state.analysis.totals,
    currency: state.auth.user.preferredCurrency,
    user: state.auth.user,
    selectedDate: state.analysis.selectedDate,
    selectedFilter: state.analysis.selectedFilter,
    summaries: state.analysis.summaries,
    allSummaries: state.analysis.allSummaries
  }
}

export default connect(mapStateToProps, { fetchSummaries, selectFilter, fetchAllSummaries })(AffiliateChart)
