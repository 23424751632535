import React, { useEffect, useState } from "react"

import Title from "../Title"
import { RootState } from "../../../../store"
import { connect } from "react-redux"
import { Contract } from "../../../../types"
import { users } from "../../../../apis"
import Loading from "../../../ui/Loading"

import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { KButton, KSpan } from "kahuna-base-react-components"
import PdfViewerModal from "./PdfViewerModal"
//@ts-ignore
import CompletedIcon from "../../../../assets/release-registry-icons/completed.svg"
//@ts-ignore
import IncompletedIcon from "../../../../assets/release-registry-icons/incomplete.svg"
import { toast } from "react-toastify"
import { errorFilledToastOptions, successFilledToastOptions } from "../../../../constants"
import ConfirmationDialog from "../../../ui/modals/ConfirmationDialog"

const Contracts = ({ user }) => {
  const [contracts, setContracts] = useState<Contract[]>([])
  const [pdfIdToDelete, setPdfIdToDelete] = useState<number>()
  const [selectedContract, setSelectedContract] = useState<Contract>()
  const [loading, setLoading] = useState(false)

  const fetchContracts = () => {
    users(`/contracts/`, "get").then((resp) => {
      if (resp && resp.data) {
        setContracts(resp.data)
      }
    })
  }

  useEffect(() => {
    fetchContracts()
  }, [])

  const handleConfirmDelete = () => {
    setLoading(true)
    users(`/delete_contract/?id=${pdfIdToDelete}`, "delete")
      .then((resp) => {
        if (resp && resp.data) {
          fetchContracts()
          setPdfIdToDelete(undefined)
          toast.success("Successfully deleted contract", successFilledToastOptions)
        } else {
          toast.error("Error deleting contract", errorFilledToastOptions)
        }
        setLoading(false)
      })
      .catch(() => {
        toast.error("Error deleting contract", errorFilledToastOptions)
        setLoading(false)
      })
  }

  const submitContract = (type, formData) => {
    setLoading(true)
    users(`/upload_contract/?type=${type}`, "post", formData)
      .then((resp) => {
        if (resp.data) {
          toast.success("Contract Signed Successfully", successFilledToastOptions)
          setSelectedContract(undefined)
          fetchContracts()
        } else {
          toast.error("Error. Couldn't sign contract", errorFilledToastOptions)
        }
        setLoading(false)
      })
      .catch(() => {
        toast.error("Error. Couldn't sign contract", errorFilledToastOptions)
        setLoading(false)
      })
  }

  return (
    <div className="flex flex-col gap-6 pb-6">
      {loading && <Loading />}
      <div>
        <Title sectionTitle="Contracts" sectionDetail="Sign Contract" iconPath="/account_icons/link.svg" />
      </div>
      <div>
        {contracts.map((contract) => {
          return (
            <div
              key={contract.id || "-"}
              className="w-[100%] flex flex-row justify-between items-center p-3 gap-2.5 table-row-hover rounded-[10px] "
            >
              <KSpan text={contract.type.replace("_", " ").toLocaleUpperCase()} />
              <div className="flex gap-2">
                <KSpan text={contract.signed ? "Signed" : "Not Signed"} />
                <img src={contract.signed ? CompletedIcon : IncompletedIcon} />
              </div>

              {contract.id ? (
                <div className="flex gap-2">
                  <KButton
                    leftIcon="/account_icons/eye.svg"
                    background="#FFF"
                    padding="6px"
                    width="32px"
                    height="32px"
                    borderRadius={10}
                    onClick={() => setSelectedContract(contract)}
                  />
                  <KButton
                    leftIcon="/account_icons/delete-bin-red.svg"
                    background="#FFF"
                    padding="6px"
                    width="32px"
                    height="32px"
                    borderRadius={10}
                    onClick={() => setPdfIdToDelete(contract.id)}
                  />
                </div>
              ) : (
                <KButton
                  width="auto"
                  background="transparent"
                  text="View / Sign"
                  onClick={() => setSelectedContract(contract)}
                />
              )}
            </div>
          )
        })}
      </div>
      <ConfirmationDialog
        openConfirmation={pdfIdToDelete !== undefined}
        setOpenConfirmation={() => setPdfIdToDelete(undefined)}
        handleConfirm={handleConfirmDelete}
        loading={loading}
      />
      {selectedContract && (
        <PdfViewerModal
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
          user={user}
          submit={submitContract}
          loading={loading}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return { user: state.auth.user }
}

export default connect(mapStateToProps)(Contracts)
