import { Dialog } from "@headlessui/react"
import React, { FC, useEffect, useState } from "react"
import Payment from "../../account/payment/Payment"
import { X } from "heroicons-react"
import { UserAdvance, UserCredit } from "../../../../types"
import {
  errorFilledToastOptions,
  successFilledToastOptions,
  USER_CREDIT_TYPES,
  userCreditTypeOptions
} from "../../../../constants"
import { users } from "../../../../apis"
import { mapListToCamelCase, mapToCamelCase, mapToSnakeCase } from "../../../../utility"
import { KButton, KDropdown, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { toast } from "react-toastify"
//@ts-ignore
import DeleteIcon from "../../../../assets/release-registry-icons/delete.svg"

type AdminUserAdvanceModalProps = {
  open: boolean
  onClose: () => void
  userId: number | undefined
}

const currencyOptions = [
  { label: "GBP", value: 4, value2: "GBP" },
  { label: "TRY", value: 1, value2: "TRY" },
  { label: "EUR", value: 3, value2: "EUR" },
  { label: "USD", value: 2, value2: "USD" }
]

const AdminUserAdvanceModal: FC<AdminUserAdvanceModalProps> = (props) => {
  const { onClose, open } = props
  const [loading, setLoading] = useState(false)

  const [userAdvanceList, setUserAdvanceList] = useState<UserAdvance[]>([])
  const [userAdvanceToCreate, setUserAdvanceToCreate] = useState<UserAdvance>()
  const [addOpen, setAddOpen] = useState(false)

  const fetchUserAdvanceList = () => {
    users(`/fetch_user_advance/?user_id=${props.userId}`, "GET").then((resp) => {
      setUserAdvanceList(mapListToCamelCase(resp.data.results))
    })
  }

  useEffect(() => {
    if (!props.userId) {
      setUserAdvanceList([])
      return
    }

    fetchUserAdvanceList()
  }, [props.userId])

  const onCloseModal = () => {
    setUserAdvanceList([])
    props.onClose()
  }

  const saveUserAdvance = () => {
    setLoading(true)
    users(`/user_advance/`, "POST", { ...mapToSnakeCase(userAdvanceToCreate), user: props.userId })
      .then((resp) => {
        if (resp.data.success) {
          toast.success("User Advance Created Successfully.", successFilledToastOptions)
          fetchUserAdvanceList()
        } else {
          toast.error("Error saving User Advance!.", errorFilledToastOptions)
        }
        setLoading(false)
      })
      .catch(() => {
        toast.error("Error saving User Advance!.", errorFilledToastOptions)
        setLoading(false)
      })
  }

  const deleteUserAdvance = (userAdvanceId: number) => {
    if (userAdvanceId === 0) {
      setAddOpen(false)
      return
    }

    if (!window.confirm("Are you sure?")) {
      return
    }

    setLoading(true)
    users(`/remove_user_advance/?id=${userAdvanceId}`, "DELETE")
      .then((resp) => {
        if (resp.data.success) {
          toast.success("User Advance Removed Successfully.", successFilledToastOptions)
        } else {
          toast.error("Error removing User Advance!.", errorFilledToastOptions)
        }
        fetchUserAdvanceList()
        setLoading(false)
      })
      .catch(() => {
        toast.error("Error removing User Advance!.", errorFilledToastOptions)
        setLoading(false)
      })
  }

  return (
    <Dialog open={open} onClose={() => onCloseModal()} className="relative">
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.3)]" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="bg-white elev-b-sm rounded-lg overflow-hidden" style={{ overflowY: "auto", padding: 42 }}>
          <div className={`flex justify-end`}>
            <X className="h-4 cursor-pointer mt-1" style={{ position: "absolute" }} onClick={() => onCloseModal()} />
          </div>
          <div className="flex mb-4">
            <div style={{ width: 140 }}>
              <KTitleSpan text={"Start Date"} fontSize={16} lineHeight="auto" />
            </div>
            <div style={{ width: 140 }}>
              <KTitleSpan text={"End Date"} fontSize={16} lineHeight="auto" />
            </div>
            <div style={{ width: 140 }}>
              <KTitleSpan text={"Advance Total"} fontSize={16} lineHeight="auto" />
            </div>
            <div style={{ width: 140 }}>
              <KTitleSpan text={"Paid Total"} fontSize={16} lineHeight="auto" />
            </div>
            <div style={{ width: 100 }}>
              <KTitleSpan text={"Currency"} fontSize={16} lineHeight="auto" />
            </div>
            <div style={{ width: 140 }}>
              <KTitleSpan text={"Created At"} fontSize={16} lineHeight="auto" />
            </div>
            <div style={{ width: 100 }}>
              <KTitleSpan text={"Action"} fontSize={16} lineHeight="auto" />
            </div>
          </div>
          {userAdvanceList.map((userAdvance: UserAdvance) => {
            return (
              <div className="flex mt-2 pb-2" style={{ width: "100%", borderBottom: "1px dashed grey" }}>
                <div style={{ width: 140 }}>
                  <KSpan text={userAdvance.startDate?.substring(0, 10) || "-"} />
                </div>
                <div style={{ width: 140 }}>
                  <KSpan text={userAdvance.endDate?.substring(0, 10) || "-"} />
                </div>
                <div style={{ width: 140 }}>
                  <KSpan text={userAdvance.advanceTotal?.toFixed(1) || "-"} />
                </div>
                <div style={{ width: 140 }}>
                  <KSpan text={userAdvance.paidTotal?.toFixed(1) || "-"} />
                </div>
                <div style={{ width: 100 }}>
                  <KSpan
                    text={currencyOptions.find((option) => option.value === userAdvance?.currencyId)?.label || "-"}
                  />
                </div>
                <div style={{ width: 140 }}>
                  <KSpan text={userAdvance.created?.substring(0, 10) || "-"} />
                </div>
                <div style={{ width: 100 }}>
                  <img
                    src={DeleteIcon}
                    className="cursor-pointer"
                    onClick={() => deleteUserAdvance(userAdvance.id || 0)}
                  />
                </div>
              </div>
            )
          })}
          {addOpen && (
            <div className="flex mt-8" style={{ width: "100%" }}>
              <div className="pr-2" style={{ width: 140 }}>
                <KInput
                  placeholder="20240124"
                  value={userAdvanceToCreate?.startDate || ""}
                  onChange={(value) => setUserAdvanceToCreate({ ...userAdvanceToCreate, startDate: value })}
                />
              </div>
              <div className="pr-2" style={{ width: 140 }}>
                <KInput
                  placeholder="20240124"
                  value={userAdvanceToCreate?.endDate || ""}
                  onChange={(value) => setUserAdvanceToCreate({ ...userAdvanceToCreate, endDate: value })}
                />
              </div>
              <div className="pr-2" style={{ width: 140 }}>
                <KInput
                  placeholder="2000"
                  value={userAdvanceToCreate?.advanceTotal?.toString() || ""}
                  onChange={(value) => {
                    if (value) {
                      setUserAdvanceToCreate({ ...userAdvanceToCreate, advanceTotal: parseInt(value) })
                    } else {
                      setUserAdvanceToCreate({ ...userAdvanceToCreate, advanceTotal: 0 })
                    }
                  }}
                />
              </div>
              <div style={{ width: 140 }}></div>
              <div className="pr-2" style={{ width: 100 }}>
                <div className="absolute">
                  <KDropdown
                    placeholder="TRY"
                    width={60}
                    menuWidth={80}
                    options={currencyOptions}
                    selected={currencyOptions.find((option) => option.value === userAdvanceToCreate?.currency)}
                    onSelect={(selected: any) =>
                      setUserAdvanceToCreate({ ...userAdvanceToCreate, currency: selected.value })
                    }
                  />
                </div>
              </div>
              <div style={{ width: 140 }}></div>
              <div style={{ width: 100 }} className="items-center flex">
                <img src={DeleteIcon} className="cursor-pointer" onClick={() => deleteUserAdvance(0)} />
              </div>
            </div>
          )}
          <div className="mt-8">
            <KButton
              background={addOpen ? "black" : "transparent"}
              textColor={addOpen ? "white" : "black"}
              shadowDisabled={true}
              width="auto"
              text={addOpen ? "Save Advance" : "+ Add New"}
              onClick={() => (addOpen ? saveUserAdvance() : setAddOpen(true))}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AdminUserAdvanceModal
