import {
  AuthUser,
  ForgotPasswordFormValues,
  InformationPageForm,
  LoginFormValues,
  ResetPasswordFormValues
} from "../types"
import { Action, Dispatch } from "redux"
import { mapToCamelCase, mapToSnakeCase } from "../utility"
import { toast } from "react-toastify"
import { errorFilledToastOptions, infoFilledToastOptions, successFilledToastOptions } from "../constants"
import { codeValidation as codeValidationApi, authLogin } from "../apis"
import { lang } from "../constants/languages"

export const AUTH_ACTION = {
  LOGIN: "LOGIN",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT: "LOGOUT",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  UPDATE_USER: "UPDATE_USER",
  UPLOAD_PROFILE_PHOTO: "UPLOAD_PROFILE_PHOTO",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  GET_ROLE: "GET_ROLE",
  SEND_VALIDATION: "SEND_VALIDATION",
  EMAIL_VALIDATE: "EMAIL_VALIDATE"
}
export const login = (formValues: LoginFormValues, callback: () => void) => async (dispatch: Dispatch<Action>) => {
  try {
    localStorage.removeItem("appliedAffiliate")
    const response = await authLogin.post("/", formValues)
    const data = mapToCamelCase<AuthUser>(response.data)

    dispatch({
      type: AUTH_ACTION.LOGIN,
      payload: data
    })
    callback()
  } catch (e) {
    console.log(e)
    toast.error(lang.login.error_on_login, errorFilledToastOptions)
    dispatch({
      type: AUTH_ACTION.LOGIN_ERROR,
      payload: e
    })
  }
}
export const logout = (callback: () => void) => async (dispatch: Dispatch<Action>) => {
  localStorage.removeItem("appliedAffiliate")
  dispatch({
    type: AUTH_ACTION.LOGOUT
  })
  callback()
}
export const forgotPassword = (formValues: ForgotPasswordFormValues, callback: Function) => async () => {
  try {
    toast.loading(lang.forgot_password.loading_text, { toastId: AUTH_ACTION.FORGOT_PASSWORD, ...infoFilledToastOptions })
    const response = await codeValidationApi.post("/send_validation/", mapToSnakeCase(formValues))
    toast.dismiss(AUTH_ACTION.FORGOT_PASSWORD)
    if (response.status === 200) {
      toast.success(lang.forgot_password.success_on_code_sent, successFilledToastOptions)
      callback()
    } else {
      toast.error(lang.forgot_password.error_on_code_sent, errorFilledToastOptions)
    }
  } catch {
    toast.error(lang.forgot_password.error_on_code_sent, errorFilledToastOptions)
  }
}
export const resetPassword = (formValues: ResetPasswordFormValues, callback?: Function) => async () => {
  try {
    toast.loading(lang.reset_password.loading_text, { toastId: AUTH_ACTION.RESET_PASSWORD, ...infoFilledToastOptions })
    const response = await codeValidationApi.put("/change_password/", formValues)
    toast.dismiss(AUTH_ACTION.RESET_PASSWORD)
    if (response.status === 200) {
      toast.success(lang.reset_password.success_on_code_reset, successFilledToastOptions)
      callback()
    } else {
      toast.error(lang.reset_password.error_on_code_reset, errorFilledToastOptions)
    }
  } catch {
    toast.error(lang.reset_password.error_on_code_reset, errorFilledToastOptions)
  }
}

export const emailValidation =
  (formValues: LoginFormValues, userCheck: boolean, useCredentials: boolean, subject: string, callback?: Function) =>
  async () => {
    try {
      toast.loading(lang.start_your_journey.information_page.validation_code_sending_loading_text, {
        toastId: AUTH_ACTION.SEND_VALIDATION, ...infoFilledToastOptions
      })

      const userCheckParam = `&user_check=${userCheck ? "1" : "0"}`
      const useCredentialsParam = `&use_credentials=${useCredentials ? "1" : "0"}`
      const response = await codeValidationApi.post(
        `/send_validation/?subject=${subject}${userCheckParam}${useCredentialsParam}`,
        mapToSnakeCase(formValues)
      )
      toast.dismiss(AUTH_ACTION.SEND_VALIDATION)
      if (response.status === 200) {
        if (response.data.override_code_validation && callback) {
          callback(response.data.override_code_validation)
          return
        }
        toast.success(lang.start_your_journey.information_page.validation_code_sending_success, successFilledToastOptions)
        if (callback) {
          callback()
        }
      } else {
        toast.error(lang.start_your_journey.information_page.validation_code_sending_error, errorFilledToastOptions)
      }
    } catch {
      toast.error(lang.start_your_journey.information_page.validation_code_sending_error, errorFilledToastOptions)
    }
  }

export const validateCode = (formValues: LoginFormValues, callback?: Function) => async () => {
  try {
    toast.loading(lang.start_your_journey.information_page.email_validation_loading_text, {
      toastId: AUTH_ACTION.EMAIL_VALIDATE,
     ...infoFilledToastOptions
    })
    const response = await codeValidationApi.post("/validate_email_address/", formValues)
    toast.dismiss(AUTH_ACTION.EMAIL_VALIDATE)
    if (response.status === 200) {
      toast.success(lang.start_your_journey.information_page.email_validation_success, successFilledToastOptions)
      if (callback) {
        callback()
      }
    } else {
      toast.error(lang.start_your_journey.information_page.email_validation_error, errorFilledToastOptions)
    }
  } catch {
    toast.error(lang.start_your_journey.information_page.email_validation_error, errorFilledToastOptions)
  }
}
