import React, { useEffect, useState } from "react"
// @ts-ignore
import placeholderPerson from "../../../../assets/start_your_journey_icons/person.png"
// @ts-ignore
import SearchIcon from "../../../../assets/start_your_journey_icons/search-2-line.svg"
//@ts-ignore
import MusicAlbumIcon from "../../../../assets/start_your_journey_icons/music-album.svg"
//@ts-ignore
import PlusIcon from "../../../../assets/start_your_journey_icons/plus.svg"
import "../offers.css"
import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
import { spotify } from "../../../../apis"
import { StartYourJourneyPages, Details } from "../StartYourJourney"
import { parseFormattedNumber } from "../../../../utility"
import { lang } from "../../../../constants/languages"
import { SelectOption } from "../../../../types"

export type MonthlyListener = {
  total: number
  artistInformations: { id: string; monthlyListener: number }[]
}

const ArtistSelectionPage = (props: {
  selectedPage: number
  setSelectedPage: (page: number) => void
  details: Details
  setDetails: (details: Details) => void
  completedSteps: { first: boolean; second: boolean; third: boolean; fourth: boolean }
  setCompletedSteps: (steps: { first: boolean; second: boolean; third: boolean; fourth: boolean }) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [searchText, setSearchText] = useState("")
  const [selectedArtists, setSelectedArtists] = useState<SelectOption[]>(props.details.artist)

  const [viewAll, setViewAll] = useState<boolean>(false)

  useEffect(() => {
    setSelectedArtists(props.details.artist)
  }, [])

  const getSpotifyToken = async (text: string, searchRetry: boolean) => {
    await spotify(`/spotify/`, { withCredentials: true })
  }

  const onSearch = async (text: string, retry: boolean) => {
    if (!text) {
      setLoading(false)
      return
    }

    setLoading(true)
    try {
      const resp = await spotify(`/search_artist_spotify/?limit=5&search=${text}`, { withCredentials: true })
      setLoading(false)
      if (resp.data.results) {
        setOptions(resp.data.results)
      }
    } catch (err) {
      //getSpotifyToken(text, retry)
    }
  }

  useEffect(() => {
    getSpotifyToken("", false)
  }, [])

  const handleInputChange = (text: string) => {
    setLoading(true)
    setSearchText(text)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const newTimeoutId = setTimeout(() => {
      // Call onSearch after 1 second
      onSearch(text, true)
    }, 1000)

    setTimeoutId(newTimeoutId)
  }

  const checkMonthlyListener = async (artistList: SelectOption[]) => {
    setLoading(true)
    let totalMonthlyListener = 0
    for (const artist of artistList) {
      try {
        if (totalMonthlyListener < 25000) {
          const resp = await spotify(`/spotify_artist_monthly_listener/?artist_id=${artist.value}`)
          totalMonthlyListener = parseFormattedNumber(resp.data.result) + totalMonthlyListener
          setLoading(false)
        } else {
          setLoading(false)
          break
        }
      } catch (err) {
        setLoading(false)
      }
    }

    if (totalMonthlyListener >= 25000) {
      props.setDetails({ ...props.details, artist: selectedArtists, exceedsListenerLimit: true })
    } else if (totalMonthlyListener < 25000) {
      props.setDetails({ ...props.details, artist: selectedArtists, exceedsListenerLimit: false })
    }
    props.setSelectedPage(StartYourJourneyPages.HOW_HEAR_US)
    props.setCompletedSteps({ ...props.completedSteps, second: true })
  }

  const onClickArtist = (artistId: string, artistName: string, cover: string) => {
    //value, label, cover
    if (props.details.role?.value2 === "manager" || props.details.role?.value2 === "label") {
      const artists = [...selectedArtists]
      const exists = artists.some((artist) => artist.value === artistId && artist.label === artistName)
      if (!exists) {
        artists.push({ value: artistId, label: artistName, cover })
        setSelectedArtists(artists)
      }
    } else {
      setSelectedArtists([{ value: artistId, label: artistName, cover }])
    }
    setSearchText("")
  }

  const renderSelectedArtists = () => {
    return (
      <div className="render-search-results-div w-full">
        <div className="mt-6 w-full flex flex-col" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)" }}>
          <div className="flex flex-col gap-1 w-full">
            {selectedArtists.length > 0 && (
              <div className="pb-2">
                {" "}
                <KSpan text={lang.start_your_journey.artist_page.selected_artists} color="#666" />{" "}
              </div>
            )}
            <div className="flex flex-col gap-1">
              {selectedArtists.length > 0 &&
                selectedArtists.slice(0, viewAll ? selectedArtists.length : 3).map((option) => {
                  return (
                    <div
                      className="flex mb-3 justify-between pr-2"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#F5F5F5",
                        borderRadius: 10,
                        width: "100%",
                        border: "1px solid #111"
                      }}
                      key={`${option.value}`}
                      onClick={() => {
                        const artists = [...selectedArtists].filter((artist) => artist.value !== option.value)
                        setSelectedArtists(artists)
                      }}
                    >
                      <div className="flex">
                        <img
                          className="my-2.5 ml-3.5 mr-4"
                          style={{ width: 36, height: 36, borderRadius: 50 }}
                          src={option.cover || placeholderPerson}
                        />
                        <div className={"grid items-center"}>
                          <KSpan
                            text={option.label?.toString() || ""}
                            color="#000"
                            fontSize={16}
                            fontWeight={500}
                            lineHeight="24px"
                            letterSpacing="-0.176px"
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              {selectedArtists.length > 3 &&
                (!viewAll ? (
                  <KButton
                    width="150px"
                    text={`${lang.start_your_journey.artist_page.more} (${selectedArtists.length - 3})`}
                    onClick={() => {
                      setViewAll(true)
                    }}
                    textDecoration="underline"
                    background="#FFF"
                    shadowDisabled={true}
                    leftIcon={PlusIcon}
                  />
                ) : (
                  <KButton
                    width="150px"
                    text={`${lang.start_your_journey.artist_page.less}`}
                    onClick={() => {
                      setViewAll(false)
                    }}
                    textDecoration="underline"
                    background="#FFF"
                    shadowDisabled={true}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderSearchResults = () => {
    return (
      <div className="render-search-results-div">
        <div className="mt-6" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)" }}>
          <div className="block">
            <div className="mb-2">
              <KSpan text={lang.start_your_journey.artist_page.search_results} color="#666" />
            </div>
            {options.length > 0 &&
              options.map((option) => {
                return (
                  <div
                    className="flex mb-3"
                    style={{ cursor: "pointer", backgroundColor: "#F5F5F5", borderRadius: 10 }}
                    key={`${option.value}${option.type}`}
                    onClick={() =>
                      onClickArtist(option.value.toString(), option?.label?.toString() || "", option.cover || "")
                    }
                  >
                    <img
                      className="my-2.5 ml-3.5 mr-4"
                      style={{ width: 36, height: 36, borderRadius: 50 }}
                      src={option.cover || placeholderPerson}
                    />
                    <div className={"grid items-center"}>
                      <KSpan
                        text={option.label?.toString() || ""}
                        color="#000"
                        fontSize={16}
                        fontWeight={500}
                        lineHeight="24px"
                        letterSpacing="-0.176px"
                      />
                    </div>
                  </div>
                )
              })}
            {options.length === 0 && (
              <React.Fragment>
                <div className="flex flex-row justify-between items-center gap-2.5 mt-6">
                  <hr style={{ flexGrow: 1 }} />
                  <KSpan
                    text={lang.common.or.toUpperCase()}
                    fontWeight={500}
                    fontSize={11}
                    lineHeight="12px"
                    letterSpacing="0.22px"
                    color="#111"
                  />
                  <hr style={{ flexGrow: 1 }} />
                </div>
                <div
                  style={{
                    height: 196,
                    backgroundColor: "#F5F5F5",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 24,
                    padding: 24,
                    alignContent: "center",
                    borderRadius: "10px",
                    marginTop: "24px"
                  }}
                >
                  <img src={MusicAlbumIcon} />
                  <div className="flex flex-col gap-3 justify-center items-center w-full">
                    <KSpan text={lang.start_your_journey.artist_page.no_results} />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderSearchBox = () => {
    const filteredArtistsLength = options.filter((option) => option.type === "artists").length
    return (
      <div className="">
        <div className="w-full">
          <KInput
            value={searchText}
            onChange={(value) => handleInputChange(value)}
            placeholder={lang.start_your_journey.artist_page.artist_name_placeholder}
            leftIcon={SearchIcon}
            padding="14px"
            gap="8px"
          />
        </div>
        {renderSelectedArtists()}
        {searchText && (
          <div className="relative flex justify-center">
            <div className="w-full">
              {loading ? (
                <div
                  style={{
                    height: 196,
                    backgroundColor: "#F7F7F7",
                    textAlign: "center",
                    justifyItems: "center",
                    gap: 24,
                    padding: 24,
                    alignContent: "center",
                    borderRadius: "10px",
                    marginTop: 24
                  }}
                  className={"grid"}
                >
                  <img src={MusicAlbumIcon} alt="music-album" />
                  <div className="flex flex-row">
                    <KSpan text={lang.start_your_journey.artist_page.searching_in_database} />
                    <span className="dots"></span>
                  </div>
                </div>
              ) : (
                renderSearchResults()
              )}
            </div>
          </div>
        )}
        <div className="mt-5">
          {selectedArtists.length > 0 && (
            <KButton
              text={lang.start_your_journey.artist_page.save_go_to_next_step}
              background="#000"
              textColor="#FFF"
              disabled={loading}
              onClick={() => checkMonthlyListener(selectedArtists)}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="flex flex-row items-start gap-2.5" style={{ height: "100%", width: "100%", marginRight: "auto" }}>
        <div className={"w-full h-full flex flex-row items-start justify-start px-4 py-4 gap-6"} style={{}}>
          <div className={"grow flex flex-col h-full"}>
            <div className={"pt-2"}>
              <KTitleSpan
                text={lang.start_your_journey.artist_page.spotify_artist_name}
                lineHeight={"40px"}
                fontSize={32}
                fontWeight={500}
                letterSpacing="-0.4px"
                color="#000"
              />
            </div>
            <div className={"mt-3"}>
              <KSpan text={lang.start_your_journey.artist_page.desc} />
            </div>
            <div className={"mt-6 w-full grow overflow-y-auto"}>{renderSearchBox()}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ArtistSelectionPage
