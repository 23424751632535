import { KButton, KSpan } from "kahuna-base-react-components"
import React, { useState } from "react"
import { PaymentMethod } from "../../../../types"
import { createPaymentMethod, deletePaymentMethod, updatePaymentMethod } from "../../../../actions"
import { connect } from "react-redux"
import { lang } from "../../../../constants/languages"
import ConfirmationDialog from "../../../ui/modals/ConfirmationDialog"

const bankCodes = {
  "010": "ziraat.svg",
  "012": "halkbank.png",
  "013": "denizbank.svg",
  "015": "vakifbank.png",
  "046": "akbank.png",
  "062": "garanti.png",
  "064": "isbank.svg",
  "067": "yapikredi.png",
  "099": "ing.png",
  111: "qnb.png",
  123: "hsbc.png"
}

const ListComponent = ({
  paymentMethod,
  setEdit,
  paymentId,
  setEditableMethodKey,
  deletePaymentMethod
}: {
  paymentMethod: PaymentMethod
  setEdit: React.Dispatch<React.SetStateAction<boolean>>
  paymentId: string
  setEditableMethodKey: React.Dispatch<React.SetStateAction<string>>
  deletePaymentMethod: (id: number) => void
}) => {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

  const handleConfirm = () => {
    if (paymentMethod.id !== undefined) {
      deletePaymentMethod(paymentMethod.id)
    }
  }

  const getIconName = (iban: string) => {
    const cleaned = iban.replace(/ /g, "")
    const bankName = Object.keys(bankCodes).find((key) => {
      return cleaned.substring(6, 9) === key.toString()
    })
    return bankName ? bankCodes[bankName] : "bank.svg"
  }

  return (
    <div className="w-[100%] flex flex-row justify-between items-center p-3 gap-2.5 table-row-payment-hover rounded-[10px] ">
      <div className="flex flex-row gap-3">
        <div className="w-12 h-12 flex items-center justify-center">
          {getIconName(paymentMethod.iban) && (
            <img
              className="object-contain max-h-full max-w-full"
              src={`/account_icons/bank-icons/${getIconName(paymentMethod.iban)}`}
            />
          )}
        </div>
        <div className="flex flex-col gap-1">
          <KSpan
            text={paymentMethod.bankName}
            fontSize={16}
            fontWeight={500}
            lineHeight="24px"
            letterSpacing="-0.176px"
            color="#000"
          />
          <KSpan text={paymentMethod.iban} color="#999" />
        </div>
      </div>
      <div className=" flex items-center justify-center p-0 rounded-[10px] gap-3">
        <div>
          {" "}
          <KButton
            text={lang.account.payment_methods.edit_button}
            height="32px"
            width="auto"
            padding="6px 12px"
            background="#FFF"
            onClick={() => {
              setEdit(true)
              setEditableMethodKey(paymentId)
            }}
          />
        </div>
        <div
          style={{
            border: "1px solid #FF5865",
            boxShadow: "0px 1px 2px 0px rgba(233, 53, 53, 0.08)",
            borderRadius: "10px"
          }}
        >
          <KButton
            leftIcon="/account_icons/delete-bin-red.svg"
            background="#FFF"
            padding="6px"
            width="32px"
            height="32px"
            borderRadius={10}
            onClick={() => {
              setOpenConfirmation(true)
            }}
          />
        </div>
      </div>
      <ConfirmationDialog
        loading={false}
        openConfirmation={openConfirmation}
        setOpenConfirmation={setOpenConfirmation}
        handleConfirm={handleConfirm}
      />
    </div>
  )
}

export default connect(null, { createPaymentMethod, deletePaymentMethod, updatePaymentMethod })(ListComponent)
