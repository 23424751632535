import React from "react"
import { Affiliate } from "../../../types"
import { KSpan, KTitleSpan } from "kahuna-base-react-components"
import { lang } from "../../../constants/languages"

const AffiliateTable = ({ affiliateList }: { affiliateList: Affiliate[] }) => {
  return (
    <div className="mt-8 mb-12">
      <div className="w-full flex flex-row px-4">
        <div className="w-[90%] pl-[60px]">
          <KSpan text={lang.affiliate.name_hover} color="#000" fontWeight={500} />
        </div>
        <div className="w-[10%]">
          <KSpan text={lang.affiliate.commissions_hover} color="#000" fontWeight={500}/>
        </div>
      </div>
      {affiliateList.map((affiliate: Affiliate) => {
        const firstName = affiliate.toUser?.firstName || ""
        const lastName = affiliate.toUser?.lastName || ""

        return (
          <div
            style={{ width: "100%" }}
            className={`rounded-lg pl-4 pr-4 table-row-hover  hover:bg-[#F7F7F7] !border-none mt-2`}
            key={affiliate.toUser?.id}
          >
            <div className={`h-[64px] flex`} style={{ width: "100%" }}>
              <div className="flex items-center" style={{ width: "90%" }}>
                <img
                  src={affiliate.toUser?.photo || "/account_icons/avatar.svg"}
                  className={`w-[48px] h-[48px] rounded-[99px] cursor-pointer mr-3`}
                />
                <KSpan text={`${firstName} ${lastName}`} color="#111" />
              </div>
              <div className="flex items-center justify-start" style={{ width: "10%" }}>
                <KSpan
                  text={`${affiliate.commission.toString()}%`}
                  color="#111"
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AffiliateTable
