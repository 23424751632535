import { KButton, KTitleSpan, KSpan, KInput, KDropdown, KCodeInput } from "kahuna-base-react-components"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import React, { useEffect, useState } from "react"
import { emailValidation, validateCode } from "../../../../actions/loginActions"
import { connect } from "react-redux"
import axios from "axios"
//@ts-ignore
import ArtistIcon from "../../../../assets/start_your_journey_icons/microphone.svg"
//@ts-ignore
import ManagerIcon from "../../../../assets/start_your_journey_icons/user.svg"
//@ts-ignore
import LawyerIcon from "../../../../assets/start_your_journey_icons/file-edit.svg"
//@ts-ignore
import DistributorIcon from "../../../../assets/start_your_journey_icons/box.svg"
//@ts-ignore
import LabelIcon from "../../../../assets/start_your_journey_icons/disc.svg"
//@ts-ignore
import FanIcon from "../../../../assets/start_your_journey_icons/headphone.svg"
//@ts-ignore
import UsersIcon from "../../../../assets/start_your_journey_icons/group-line.svg"
//@ts-ignore
import ChevronDownIcon from "../../../../assets/start_your_journey_icons/chevron-down.svg"
//@ts-ignore
import WorldIcon from "../../../../assets/start_your_journey_icons/world.svg"
//@ts-ignore
import EmailIcon from "../../../../assets/start_your_journey_icons/e-mail.svg"
//@ts-ignore
import ProfileIcon from "../../../../assets/start_your_journey_icons/profile.svg"
//@ts-ignore
import PhoneIcon from "../../../../assets/start_your_journey_icons/phone.svg"
//@ts-ignore
import ResendMailIcon from "../../../../assets/start_your_journey_icons/mail-icon.svg"
import { StartYourJourneyPages, Details } from "../StartYourJourney"
import { MultiValue } from "react-select"
import { InformationPageForm, LoginFormValues } from "../../../../types"
import { countryCodeOptions, lang } from "../../../../constants/languages"

const connectionOptions: KSelectOption[] = [
  { label: lang.start_your_journey.information_page.artist_option, value: 0, value2: "artist", icon: ArtistIcon },
  { label: lang.start_your_journey.information_page.manager_option, value: 1, value2: "manager", icon: ManagerIcon },
  { label: lang.start_your_journey.information_page.lawyer_option, value: 2, value2: "lawyer", icon: LawyerIcon },
  {
    label: lang.start_your_journey.information_page.distributor_option,
    value: 3,
    value2: "distributor",
    icon: DistributorIcon
  },
  { label: lang.start_your_journey.information_page.label_option, value: 4, value2: "label", icon: LabelIcon },
  { label: lang.start_your_journey.information_page.fan_option, value: 5, value2: "fan", icon: FanIcon }
]

const InformationPage = (props: {
  onSubmit: (
    form: LoginFormValues,
    userCheck: boolean,
    useCredentials: boolean,
    subject: string,
    callback?: (overideCodeValidation: boolean) => void
  ) => void
  validate: (form: LoginFormValues, callback?: () => void) => void
  selectedPage: number
  setSelectedPage: (page: number) => void
  details: Details
  setDetails: (details: Details) => void
  completedSteps: { first: boolean; second: boolean; third: boolean; fourth: boolean }
  setCompletedSteps: (steps: { first: boolean; second: boolean; third: boolean; fourth: boolean }) => void
}) => {
  const initialForm = {
    country: props.details.country,
    contactName: props.details.contactName,
    email: props.details.email,
    phoneNumber: props.details.phoneNumber,
    code: "",
    ipAddress: ""
  }
  const [form, setForm] = useState<InformationPageForm>({ ...initialForm })
  const [isCodeValidated, setIsCodeValidated] = useState<boolean>(props.completedSteps.first)
  const [showCode, setShowCode] = useState(false)
  const [checkTerms, setCheckTerms] = useState<boolean>(props.completedSteps.first)
  const [time, setTime] = useState(0)
  const [connectionToArtist, setConnectionToArtist] = useState<KSelectOption | undefined>(props.details.role)

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json")
      setForm({ ...form, ipAddress: res.data.ip })
    }

    getData().then()
  }, [])

  const formatPhoneNumber = (value: string | undefined) => {
    if (value) {
      // Filter the value to only include numbers and the + sign at the beginning
      const filteredValue = value.replace(/[^\d+]/g, "").replace(/^(\+?)(.*)/, (_, plus, rest) => `${plus}${rest}`)

      // Return the filtered value without splitting into groups
      return filteredValue
    }
    return ""
  }

  const renderButton = () => {
    const disabled =
      !props.completedSteps.first &&
      (!checkTerms ||
        (showCode && (!(form.code.length === 6) || !isCodeValidated)) ||
        !form.contactName ||
        !form.country ||
        !form.email ||
        !form.phoneNumber ||
        !connectionToArtist?.label)
    return (
      <KButton
        onClick={() => {
          if (!showCode && !props.completedSteps.first) {
            props.onSubmit(
              { email: form.email, username: form.email, ipAddress: form.ipAddress },
              false,
              false,
              "Code Validation",
              () => {
                setShowCode(true)
                setTime(180)
              }
            )
          } else if (showCode || props.completedSteps.first) {
            props.setDetails({
              ...props.details,
              contactName: form.contactName,
              country: form.country,
              email: form.email,
              phoneNumber: form.phoneNumber,
              role: connectionToArtist as KSelectOption
            })
            props.setSelectedPage(StartYourJourneyPages.ARTIST_SELECTION)
            props.setCompletedSteps({ ...props.completedSteps, first: true })
          }
        }}
        text={
          !showCode && !props.completedSteps.first
            ? lang.start_your_journey.information_page.send_validation_code_button
            : lang.start_your_journey.information_page.save_and_next_step_button
        }
        disabled={disabled}
        background="black"
        textColor="white"
        padding="14px"
        height="48px"
      />
    )
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`
  }

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [time])

  return (
    <div className="p-4">
      <div className={"w-full grid pt-2"}>
        <KTitleSpan
          text={lang.start_your_journey.information_page.general_information}
          lineHeight={"40px"}
          fontSize={32}
          color="#000"
          fontWeight={500}
        />

        <div className={"mt-2"}>
          <KSpan text={lang.start_your_journey.information_page.desc} />
        </div>

        <div className={"flex flex-col gap-2 mt-6"} style={{}}>
          <div style={{ width: "100%" }}>
            <KInput
              value={form.contactName}
              onChange={(text) => setForm({ ...form, contactName: text })}
              placeholder={lang.start_your_journey.information_page.contact_name_placeholder}
              leftIcon={ProfileIcon}
              padding="14px"
              gap="8px"
              disabled={props.completedSteps.first}
            />
          </div>
          <div
            style={{
              pointerEvents: props.completedSteps.first ? "none" : "auto",
              width: "100%"
            }}
          >
            <KDropdown
              selected={{ label: form.country, value: 90 }}
              defaultValue={{ label: form.country, value: 90 }}
              placeholder={lang.start_your_journey.information_page.country_placeholder}
              leftIcon={WorldIcon}
              gap="8px"
              padding="14px"
              height={48}
              onSelect={(selected) => setForm({ ...form, country: (selected as KSelectOption).label })}
              options={countryCodeOptions}
              rightIcon={ChevronDownIcon}
              enableIndicator
            />
          </div>
          <div style={{ width: "100%" }}>
            <KDropdown
              selected={connectionToArtist}
              onSelect={(selected) => {
                const selectedOption = selected as KSelectOption
                setConnectionToArtist(selectedOption)
                if (selectedOption?.value2 !== props.details.role?.value2) {
                  props.setDetails({ ...props.details, artist: [] })
                }
              }}
              placeholder={lang.start_your_journey.information_page.connection_placeholder}
              leftIcon={UsersIcon}
              options={connectionOptions}
              rightIcon={ChevronDownIcon}
              defaultValuePrimitive={props.details.role?.value2}
              padding="14px"
              gap="8px"
              hideChosenOptionIcon={true}
              enableIndicator
            />
          </div>
        </div>
        <div className={"flex mt-2 flex-col gap-2"} style={{ width: "" }}>
          <div style={{ width: "100%" }}>
            <KInput
              value={form.email}
              onChange={(text) => setForm({ ...form, email: text })}
              placeholder={lang.start_your_journey.information_page.email_placeholder}
              leftIcon={EmailIcon}
              padding="14px"
              gap="8px"
              disabled={props.completedSteps.first || showCode}
            />
          </div>
        </div>
        <div className={"flex mt-2 flex-col gap-2"} style={{ width: "" }}>
          <div style={{ width: "100%" }}>
            <KInput
              value={form.phoneNumber}
              onChange={(text) => setForm({ ...form, phoneNumber: formatPhoneNumber(text) })}
              placeholder={lang.start_your_journey.information_page.phone_number_placeholder}
              leftIcon={PhoneIcon}
              padding="14px"
              gap="8px"
              disabled={props.completedSteps.first || showCode}
            />
          </div>
        </div>
        {showCode && !props.completedSteps.first && (
          <div className={"mt-6 flex items-center"} style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <KCodeInput
                onChange={(text: string) => {
                  setForm({ ...form, code: text })
                  if (text.length === 6) {
                    props.validate({ email: form.email, code: text }, () => {
                      setIsCodeValidated(true)
                    })
                  }
                }}
                autoBlur={true}
                fitInContainer
                height={64}
              />
            </div>
          </div>
        )}
        <div className="flex mt-4 mb-8">
          <div>
            <KInput
              value={"checkTerms"}
              onChange={() => setCheckTerms(!checkTerms)}
              type={"checkbox"}
              checked={checkTerms}
              shadowDisabled={true}
              width={16}
              height={16}
              accentColor={"black"}
              disabled={props.completedSteps.first || showCode}
            />
          </div>
          <div className={"mt-1"} style={{ maxWidth: 600 }}>
            <KSpan text={lang.start_your_journey.information_page.consent_policy_1} color="#1F1F1F" fontWeight={500} />
            <a
              href="https://kahuna.io/pages/terms-of-services"
              target="_blank"
              className="ml-1 mr-1"
              style={{ textDecoration: "underline" }}
            >
              <KSpan
                text={lang.start_your_journey.information_page.consent_policy_terms_of_service}
                color="#1F1F1F"
                fontWeight={500}
              />
            </a>
            <KSpan text={lang.start_your_journey.information_page.consent_policy_2} color="#1F1F1F" fontWeight={500} />
            <a
              href="https://kahuna.io/pages/privacy-policy"
              target="_blank"
              className="ml-1 mr-1"
              style={{ textDecoration: "underline" }}
            >
              <KSpan
                text={lang.start_your_journey.information_page.consent_policy_privacy_policy}
                color="#1F1F1F"
                fontWeight={500}
              />
            </a>
            <KSpan text={lang.start_your_journey.information_page.consent_policy_3} color="#1F1F1F" fontWeight={500} />
            <KSpan
              text={` ${lang.start_your_journey.information_page.consent_text}`}
              color="#1F1F1F"
              fontWeight={500}
            />
          </div>
        </div>

        {renderButton()}
        {showCode &&
          !props.completedSteps.first &&
          (time === 0 ? (
            <div className="mt-2">
              <KButton
                leftIcon={ResendMailIcon}
                onClick={() => {
                  props.onSubmit(
                    { email: form.email, username: form.email, ipAddress: form.ipAddress },
                    false,
                    false,
                    "Code Validation",
                    () => {
                      setShowCode(true)
                      setTime(180)
                    }
                  )
                }}
                text={lang.start_your_journey.information_page.resend_code_button}
                shadowDisabled={true}
                background="transparent"
                textColor="#000"
                textDecoration="underline"
              />
            </div>
          ) : (
            <div className="ml-4 flex justify-center items-center h-11">
              <KSpan text={formatTime(time)} lineHeight="14px" />
            </div>
          ))}
      </div>
    </div>
  )
}
export default connect(null, { onSubmit: emailValidation, validate: validateCode })(InformationPage)
