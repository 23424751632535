import { KButton, KLogo, KSpan } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CalendlyPage from "./pages/CalendlyPage"
import { lang } from "../../../constants/languages"
import InformationPage from "./pages/InformationPage"
import ArtistSelectionPage from "./pages/ArtistSelectionPage"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import HowHearUsPage from "./pages/HowHearUsPage"
import { initialHearingSources } from "../../../constants"
import ThankYouPage from "./pages/ThankYouPage"
import { publicApi } from "../../../apis"
//@ts-ignore
import Calendar from "../../../assets/start_your_journey_icons/calendar.svg"
//@ts-ignore
import Source from "../../../assets/start_your_journey_icons/source.svg"
//@ts-ignore
import ArtistIcon from "../../../assets/start_your_journey_icons/user-filled.svg"
import { SelectOption } from "../../../types"
import Language from "../account/language/Language"

export enum StartYourJourneyPages {
  INFORMATIONS = 1,
  ARTIST_SELECTION = 2,
  HOW_HEAR_US = 3,
  CALENDLY_PAGE = 4,
  THANK_YOU = 5
}

export interface Details {
  contactName: string
  country: string
  email: string
  phoneNumber: string
  role?: KSelectOption
  artist: SelectOption[]
  exceedsListenerLimit: boolean // min 25K,
  hearingSource: string
}

export const saveRegistration = async (
  meetingId: string,
  description: string,
  db_status: string,
  type: string,
  start_time: string,
  created_at: string,
  name: string,
  country: string,
  email: string,
  phoneNumber: string,
  role: string,
  artists: string,
  hearingSource: string
) => {
  const response = await publicApi(`/save_registration/`, {
    method: "POST",
    data: {
      calendly_id: meetingId,
      description,
      db_status,
      type,
      start_time,
      created_at,
      name,
      country,
      email,
      phone_number: phoneNumber,
      role,
      artists,
      hearing_source: hearingSource
    }
  })
}

const headerHeight = 88

const StartYourJourney = () => {
  const [selectedPage, setSelectedPage] = useState(StartYourJourneyPages.INFORMATIONS)
  const [completedSteps, setCompletedSteps] = useState<{
    first: boolean
    second: boolean
    third: boolean
    fourth: boolean
  }>({
    first: false,
    second: false,
    third: false,
    fourth: false
  })

  const navigate = useNavigate()

  const [details, setDetails] = useState<Details>({
    contactName: "",
    country: lang.common.turkey,
    email: "",
    phoneNumber: "",
    role: undefined,
    artist: [] as SelectOption[],
    exceedsListenerLimit: false,
    hearingSource: ""
  })

  const [hearingSource, setHearingSource] = useState({ ...initialHearingSources })

  return (
    <div style={{ height: "100vh", backgroundColor: "#F0F0F0", padding: 10 }}>
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 2px 2px 0px lightgray"
        }}
      >
        <div className="grid" style={{ height: `${headerHeight}px` }}>
          <div
            className="flex pl-10 pr-10"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div style={{ textAlign: "left", display: "inline-block", cursor: "pointer" }} onClick={() => navigate(-1)}>
              <KLogo hoverEnabled={true} width={48} height={48} logoColor={"white"} borderRadius={1} />
            </div>
            <div className="flex items-center gap-2">
              <img src="/navigation_icons/profile_dropdownmenu_icons/language.svg" />
              <div>
                <Language showTitle={false} dropdownWidth={120} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-row items-start justify-center"
          style={{ width: "100%", height: "calc(100% - 88px)", overflowY: "scroll" }}
        >
          <div
            className="flex flex-row gap-2.5 w-4/5 false h-full"
            style={{
              justifyContent: selectedPage === StartYourJourneyPages.THANK_YOU ? "center" : "start",
              alignItems: selectedPage === StartYourJourneyPages.THANK_YOU ? "center" : "start"
            }}
          >
            {selectedPage !== StartYourJourneyPages.THANK_YOU && (
              <div className="flex flex-col items-start rounded-b-[10px] p-6 gap-1" style={{ width: 280 }}>
                <div className="pl-4 pb-6">
                  <KSpan text={lang.catalog.smart_link.steps} />
                </div>
                <div>
                  <KButton
                    gap="8px"
                    text={lang.start_your_journey.informations}
                    leftIcon={completedSteps.first ? "/catalog_icons/check-circle.svg" : "/account_icons/sparkle.svg"}
                    onClick={() => setSelectedPage(StartYourJourneyPages.INFORMATIONS)}
                    background={selectedPage === StartYourJourneyPages.INFORMATIONS ? "#F3F3F3" : "transparent"}
                    fontWeight={400}
                    shadowDisabled={true}
                  />
                </div>
                <div>
                  <KButton
                    gap="8px"
                    text={lang.start_your_journey.artist}
                    leftIcon={
                      completedSteps.second ? "/catalog_icons/check-circle.svg" : "/account_icons/user-search.svg"
                    }
                    onClick={() => {
                      if (completedSteps.first) {
                        setSelectedPage(StartYourJourneyPages.ARTIST_SELECTION)
                      }
                    }}
                    background={selectedPage === StartYourJourneyPages.ARTIST_SELECTION ? "#F3F3F3" : "transparent"}
                    fontWeight={400}
                    shadowDisabled={true}
                  />
                </div>
                <div>
                  <KButton
                    gap="8px"
                    leftIcon={completedSteps.third ? "/catalog_icons/check-circle.svg" : Source}
                    text={lang.start_your_journey.source}
                    onClick={() => {
                      if (completedSteps.first && completedSteps.second) {
                        setSelectedPage(StartYourJourneyPages.HOW_HEAR_US)
                      }
                    }}
                    background={selectedPage === StartYourJourneyPages.HOW_HEAR_US ? "#F3F3F3" : "transparent"}
                    fontWeight={400}
                    shadowDisabled={true}
                  />
                </div>
                {details.exceedsListenerLimit && (
                  <div>
                    <KButton
                      gap="8px"
                      leftIcon={Calendar}
                      text={lang.start_your_journey.book_a_demo}
                      onClick={() => {
                        if (completedSteps.first && completedSteps.second && completedSteps.third) {
                          setSelectedPage(StartYourJourneyPages.CALENDLY_PAGE)
                        }
                      }}
                      background={selectedPage === StartYourJourneyPages.CALENDLY_PAGE ? "#F3F3F3" : "transparent"}
                      fontWeight={400}
                      shadowDisabled={true}
                    />
                  </div>
                )}
              </div>
            )}
            <div
              className="flex flex-row justify-start items-start p-6 h-full"
              style={{
                width: selectedPage === StartYourJourneyPages.CALENDLY_PAGE ? "100%" : "700px"
              }}
            >
              {selectedPage === StartYourJourneyPages.INFORMATIONS && (
                <InformationPage
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  details={details}
                  setDetails={setDetails}
                  completedSteps={completedSteps}
                  setCompletedSteps={setCompletedSteps}
                />
              )}
              {selectedPage === StartYourJourneyPages.ARTIST_SELECTION && (
                <ArtistSelectionPage
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  details={details}
                  setDetails={setDetails}
                  completedSteps={completedSteps}
                  setCompletedSteps={setCompletedSteps}
                />
              )}
              {selectedPage === StartYourJourneyPages.HOW_HEAR_US && (
                <HowHearUsPage
                  details={details}
                  setDetails={setDetails}
                  hearingSource={hearingSource}
                  setHearingSource={setHearingSource}
                  setSelectedPage={setSelectedPage}
                  selectedPage={selectedPage}
                  completedSteps={completedSteps}
                  setCompletedSteps={setCompletedSteps}
                />
              )}
              {selectedPage === StartYourJourneyPages.CALENDLY_PAGE && (
                <CalendlyPage
                  details={details}
                  setDetails={setDetails}
                  setSelectedPage={setSelectedPage}
                  selectedPage={selectedPage}
                  completedSteps={completedSteps}
                  setCompletedSteps={setCompletedSteps}
                />
              )}
              {selectedPage === StartYourJourneyPages.THANK_YOU && <ThankYouPage />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartYourJourney
