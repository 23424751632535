import { connect } from "react-redux"
import * as React from "react"
import { ChangePasswordFormProps, ChangePasswordFormValues } from "../../../../types"
import changePasswordValidator from "./changePasswordValidator"
import { RootState } from "../../../../store"
import { FC, useState } from "react"
import { changePassword } from "../../../../actions"
import { KButton, KInput, KTitleSpan } from "kahuna-base-react-components"
import { lang } from "../../../../constants/languages"

const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {
  const { username, changePassword, open, setOpen } = props

  const createFormValues = (): ChangePasswordFormValues => {
    const initialValues = new ChangePasswordFormValues()
    initialValues.username = username
    return initialValues
  }

  const [passwordFormValues, setPasswordFormValues] = useState<ChangePasswordFormValues>(createFormValues) // ilk degerler ataniyor

  const [isText, setIsText] = useState<{ currentPassword:Boolean, newPassword: boolean, confirmPassword: boolean }>({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  })
  return (
    <>
      <div className="p-6 shadow-md w-[400px] rounded-[10px] bg-[#fff]">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-8 items-center justify-center">
            <span className="w-[96px] aspect-square rounded-[48px] shadow-md flex justify-center items-center">
              <img className="w-12 h-12" src="/account_icons/mail.svg"></img>
            </span>
            <KTitleSpan text={lang.account.profile.change_password_form.title} fontSize={24} lineHeight="32px" />
          </div>
          <div className="flex flex-col gap-3 justify-center items-center">
            <KInput
              type={isText.currentPassword ? "text" : "password"}
              leftIcon="/account_icons/password.svg"
              rightIcon={isText.currentPassword ? "/account_icons/eye-open.svg" : "/account_icons/eye-closed.svg"}
              rightIconClick={() => {
                setIsText((prev) => {
                  return {
                    ...prev,
                    currentPassword: !prev.currentPassword
                  }
                })
              }}
              value={passwordFormValues.currentPassword}
              placeholder={lang.account.profile.change_password_form.current_password_placeholder}
              onChange={(value) => {
                setPasswordFormValues((prev) => {
                  return { ...prev, currentPassword: value }
                })
              }}
              padding="14px"
            />
            <KInput
              type={isText.newPassword ? "text" : "password"}
              leftIcon="/account_icons/password.svg"
              rightIcon={isText.newPassword ? "/account_icons/eye-open.svg" : "/account_icons/eye-closed.svg"}
              rightIconClick={() => {
                setIsText((prev) => {
                  return {
                    ...prev,
                    newPassword: !prev.newPassword
                  }
                })
              }}
              value={passwordFormValues.newPassword}
              placeholder={lang.account.profile.change_password_form.new_password_placeholder}
              onChange={(value) => {
                setPasswordFormValues((prev) => {
                  return { ...prev, newPassword: value }
                })
              }}
              padding="14px"
            />

            <KInput
              type={isText.confirmPassword ? "text" : "password"}
              leftIcon="/account_icons/password.svg"
              rightIcon={
                isText.confirmPassword ? "/account_icons/eye-open.svg" : "/account_icons/eye-closed.svg"
              }
              rightIconClick={() => {
                setIsText((prev) => {
                  return {
                    ...prev,
                    confirmPassword: !prev.confirmPassword
                  }
                })
              }}
              value={passwordFormValues.newPasswordAgain}
              placeholder={lang.account.profile.change_password_form.confirm_password_placeholder}
              onChange={(value) => {
                setPasswordFormValues((prev) => {
                  return { ...prev, newPasswordAgain: value }
                })
              }}
              padding="14px"
            />
          </div>
          <div className="flex gap-3 justify-center items-center mt-6">
            <KButton
              text={lang.common.cancel}
              onClick={() => {
                setOpen(false)
              }}
              shadowDisabled={true}
              background="#fff"
              height="48px"
              textColor="#000"
            />
            <KButton
              text={lang.account.profile.change_password_form.reset_password_button}
              background="#000"
              textColor="white"
              onClick={() => {
                const errors = changePasswordValidator(passwordFormValues)
                if (Object.keys(errors).length === 0) {
                  changePassword(passwordFormValues)
                  setOpen(false)
                }
              }}
              height="48px"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    username: state.auth.user.username
  }
}

export default connect(mapStateToProps, { changePassword })(ChangePasswordForm)
