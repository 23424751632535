import React, { useEffect, useRef, useState } from "react"

const AudioPlayer = ({
  src,
  playingAudioRefs,
  setPlayingAudioRefs
}: {
  src: string
  playingAudioRefs: HTMLAudioElement[]
  setPlayingAudioRefs: (playingAudioRefs: HTMLAudioElement[]) => void
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayPause = (event) => {
    event.stopPropagation()

    if (!audioRef || !audioRef.current) {
      return
    }
    if (isPlaying) {
      audioRef.current.pause()
    } else {
      playingAudioRefs.forEach((ref: any) => ref?.current?.pause())
      audioRef.current.play()
      //@ts-ignore
      setPlayingAudioRefs([audioRef])
    }
    setIsPlaying(!isPlaying)
  }

  const handleAudioEnded = () => {
    setIsPlaying(false)
  }

  useEffect(() => {
    const ref = playingAudioRefs.find((ref: any) => ref === audioRef)
    if (!ref && isPlaying) {
      setIsPlaying(false)
    }
  }, [playingAudioRefs])

  const renderIcon = () => {
    return isPlaying ? <img src="/catalog_icons/pause-line.svg" /> : <img src="/catalog_icons/play-line.svg" />
  }

  return (
    <div>
      <div className="cursor-pointer" onClick={(event) => togglePlayPause(event)}>
        {src && renderIcon()}
      </div>
      {src && <audio ref={audioRef} src={src} onEnded={handleAudioEnded} />}
    </div>
  )
}

export default AudioPlayer
