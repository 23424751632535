import { AssetRegistryTrackShareHolder, TrackDetailProps, TrackRegistryConsentFile } from "../../../../types"
import * as React from "react"
import { Dialog } from "@headlessui/react"
import { FC, useEffect, useState } from "react"
import { connect } from "react-redux"
import { RootState } from "../../../../store"
import { selectRelease, selectTrack, fetchTrackShareData } from "../../../../actions/catalogActions"
import { useParams } from "react-router-dom"
import "../styles/Catalog.css"
import { hashids } from "../../../../utility"
import ReleaseComponent from "../ReleaseComponent"
import { KButton, KSpan } from "kahuna-base-react-components"
import { avatarColorList, avatarFontColorList, getRandomAvatarIndex } from "../../../../constants"
import { X } from "heroicons-react"
import { releases } from "../../../../apis"
import UploadContributorConsentFileComponent from "../../../ui/components/UploadContributorConsentFile"
import { lang } from "../../../../constants/languages"
//@ts-ignore
import DownloadIcon from "../../../../assets/release-registry-icons/download.svg"
const TrackDetail: FC<TrackDetailProps> = (props) => {
  const { track, selectTrack, release, selectRelease, trackShareData, fetchTrackShareData } = props
  const params = useParams()
  const releaseId = hashids.decode(params.releaseId).toString()
  const [loading, setLoading] = useState<boolean>(true)
  const [notPublishedTrackShareHolderList, setNotPublishedTrackShareHolderList] = useState<
    AssetRegistryTrackShareHolder[]
  >([])
  const [trackRegistryConsentFileList, setTrackRegistryConsentFileList] = useState<TrackRegistryConsentFile[]>([])

  useEffect(() => {
    const fetchInfo = async () => {
      if (props.selectedTrack) {
        if (!release.id) {
          await selectRelease(null, releaseId)
        }
        selectTrack(null, props.selectedTrack)
      }
    }
    fetchInfo()
  }, [props.selectedTrack])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (track.id) {
        try {
          await fetchTrackShareData(track.id)
          releases(`/track-share-holder-invitation/list_not_published/?track_id=${track.id}`, "get").then((resp) => {
            if (resp.data) {
              setNotPublishedTrackShareHolderList(resp.data)
            }
          })
        } catch (err) {
          console.error("Fetch error: ", err)
        }
        releases(`/track-registry-consent-file/list_by_isrc/?isrc=${props.track.isrc}`, "get").then((resp) => {
          if (resp.data) {
            setTrackRegistryConsentFileList(resp.data)
          }
        })
      }
    }
    fetchData().then(() => setLoading(false))
  }, [track.id])

  const dialogOpen = () => {
    if (props.selectedTrack) {
      return true
    }
    return false
  }

  const getInitials = (input: string): string => {
    const filteredInput = input || "-"
    return filteredInput
      .split(",")
      .map((name) => name.trim()[0].toUpperCase())
      .join("")
  }

  const renderArtists = () => {
    const remixerList = track.remixer?.split(",").map((element) => element.trim())
    const lyricistList = track.lyricist?.split(",").map((element) => element.trim())
    const featuringList = track.featuring?.split(",").map((element) => element.trim())
    const contributorList = track.contributor?.split(",").map((element) => element.trim())
    const composerList = track.composer?.split(",").map((element) => element.trim())
    const authorList = track.author?.split(",").map((element) => element.trim())
    const arrangerList = track.arranger?.split(",").map((element) => element.trim())
    const producerList = track.producer?.split(",").map((element) => element.trim())

    const artistMap = {}
    remixerList?.forEach((remixer) => {
      if (remixer in artistMap) artistMap[remixer].push("Remixer")
      else artistMap[remixer] = ["Remixer"]
    })
    lyricistList?.forEach((lyricist) => {
      if (lyricist in artistMap) artistMap[lyricist].push("Lyricist")
      else artistMap[lyricist] = ["Lyricist"]
    })
    featuringList?.forEach((featuring) => {
      if (featuring in artistMap) artistMap[featuring].push("Featuring")
      else artistMap[featuring] = ["Featuring"]
    })
    contributorList?.forEach((contributor) => {
      if (contributor in artistMap) artistMap[contributor].push("Contributor")
      else artistMap[contributor] = ["Contributor"]
    })
    composerList?.forEach((composer) => {
      if (composer in artistMap) artistMap[composer].push("Composer")
      else artistMap[composer] = ["Composer"]
    })
    authorList?.forEach((author) => {
      if (author in artistMap) artistMap[author].push("Author")
      else artistMap[author] = ["Author"]
    })
    arrangerList?.forEach((arranger) => {
      if (arranger in artistMap) artistMap[arranger].push("Arranger")
      else artistMap[arranger] = ["Arranger"]
    })
    producerList?.forEach((producer) => {
      if (producer in artistMap) artistMap[producer].push("Producer")
      else artistMap[producer] = ["Producer"]
    })

    return (
      <div className="mt-4">
        {Object.keys(artistMap).map((artistName: string) => {
          const values = artistMap[artistName]
          const randomIndex = getRandomAvatarIndex()
          return (
            <div className="flex justify-between mt-3" key={`artist_list_div_${artistName}`}>
              <div className="flex">
                <div
                  key={`a_i_${artistName}`}
                  className="w-7 h-7 rounded-[16px] flex justify-center items-center"
                  style={{
                    fontWeight: "500",
                    backgroundColor: avatarColorList[randomIndex],
                    color: avatarFontColorList[randomIndex]
                  }}
                >
                  {getInitials(artistName)}
                </div>
                <div className="ml-2">
                  <KSpan text={artistName} color="#111" fontWeight={400} letterSpacing="-0.084px" />
                </div>
              </div>
              <KSpan text={values.join(", ")} fontSize={12} fontWeight={500} color="#3D3D3D" />
            </div>
          )
        })}
      </div>
    )
  }

  const onDownloadLyrics = () => {
    const element = document.createElement("a")
    const file = new Blob([track.lyrics], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = `${track.title} Lyrics.txt`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  return (
    <Dialog open={dialogOpen()} onClose={() => props.setSelectedTrack(undefined)}>
      <div className="fixed z-[400] w-screen h-screen top-0 left-0 flex items-center justify-end bg-[#0000004d]">
        <div className="bg-white mr-6" style={{ height: "94vh", borderRadius: 10, minWidth: 480, overflow: "auto" }}>
          <X
            className="h-4 cursor-pointer absolute mr-7 mt-1"
            onClick={() => props.setSelectedTrack(undefined)}
            style={{ right: 0 }}
          />
          <div className="p-6">
            <ReleaseComponent release={release} overrideBorder={true} fullScreen={false} titleOverride={track.title} />
            <div className="mt-6">
              <KSpan
                text={lang.catalog.track_detail.artist_title}
                fontSize={14}
                fontWeight={500}
                lineHeight="20px"
                letterSpacing="0.84px"
                color="#737373"
              />
            </div>

            {renderArtists()}

            <div className="mt-6">
              <KSpan
                text={lang.catalog.track_detail.contributor_title}
                fontSize={14}
                fontWeight={500}
                lineHeight="20px"
                letterSpacing="0.84px"
                color="#737373"
              />
            </div>

            <div className="mt-4">
              {trackShareData?.map((shareHolder) => {
                const randomIndex = getRandomAvatarIndex()
                return (
                  <div className="flex mt-3 items-center justify-between" key={Math.random()}>
                    <div className="flex">
                      <div
                        key={shareHolder.email}
                        className="w-7 h-7 rounded-[16px] flex justify-center items-center"
                        style={{
                          fontWeight: "500",
                          backgroundColor: avatarColorList[randomIndex],
                          color: avatarFontColorList[randomIndex]
                        }}
                      >
                        {getInitials(shareHolder.first_name)}
                      </div>
                      <div className="ml-2">
                        <KSpan
                          text={`${shareHolder.first_name} ${shareHolder.last_name}`}
                          color="#111"
                          fontWeight={400}
                          letterSpacing="-0.084px"
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <KSpan text={shareHolder.roles.join(", ")} fontSize={12} />
                      <img className="ml-3 mr-3" src={"/account_icons/vector_5.svg"} />
                      <KSpan text={`${shareHolder.share}%`} fontSize={12} color="#3D3D3D" />
                    </div>
                  </div>
                )
              })}
              {notPublishedTrackShareHolderList?.map((shareHolder) => {
                const randomIndex = getRandomAvatarIndex()
                return (
                  <div className="flex mt-3 items-center justify-between" key={Math.random()}>
                    <div className="flex">
                      <div
                        key={shareHolder.email}
                        className="w-7 h-7 rounded-[16px] flex justify-center items-center"
                        style={{
                          backgroundColor: `${avatarColorList[randomIndex]}33`
                        }}
                      >
                        <KSpan color="rgba(17, 17, 17, 0.20)" text={getInitials(shareHolder.name)} />
                      </div>
                      <div className="ml-2">
                        <KSpan
                          text={`${shareHolder.name}`}
                          color="rgba(17, 17, 17, 0.20)"
                          fontWeight={400}
                          letterSpacing="-0.084px"
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <KSpan text={"Pending"} fontSize={12} color="rgba(17, 17, 17, 0.20)" />
                      <img className="ml-3 mr-3" src={"/account_icons/vector_5.svg"} />
                      <KSpan text={`${shareHolder.share}%`} fontSize={12} color="rgba(17, 17, 17, 0.20)" />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="mt-6">
              <KSpan
                text={lang.catalog.track_detail.lyrics_title}
                fontSize={14}
                fontWeight={500}
                lineHeight="20px"
                letterSpacing="0.84px"
                color="#737373"
              />
            </div>
            <div className="mt-4 flex" style={{ width: "100%" }}>
              <div style={{ width: "60%", overflow: "hidden", display: "grid" }}>
                {(track.lyrics?.substring(0, 100) || "").split("\n").map((line) => (
                  <KSpan key={Math.random().toString()} text={line} />
                ))}
                <KSpan text={"..."} />
              </div>
              <div style={{ width: "40%" }} className="flex justify-end">
                <div style={{ width: 120, height: 40 }}>
                  {track.lyrics && (
                    <KButton
                      text={lang.catalog.track_detail.download_lyrics_button}
                      onClick={() => onDownloadLyrics()}
                      leftIcon={DownloadIcon}
                      background="white"
                      gap="4px"
                      height="36px"
                      padding="8px"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6">
              <KSpan
                text={lang.catalog.track_detail.contributor_consent_files_title}
                fontSize={14}
                fontWeight={500}
                lineHeight="20px"
                letterSpacing="0.84px"
                color="#737373"
              />
              <UploadContributorConsentFileComponent
                trackRegistryConsentFileList={trackRegistryConsentFileList}
                setTrackRegistryConsentFileList={setTrackRegistryConsentFileList}
                isrc={props.track.isrc}
                upc={props.release.upc}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    track: state.catalogData.selectedTrack,
    release: state.catalogData.selectedRelease,
    trackShareData: state.catalogData.trackShareData
  }
}

export default connect(mapStateToProps, { selectTrack, selectRelease, fetchTrackShareData })(TrackDetail)
