import React, { FC, useEffect, useState } from "react"
import { SocialMedia, User } from "../../../../types"

import { KButton, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"

//@ts-ignore
import SpotifyLogo from "../../../../assets/release-platform-icons/SpotifyLogo.png"

// @ts-ignore
import KahunaLogo from "../../../../assets/logo-icons/kahuna.png"
import { getSocialMediaIcon, renderSiteTitle } from "../../../../utility"
import { lang } from "../../../../constants/languages"

type SocialMediaProps = {
  socialMediaList: SocialMedia[]
  fetchSocialMediaLinks: (userId?: number) => void
  saveSocialMediaLink?: (socialMedia: SocialMedia) => void
  deleteSocialMediaLink?: (id: number) => void
  smallScreen: boolean
  isAdmin: boolean
}

const SocialMediaPage: FC<SocialMediaProps> = (props) => {
  const { socialMediaList, isAdmin } = props

  const [addNew, setAddNew] = useState<boolean>(false)
  const [newLinkText, setNewLinkText] = useState("")
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([])

  const fillFavIcons = () => {
    const socialMedias =
      socialMediaList?.map((socialMedia: SocialMedia) => {
        const baseUrl = socialMedia.url?.replace("https://", "").replace("http://", "").split("/")[0]
        return { ...socialMedia, icon: `https://${baseUrl}/favicon.ico` }
      }) || []
    setSocialMedias([...socialMedias])
  }

  useEffect(() => {
    fillFavIcons()
  }, [socialMediaList])

  useEffect(() => {
    props.fetchSocialMediaLinks()
  }, [])

  const renderIcon = (url?: string, icon?: string) => {
    if (!url || !icon) {
      return
    }

    const socialMediaIcon = getSocialMediaIcon(url)
    const platformIcon = socialMediaIcon || icon
    const padding = url.includes("www.youtube.com") ? 2 : 6
    return (
      <img
        src={platformIcon}
        style={{ padding, width: 32, height: 32 }}
        onError={(e: any) => {
          e.target.src = KahunaLogo
          e.target.style.padding = 0
        }}
      />
    )
  }

  const renderUrls = () => {
    return socialMedias?.map((socialMedia) => {
      return (
        <div key={socialMedia.id} className="user-event-div">
          <div className="flex" style={{ width: "100%" }}>
            {socialMedia.url?.includes("spotify") ? (
              <img src={SpotifyLogo} style={{ borderRadius: 10, height: 28 }} />
            ) : (
              renderIcon(socialMedia.url, socialMedia.icon)
            )}

            <div className="items-center flex justify-between" style={{ width: "100%" }}>
              <KSpan
                text={!socialMedia.url?.includes("spotify") ? renderSiteTitle(socialMedia.url) : ""}
                fontSize={14}
                color="#1F1F1F"
                fontWeight={600}
              />

              <div className="flex gap-2">
                <KButton
                  leftIcon={"/account_icons/arrow-up-right.svg"}
                  background="#FFF"
                  padding="4px"
                  width="26px"
                  height="26px"
                  onClick={() => window.open(socialMedia.url, "_blank")}
                />
                {isAdmin && (
                  <KButton
                    leftIcon={"/account_icons/delete-bin-red.svg"}
                    background="#FFF"
                    padding="4px"
                    width="26px"
                    height="26px"
                    onClick={() => {
                      if (!props.deleteSocialMediaLink) {
                        return
                      }
                      props.deleteSocialMediaLink(socialMedia.id || 0)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  const renderSmartLinkAdd = () => {
    return (
      <div
        className={`user-event-div flex justify-center cursor-pointer ${!addNew && "hover:!bg-[#F3F3F3]"} shadow-none`}
        style={{
          background: addNew ? "#FFFFFF" : "#F7F7F7",
          borderColor: addNew ? "#F3F3F3" : "transparent",
          boxShadow: addNew ? "0px 1px 2px 0px rgba(228, 229, 231, 0.24)" : "none"
        }}
        onClick={() => setAddNew(true)}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            setNewLinkText("")
            setAddNew(false)
          }
          if (event.key === "Enter" && props.saveSocialMediaLink && newLinkText && addNew) {
            props.saveSocialMediaLink({ url: newLinkText })
            setNewLinkText("")
            setAddNew(false)
          }
        }}
      >
        <div className="flex items-center" style={{ width: "100%" }}>
          <img src="/account_icons/plus-filled.svg" />
          {addNew ? (
            <div style={{ width: "100%" }}>
              <KInput
                placeholder={lang.artist_space.new_link_placeholder}
                value={newLinkText}
                background="#FFF"
                hoverBackground="#F7F7F7"
                onChange={(value) => setNewLinkText(value)}
                shadowDisabled={true}
                onBlur={() => {
                  if (props.saveSocialMediaLink && newLinkText) {
                    props.saveSocialMediaLink({ url: newLinkText })
                    setNewLinkText("")
                    setAddNew(false)
                  }
                }}
              />
            </div>
          ) : (
            <KTitleSpan
              text={lang.artist_space.new_link}
              fontSize={20}
              fontWeight={500}
              lineHeight="28px"
              fontStyle="normal"
              color="#666666"
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4 pb-6 pt-8">
      <div className="pl-3">
        <KTitleSpan
          text={lang.artist_space.platforms_title}
          fontSize={20}
          fontWeight={500}
          lineHeight="28px"
          color="#000"
        />
      </div>
      <div
        className="grid"
        style={{
          gridTemplateColumns: !props.smallScreen
            ? "repeat(auto-fit, minmax(calc(33.3% - 16px), calc(33.3% - 16px)))"
            : "",
          gridGap: 16
        }}
      >
        {isAdmin && renderSmartLinkAdd()}
        {socialMedias?.length > 0 && renderUrls()}
      </div>
    </div>
  )
}

export default SocialMediaPage
