import { KButton, KLogo, KSpan } from "kahuna-base-react-components"
import React, { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ReleaseSmartLinkProps } from "../../../types"
import { RootState } from "../../../store"
import { connect } from "react-redux"
import { selectRelease } from "../../../actions/catalogActions"
import { hashids } from "../../../utility"
import "./styles/smartlink.css"
import CustomizeLink from "./pages/CustomizeLink"
import ConnectPage from "./pages/ConnectPage"
import AnalyticPage from "./pages/AnalyticPage"
import { lang } from "../../../constants/languages"

export enum SmartLinkPages {
  CUSTOMIZE = 1,
  CONNECT = 2,
  ANALYTICS = 3
}

const headerHeight = 88

const ReleaseSmartLinkPage: FC<ReleaseSmartLinkProps> = (props) => {
  const { release, releases: allReleases, selectRelease } = props

  const [selectedPage, setSelectedPage] = useState(SmartLinkPages.CUSTOMIZE)
  const [smartLinkExist, setSmartLinkExist] = useState(false)
  const [linkText, setLinkText] = useState("")

  const params = useParams()

  const navigate = useNavigate()

  const decodedId = hashids.decode(params.releaseId || "").toString()

  useEffect(() => {
    if (params.releaseId) {
      selectRelease(
        allReleases.find((r) => r.id?.toString() === decodedId),
        decodedId
      )
    }

    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (release.smartLink) {
      setSmartLinkExist(true)
      setLinkText(release.smartLink)
    }
  }, [release])

  return (
    <div style={{ height: "100vh", backgroundColor: "#F0F0F0", padding: 10 }}>
      <div
        style={{
          backgroundColor: "#fff",
          height: "100%",
          width: "100%",
          borderRadius: 10,
          boxShadow: "0px 2px 2px 0px lightgray"
        }}
      >
        <div className="grid" style={{ height: `${headerHeight}px` }}>
          <div
            className="flex pl-10 pr-10"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <div style={{ textAlign: "left", display: "inline-block", cursor: "pointer" }} onClick={() => navigate(-1)}>
              <KLogo hoverEnabled={true} width={48} height={48} logoColor={"white"} borderRadius={1} />
            </div>
            <div>
              {linkText && smartLinkExist ? (
                <KButton
                  background="transparent"
                  text={lang.common.preview}
                  onClick={() => window.open(`https://kahuna.io/s?${linkText}`, "_blank")}
                />
              ) : (
                <span className="powered-by-span">Powered by Kahuna</span>
              )}
            </div>
          </div>
        </div>
        <div className="relative flex flex-row items-start justify-center" style={{ width: "100%" }}>
          <div className="flex flex-row items-start justify-start gap-2.5 w-4/5 false">
            <div className="flex flex-col items-start rounded-b-[10px] p-6 gap-1" style={{ width: 248 }}>
              <div className="pl-4 pb-6">
                <KSpan text={lang.catalog.smart_link.steps} />
              </div>
              <div>
                <KButton
                  gap="8px"
                  text={lang.catalog.smart_link.customize_button}
                  leftIcon="/account_icons/sparkle.svg"
                  onClick={() => setSelectedPage(SmartLinkPages.CUSTOMIZE)}
                  background={selectedPage === SmartLinkPages.CUSTOMIZE ? "#F3F3F3" : "transparent"}
                  fontWeight={400}
                  shadowDisabled={true}
                />
              </div>
              <div>
                <KButton
                  gap="8px"
                  text={lang.catalog.smart_link.connect_button}
                  leftIcon="/account_icons/link.svg"
                  onClick={() => smartLinkExist && setSelectedPage(SmartLinkPages.CONNECT)}
                  background={selectedPage === SmartLinkPages.CONNECT ? "#F3F3F3" : "transparent"}
                  fontWeight={400}
                  shadowDisabled={true}
                />
              </div>
              <div>
                <KButton
                  gap="8px"
                  leftIcon="/account_icons/bar-chart-square.svg"
                  text={lang.catalog.smart_link.analytics_button}
                  onClick={() => smartLinkExist && setSelectedPage(SmartLinkPages.ANALYTICS)}
                  background={selectedPage === SmartLinkPages.ANALYTICS ? "#F3F3F3" : "transparent"}
                  fontWeight={400}
                  shadowDisabled={true}
                />
              </div>
            </div>
            <div className="flex flex-row justify-start items-start w-[75%] min-[1600px]:w-[60%] max-[850px]:!w-full p-6">
              {selectedPage === SmartLinkPages.CUSTOMIZE && (
                <CustomizeLink
                  release={release}
                  setSelectedPage={setSelectedPage}
                  setSmartLinkExist={setSmartLinkExist}
                  linkText={linkText}
                  setLinkText={setLinkText}
                />
              )}
              {selectedPage === SmartLinkPages.CONNECT && (
                <ConnectPage release={release} setSelectedPage={setSelectedPage} />
              )}
              {selectedPage === SmartLinkPages.ANALYTICS && <AnalyticPage release={release} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    releases: state.catalogData.paginatedReleases.results,
    release: state.catalogData.selectedRelease
  }
}

export default connect(mapStateToProps, { selectRelease })(ReleaseSmartLinkPage)
