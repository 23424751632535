import { CollaboratorData, TableColumn } from "../../../../../types"
import {
  avatarColorList,
  TABLE_COLUMN_TYPE,
  COLLAB_INVITATION_STATUS,
  TABLE_ROW_STATUS,
  getRandomAvatarIndex,
  avatarFontColorList
} from "../../../../../constants"
import React, { CSSProperties } from "react"

const cellStyle = { fontWeight: 400, lineHeight: "20px", textColor: "#111827" }
const headerStyle: CSSProperties = { textAlign: "left", width: "25%" }

const trackShareHolderColumns: TableColumn[] = [
  {
    header: "Collaborator Name",
    headerStyle,
    cellStyle,
    type: TABLE_COLUMN_TYPE.FUNCTION,
    render: (row: CollaboratorData) => {
      const initials = `${row.first_name[0]}${row.last_name[0]}`
      let avatarElement
      if (row.photo) {
        avatarElement = (
          <img src={row.photo} alt={initials} style={{ borderRadius: "50%", height: "32px", width: "32px" }} />
        )
      } else {
        if (row.status === Number(TABLE_ROW_STATUS.Active)) {
          const randomIndex = getRandomAvatarIndex()
          avatarElement = (
            <div
              className="h-[32px] w-[32px] rounded-full flex flex-col justify-center"
              style={{ backgroundColor: avatarColorList[randomIndex], color: avatarFontColorList[randomIndex] }}
            >
              <div className="text-LG font-medium text-white items-center justify-center flex">{initials}</div>
            </div>
          )
        } else {
          avatarElement = <div className="h-[32px] w-[32px] rounded-full flex flex-col justify-center bg-gray-200" />
        }
      }
      return (
        <>
          <div className="flex flex-row items-center">
            {avatarElement}
            <span className="ml-3">
              {" "}
              {row.first_name} {row.last_name}
            </span>
          </div>
        </>
      )
    }
  },
  {
    header: "Email",
    type: TABLE_COLUMN_TYPE.TEXT,
    headerStyle,
    cellStyle,
    attribute: "email"
  },
  {
    header: "Roles",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "30%" },
    cellStyle,
    render: (row: CollaboratorData) => {
      return row.roles.join(", ")
    }
  },
  {
    header: "Share",
    type: TABLE_COLUMN_TYPE.FUNCTION,
    headerStyle: { ...headerStyle, width: "10%" },
    cellStyle,
    render: (row: CollaboratorData) => `${row.share} %`
  },
  {
    header: "Status",
    type: TABLE_COLUMN_TYPE.CONST,
    headerStyle: { ...headerStyle, width: "10%" },
    cellStyle,
    constType: COLLAB_INVITATION_STATUS,
    attribute: "status"
  }
]

export default trackShareHolderColumns
