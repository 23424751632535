import * as React from "react"
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react"
import {
  AssetRegistryPublishingShareHolder,
  AssetRegistryTrackShareHolder,
  LCReleaseForm,
  LCReleaseFormData,
  SelectOption,
  SpotifyEditorialPitching,
  TrackRegistryConsentFile
} from "../../../../types"
import { releases } from "../../../../apis"
import { toast } from "react-toastify"
import { successFilledToastOptions, warningFilledToastOptions } from "../../../../constants"
import { ReleaseRegistryPages } from "../../../../actions/types"
import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { hashids, turkishCharMap } from "../../../../utility"
import { useNavigate } from "react-router-dom"

export const notAllowedTrackRelationships = ["label", "default-track-offer", "records"]
export const notAllowedTrackAttributes = [
  "roles",
  "created-at",
  "updated-at",
  "original-id",
  "preview-url",
  "length",
  "audio16bits",
  "audio24bits",
  "all16bits",
  "all24bits",
  "audios",
  "master-types"
]

export const notAllowedProductRelationships = [
  "company",
  "default-offer",
  "distributor",
  "offers",
  "performers",
  "tracks"
]
export const notAllowedProductAttributes = [
  "created-at",
  "updated-at",
  "cover-url",
  "full-cover-url",
  "rights-completed",
  "metadata-completed",
  "tracks-metadata-completed",
  "booklet-url",
  "has-play-page",
  "audio-completed",
  "validatable",
  "default-product-offer-completed",
  "is-mfit",
  "is-destroyable",
  "audio16bits",
  "audio24bits",
  "all16bits",
  "all24bits",
  "audios",
  "is-back-catalogue",
  "roles",
  "status",
  "product-type-name",
  "front-cover-md5",
  "computed-rights",
  "release-date",
  "default-offer-validation"
]

export const notAllowedOfferRelationships = ["created-at"]
export const notAllowedOfferAttributes = [
  "created-at",
  "updated-at",
  "release-type",
  "displayed-territories",
  "active",
  "is-default",
  "name",
  "preview-before-release-date",
  "status",
  "usage",
  "download",
  "stream"
]

export interface OptionType {
  value: string
  label: string
  type: string
}
export interface AssetRegistryOptionsType {
  labelOptions: OptionType[]
  productTypeOptions: OptionType[]
  languageOptions: OptionType[]
  metadataLanguageOptions
  productGenreOptions: OptionType[]
  distributorProductSubGenreOptions
  distributorPriceCodeOptions: OptionType[]
  distributorTrackPriceOptions: OptionType[]
  roleOptions: string[]
}

export interface ResidualFormProps {
  sendToBeatport: boolean
}

interface ReleaseRegistryOrchestratorProviderProps extends PropsWithChildren {
  data: LCReleaseForm
  options: AssetRegistryOptionsType
  residualData: ResidualFormProps
  selectedTab: ReleaseRegistryPages
  setSelectedTab: (tab: ReleaseRegistryPages) => void
  lyricsWidget: any
  spotifyEditorialPitching: SpotifyEditorialPitching
  setSpotifyEditorialPitching: (sep: SpotifyEditorialPitching) => void
  appleDigitalMaster: string
  setAppleDigitalMaster: (adm: string) => void
  isPublishing: boolean
  summaryPublishingText: string
  bmwCustomIdKeyword: string
}

export type ReleaseRegistryOrchestrator = {
  releaseForm: LCReleaseForm
  setReleaseForm: (releaseForm: LCReleaseForm) => void
  updateProduct: (attributeName: string, value: any) => void
  updateOffer: (attributeName: string, value: any) => void
  updateOfferRelationships: (attributeName: string, value: any) => void
  updateProductRelationships: (attributeName: string, value: any) => void
  updateTrackRelationships: (attributeName: string, value: any) => void
  errorText: string | undefined
  setErrorText: (text: string | undefined) => void
  applyArtistToAll: (key: string, values: any) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  onSearchArtist: (search: string) => void
  artistOptions: SelectOption[]
  options: AssetRegistryOptionsType
  residualForm: ResidualFormProps
  setResidualForm: (residualForm: ResidualFormProps) => void
  selectedNavigationTab: ReleaseRegistryPages
  setSelectedNavigationTab: (tab: ReleaseRegistryPages) => void
  createTrack: () => void
  saveTrackPrimitive: (track: LCReleaseFormData) => void
  saveTrackAndOffers: () => Promise<boolean>
  selectedTrack?: LCReleaseFormData
  setSelectedTrack: (selectedTrack?: LCReleaseFormData) => void
  selectedTrackOffer?: LCReleaseFormData
  setSelectedTrackOffer: (selectedTrackOffer?: LCReleaseFormData) => void
  onSelectTrack: (track: LCReleaseFormData) => Promise<void>
  updateTrack: (attributeName: string, value: any) => void
  updateTrackMultiple: (attributeName: string, value: any, attributeName2: string, value2: any) => void
  deleteTracks: (id: string, isrc: string) => void
  includedContributors: string[]
  setIncludedContributors: (includedContributors: string[]) => void
  trackShareHolderList: AssetRegistryTrackShareHolder[]
  setTrackShareHolderList: (tshl: AssetRegistryTrackShareHolder[]) => void
  publishingShareHolderList: AssetRegistryPublishingShareHolder[]
  setPublishingShareHolderList: (tshl: AssetRegistryPublishingShareHolder[]) => void
  trackRegistryConsentFileList: TrackRegistryConsentFile[]
  setTrackRegistryConsentFileList: (trackRegistryConsentFileList: TrackRegistryConsentFile[]) => void
  rotorAccessToken?: string
  setRotorAccessToken: (rotorAccessToken?: string) => void
  trackShareHolderRoleOptions: KSelectOption[]
  lyricsWidget: any
  processingTrackIds: string[]
  setProcessingTrackIds: (trackIds: string[]) => void
  audioSrcMap: {}
  spotifyEditorialPitching: SpotifyEditorialPitching
  setSpotifyEditorialPitching: (sep: SpotifyEditorialPitching) => void
  redirectedFromSummary: boolean
  setRedirectedFromSummary: (redirected: boolean) => void
  appleDigitalMaster: string
  setAppleDigitalMaster: (adm: string) => void
  selectedLabelName: string
  setSelectedLabelName: (selectedLabelName: string) => void
  saveProductForm: () => Promise<boolean>
  isPublishing: boolean
  summaryPublishingText: string
  bmwCustomIdKeyword: string
  distributedRights: any[]
  setDistributedRights: (distributedRight: any[]) => void
}

const ReleaseRegistryOrchestratorContext = createContext<ReleaseRegistryOrchestrator>(null)

export const useOrchestrator = () => useContext(ReleaseRegistryOrchestratorContext)

export const ReleaseRegistryOrchestratorProvider: React.FC<ReleaseRegistryOrchestratorProviderProps> = (props) => {
  const [releaseForm, setReleaseForm] = useState<LCReleaseForm>(props.data)
  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [artistOptions, setArtistOptions] = useState<SelectOption[]>([])
  const [timeoutId, setTimeoutId] = useState(null)
  const [selectedArtistList, setSelectedArtistList] = useState<string[]>([])
  const [residualForm, setResidualForm] = useState<ResidualFormProps>(props.residualData)
  const [selectedTrack, setSelectedTrack] = useState<LCReleaseFormData>()
  const [selectedTrackOffer, setSelectedTrackOffer] = useState<LCReleaseFormData>()
  const [trackShareHolderList, setTrackShareHolderList] = useState<AssetRegistryTrackShareHolder[]>([])
  const [trackShareHolderRoleOptions, setTrackShareHolderRoleOptions] = useState<KSelectOption[]>([])
  const [audioSrcMap, setAudioSrcMap] = useState({})
  const [activeImportTaskIdList, setActiveImportTaskIdList] = useState<string[]>([])
  const [processingTrackIds, setProcessingTrackIds] = useState<string[]>([])
  const [processingIntervalId, setProcessingIntervalId] = useState<NodeJS.Timer>()
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>()
  const [redirectedFromSummary, setRedirectedFromSummary] = useState<boolean>(false)
  const [appleDigitalMaster, setAppleDigitalMaster] = useState<string>(props.appleDigitalMaster)
  const [selectedLabelName, setSelectedLabelName] = useState<string>(
    props.options.labelOptions?.find((label) => label.value === releaseForm.product.data.relationships.label?.data?.id)
      ?.label || ""
  )
  const [publishingShareHolderList, setPublishingShareHolderList] = useState<AssetRegistryPublishingShareHolder[]>([])
  const [distributedRights, setDistributedRights] = useState<any[]>([])
  const [trackRegistryConsentFileList, setTrackRegistryConsentFileList] = useState<TrackRegistryConsentFile[]>([])
  const [rotorAccessToken, setRotorAccessToken] = useState<string>()

  const navigate = useNavigate()

  useEffect(() => {
    if (props.data.product.data.id) {
      const releaseFormData = props.data
      const processingTasks =
        [...releaseFormData.import_tasks?.filter((task) => task.data?.attributes?.status === "processing")] || []

      checkProcessingTaskExist(processingTasks)

      const completedTasks =
        [...releaseFormData.import_tasks?.filter((task) => task.data?.attributes?.status === "completed")] || []

      const audioSrcMap = completedTasks.reduce((acc, task) => {
        const key = task.data?.relationships?.track?.data?.id || "-"
        const value = task.data?.attributes?.url || "-"
        acc[key] = value
        return acc
      }, {})
      setAudioSrcMap(audioSrcMap)
    }

    setReleaseForm(props.data)
  }, [props.data])

  const processingTaskInterval = async () => {
    const newIntervalId = setInterval(async () => {
      releases(`/release/label_camp_upload_track_audio_status/?track_id_list=${processingTrackIds}`, "get").then(
        (resp) => {
          let isUpdatedTask = false
          let isUpdatedTrack = false
          let newProcessingTrackIds = [...processingTrackIds]
          const newImportTaskIdList = [...activeImportTaskIdList]
          for (let i = 0; i < resp.data.length; i++) {
            const element = resp.data[i]
            if (element.status === "success" && !newImportTaskIdList.includes(element.import_task_id)) {
              newImportTaskIdList.push(element.import_task_id)
              isUpdatedTask = true
            }
            if (element.status === "failed") {
              newProcessingTrackIds = [...newProcessingTrackIds.filter((id) => id !== element.track_id)]
              isUpdatedTrack = true
              setErrorText(`Upload failed for track: ${element.track_id}`)
            }
          }

          if (isUpdatedTask) {
            setActiveImportTaskIdList([...newImportTaskIdList])
          }
          if (isUpdatedTrack) {
            setProcessingTrackIds([...newProcessingTrackIds])
          }
        }
      )
    }, 30000)
    setProcessingIntervalId(newIntervalId)
  }

  const importTaskInterval = async () => {
    const newIntervalId = setInterval(async () => {
      let newTaskIdList = [...activeImportTaskIdList]
      let newProcessingTrackIds = [...processingTrackIds]
      for (let i = 0; i < activeImportTaskIdList.length; i++) {
        await releases(`/release/label_camp_import_task/?import_task_id=${activeImportTaskIdList[i]}`, "get").then(
          (resp) => {
            try {
              const data = resp.data.result.data
              const trackId = data.relationships?.track?.data?.id || ""
              if (data.attributes.status === "error") {
                const foundTrack = releaseForm.tracks.find(
                  (track) => track.data.id === data.relationships.track.data.id
                )
                setErrorText(
                  `Audio Upload Failed for track: ${foundTrack?.data?.attributes?.title}\n\n ${data.attributes.message}`
                )
                newProcessingTrackIds = [...newProcessingTrackIds.filter((id) => id !== trackId)]
                newTaskIdList = [...newTaskIdList.filter((id) => id !== data.id)]
              } else if (data.attributes.status === "completed") {
                const updatedTracks = releaseForm.tracks.map((track: LCReleaseFormData) => {
                  if (track.data.id === data.relationships.track.data.id) {
                    toast.success(
                      `Audio Uploaded Successfully for track: ${track.data.attributes.title}`,
                      successFilledToastOptions
                    )
                    return {
                      ...track,
                      data: {
                        ...track.data,
                        attributes: {
                          ...track.data.attributes,
                          "preview-url": data.attributes.url,
                          audio16bits: data.attributes.precision === "16",
                          audio24bits: data.attributes.precision === "24",
                          "audio-dolby-atmos": data.attributes.precision === "dolby_atmos"
                        }
                      }
                    }
                  }
                  return track
                })
                setReleaseForm({
                  ...releaseForm,
                  tracks: [...updatedTracks]
                })
                newProcessingTrackIds = [...newProcessingTrackIds.filter((id) => id !== trackId)]
                newTaskIdList = [...newTaskIdList.filter((id) => id !== data.id)]
              }
            } catch (e) {
              // Do Nothing
            }
          }
        )
      }
      setActiveImportTaskIdList(newTaskIdList)
      setProcessingTrackIds(newProcessingTrackIds)
    }, 25000)
    setIntervalId(newIntervalId)
  }

  useEffect(() => {
    releases(`/role/`, "get").then((resp) => {
      setTrackShareHolderRoleOptions(
        resp.data.results.map((role) => {
          return { label: role.name, value: role.id }
        })
      )
    })
  }, [])

  useEffect(() => {
    clearInterval(processingIntervalId)
    if (processingTrackIds.length === 0) {
      return
    }
    processingTaskInterval()
  }, [processingTrackIds])

  useEffect(() => {
    clearInterval(intervalId)
    if (activeImportTaskIdList.length === 0) {
      return
    }
    importTaskInterval()
  }, [activeImportTaskIdList])

  const checkProcessingTaskExist = (processingTasks) => {
    const track_id_list = releaseForm.tracks.map((track) => track.data.id)
    releases(`/release/label_camp_upload_track_audio_status/?track_id_list=${track_id_list}`, "get").then((resp) => {
      let trackIds: string[] = []
      if (processingTasks.length > 0) {
        trackIds = processingTasks.map((task) => task.data.relationships?.track?.data?.id)

        const taskIds = processingTasks.map((task) => task.data.id || "-")
        setActiveImportTaskIdList([...taskIds])
      }

      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i]
        if (element.status === "in_progress") {
          trackIds.push(element.track_id)
        }
      }

      setProcessingTrackIds([...trackIds])
    })
  }

  const updateProduct = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      product: {
        ...releaseForm.product,
        data: {
          ...releaseForm.product.data,
          attributes: {
            ...releaseForm.product.data.attributes,
            [attributeName]: value
          }
        }
      }
    })
  }
  const [includedContributors, setIncludedContributors] = useState<string[]>([]) //for track contributors and artists only

  const updateOffer = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      offer: {
        ...releaseForm.offer,
        data: {
          ...releaseForm.offer.data,
          attributes: {
            ...releaseForm.offer.data.attributes,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateOfferRelationships = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      offer: {
        ...releaseForm.offer,
        data: {
          ...releaseForm.offer.data,
          relationships: {
            ...releaseForm.offer.data.relationships,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateProductRelationships = (attributeName: string, value: any) => {
    setReleaseForm({
      ...releaseForm,
      product: {
        ...releaseForm.product,
        data: {
          ...releaseForm.product.data,
          relationships: {
            ...releaseForm.product.data.relationships,
            [attributeName]: value
          }
        }
      }
    })
  }

  const updateTrackRelationships = (attributeName: string, value: any) => {
    if (!selectedTrack) {
      return
    }

    setSelectedTrack({
      ...selectedTrack,
      data: {
        ...selectedTrack.data,
        relationships: {
          ...selectedTrack.data.relationships,
          [attributeName]: value
        }
      }
    })
  }

  const saveTrackPrimitive = (trackToUpdate: LCReleaseFormData) => {
    const updatedTracks = releaseForm.tracks.map((track: LCReleaseFormData) => {
      if (track.data.id === trackToUpdate.data.id) {
        const trackRelationships = {}
        Object.entries(trackToUpdate.data.relationships).forEach(([key, value]) => {
          if (value["data"] && !notAllowedTrackRelationships.includes(key)) {
            trackRelationships[key] = { data: value["data"] }
          }
        })
        const trackAttributes = {}
        Object.entries(trackToUpdate.data.attributes).forEach(([key, value]) => {
          if (value && !notAllowedTrackAttributes.includes(key)) trackAttributes[key] = value
        })

        const requestBody = {
          track: {
            data: {
              id: trackToUpdate.data.id,
              relationships: trackRelationships,
              attributes: trackAttributes,
              type: "tracks"
            }
          }
        }
        releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
          if (!resp.data.success) {
            setErrorText(JSON.stringify(resp.data.result))
          }
        })
        return trackToUpdate
      }
      return track
    })

    setReleaseForm({
      ...releaseForm,
      tracks: updatedTracks
    })
  }

  const onSelectTrackResponse = (trackOffers, track_offer, track) => {
    trackOffers.push(track_offer)

    setSelectedTrack(track)
    setSelectedTrackOffer(track_offer)
    setReleaseForm({
      ...releaseForm,
      track_offers: [...trackOffers]
    })
  }

  const onSelectTrack = async (track: LCReleaseFormData) => {
    if (releaseForm.product.data.relationships["product-type"]?.data?.id === "2") {
      // Selected Single
      track.data.attributes.title = track?.data?.attributes?.title || releaseForm.product.data.attributes.name
      track.data.attributes.subtitle = track?.data?.attributes?.subtitle || releaseForm.product.data.attributes.subtitle
    }

    if (!track?.data.attributes["production-line"]) {
      track.data.attributes["production-line"] = releaseForm.product.data.attributes["production-line"]
    }

    releases(`/track-share-holder-invitation/list_by_isrc/?isrc=${track.data.attributes["isrc-code"]}`, "get").then(
      (resp) => {
        if (resp.data) {
          setTrackShareHolderList(resp.data)
        }
      }
    )

    releases(`/publishing-share-holder/list_by_isrc/?isrc=${track.data.attributes["isrc-code"]}`, "get").then(
      (resp) => {
        if (resp.data) {
          setPublishingShareHolderList(resp.data)
        }
      }
    )

    releases(`/track-registry-consent-file/list_by_isrc/?isrc=${track.data.attributes["isrc-code"]}`, "get").then(
      (resp) => {
        if (resp.data) {
          setTrackRegistryConsentFileList(resp.data)
        }
      }
    )

    const existedTrackOffer = releaseForm.track_offers?.find(
      (offer) => offer.data?.relationships?.track?.data?.id === track.data.id
    )
    if (existedTrackOffer) {
      setSelectedTrack(track)
      setSelectedTrackOffer(existedTrackOffer)
      return
    }

    setLoading(true)

    const trackOfferResponse = await releases(`/release/label_camp_track_offer/?track_id=${track.data.id}`, "get")

    const trackOffers = [...releaseForm.track_offers]
    let track_offer: LCReleaseFormData = trackOfferResponse.data.result.track_offer
    if (!track_offer || !track_offer.data) {
      track_offer = {
        data: {
          id: undefined,
          attributes: {
            usage: { download: true, on_demand_stream: true, non_interactive_stream: true, copy_scan: true }
          },
          relationships: {
            track: { data: { type: "tracks", id: track.data.id } },
            offer: { data: { type: "offers", id: releaseForm.offer.data.id } },
            "price-code": { data: { type: "distributor-price-codes", id: "35136" } },
            genre: {
              data: {
                type: "distributor-product-subgenres",
                id: releaseForm.offer.data.relationships["subgenre"]?.data?.id
              }
            }
          },
          type: "track-offers"
        }
      }

      const requestBody = {
        track: null,
        offer: {
          ...track_offer
        }
      }
      const trackCreateResponse = await releases(`/release/label_camp_track_create/`, "post", requestBody)
      const track_offers = [...releaseForm.track_offers]
      track_offers.push(trackCreateResponse.data.result.offer)
      setReleaseForm({ ...releaseForm, track_offers: [...track_offers] })

      onSelectTrackResponse(trackOffers, trackCreateResponse.data.result.offer, track)

      setLoading(false)
    } else {
      onSelectTrackResponse(trackOffers, track_offer, track)
      setLoading(false)
    }
  }

  const deleteTracks = async (id: string, isrcCode: string) => {
    setLoading(true)

    await releases(`/release/label_camp_track_delete/?track_id=${id}&isrc=${isrcCode}`, "delete")

    let trackNumber = 1
    for (const track of releaseForm.tracks) {
      if (track.data.id !== id) {
        track.data.attributes["track-number"] = trackNumber
        saveTrackPrimitive(track)
        await delay(2000) // Wait for 2 seconds
        trackNumber++
      }
    }

    setReleaseForm({
      ...releaseForm,
      tracks: releaseForm.tracks.filter((track) => track.data.id !== id)
    })
    setLoading(false)
  }

  const updateTrack = (attributeName: string, value: any) => {
    if (!selectedTrack) {
      return
    }

    setSelectedTrack({
      ...selectedTrack,
      data: {
        ...selectedTrack.data,
        attributes: {
          ...selectedTrack.data.attributes,
          [attributeName]: value
        }
      }
    })
  }

  const updateTrackMultiple = (attributeName: string, value: any, attributeName2: string, value2: any) => {
    if (!selectedTrack) {
      return
    }

    setSelectedTrack({
      ...selectedTrack,
      data: {
        ...selectedTrack.data,
        attributes: {
          ...selectedTrack.data.attributes,
          [attributeName]: value,
          [attributeName2]: value2
        }
      }
    })
  }

  const validatePublishingContributors = (selectedTrack: LCReleaseFormData, key: string, label: string) => {
    if (!(selectedTrack.data.attributes[key]?.length > 0)) {
      setErrorText(`Mandatory field: ${label}`)
      return false
    }
    if (selectedTrack.data.attributes[key]?.length > 4) {
      setErrorText(`You cannot add more than 4 ${label}`)
      return false
    }

    return true
  }

  const saveTrackAndOffers = async () => {
    if (!selectedTrack || !selectedTrackOffer) {
      return false
    }

    if (props.isPublishing) {
      if (!validatePublishingContributors(selectedTrack, "composer", "Composer")) return false
      if (!validatePublishingContributors(selectedTrack, "lyricist", "Lyricist")) return false
      if (!(selectedTrack.data.attributes["arranger"]?.length > 0)) {
        toast.warning(
          "Saved without arranger. Are you sure you want to continue without arranger?",
          warningFilledToastOptions
        )
      }
    }

    setLoading(true)

    const updatedTracks = releaseForm.tracks.map((track: LCReleaseFormData) => {
      if (track.data.id === selectedTrack.data.id) {
        const trackRelationships = {}
        Object.entries(selectedTrack.data.relationships).forEach(([key, value]) => {
          if (value["data"] && !notAllowedTrackRelationships.includes(key)) {
            trackRelationships[key] = { data: value["data"] }
          }
        })
        const trackAttributes = {}
        Object.entries(selectedTrack.data.attributes).forEach(([key, value]) => {
          if (key === "parental-advisory" || key === "clean") {
            trackAttributes[key] = value || false
          } else if (value && !notAllowedTrackAttributes.includes(key)) {
            trackAttributes[key] = value
          }
        })

        const offerRelationships = { offer: { data: { type: "offers", id: "4306163" } } }
        Object.entries(selectedTrackOffer.data.relationships).forEach(([key, value]) => {
          if (value["data"] && !notAllowedOfferRelationships.includes(key)) {
            offerRelationships[key] = { data: value["data"] }
          }
        })
        const offerAttributes = {}
        Object.entries(selectedTrackOffer.data.attributes).forEach(([key, value]) => {
          if (value && !notAllowedOfferAttributes.includes(key)) offerAttributes[key] = value
        })
        const requestBody = {
          track: {
            data: {
              id: selectedTrack.data.id,
              relationships: trackRelationships,
              attributes: trackAttributes,
              type: "tracks"
            }
          },
          offer: {
            data: {
              id: selectedTrackOffer.data.id,
              relationships: offerRelationships,
              attributes: offerAttributes,
              type: "track-offers"
            }
          }
        }
        setLoading(true)
        releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
          setLoading(false)
          if (!resp.data.success) {
            setErrorText(JSON.stringify(resp.data.result))
          }
        })
        return selectedTrack
      }
      return track
    })

    const updatedTrackOffers = releaseForm.track_offers.map((trackOffer: LCReleaseFormData) => {
      if (trackOffer.data?.relationships?.track?.data?.id === selectedTrack.data.id) {
        return selectedTrackOffer
      }
      return trackOffer
    })
    setReleaseForm({
      ...releaseForm,
      tracks: updatedTracks,
      track_offers: updatedTrackOffers
    })
    setSelectedTrack(undefined)
    setTrackShareHolderList([])
    setPublishingShareHolderList([])
    setTrackRegistryConsentFileList([])
    setRotorAccessToken(undefined)
    return true
  }

  const createTrack = () => {
    setLoading(true)
    const trackNumber = releaseForm.tracks?.length || 0
    const requestBody = {
      track: {
        data: {
          type: "tracks",
          attributes: { "volume-number": 1, "track-number": trackNumber + 1 },
          relationships: { product: { data: { id: releaseForm.product.data.id, type: "products" } } }
        }
      }
    }
    releases(`/release/label_camp_track_create/`, "post", requestBody).then((resp) => {
      setLoading(false)
      if (!resp.data.success) {
        setErrorText(resp.data.result)
        return
      }
      const tracks = [...releaseForm.tracks]
      tracks.push(resp.data.result.track)
      setReleaseForm({ ...releaseForm, tracks: [...tracks] })
    })
  }

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const applyArtistToAll = async (key: string, values: any) => {
    setLoading(true)
    for (const track of releaseForm.tracks) {
      track.data.attributes[key] = values
      saveTrackPrimitive(track)
      await delay(2000) // Wait for 2 seconds
    }
    toast.success(`Successfully updated all tracks`, successFilledToastOptions)
    setLoading(false)
  }

  const onSearchArtist = (search: string) => {
    if (!search) {
      return
    }

    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setTimeoutId(
      // @ts-ignore
      setTimeout(async () => {
        const existedArtistOptions = [...new Set(selectedArtistList)].map((artist) => {
          return { value: artist, label: artist }
        })

        releases(`/artist/search/?value=${search}`, "get")
          .then((resp) => {
            if (resp && resp.data) {
              const options = resp.data.map((option) => {
                return { value: option, label: option }
              })
              setArtistOptions([{ value: search, label: "+ Add New" }, ...options, ...existedArtistOptions])
            } else {
              setArtistOptions([{ value: search, label: "+ Add New" }, ...existedArtistOptions])
            }
          })
          .catch(() => {
            setArtistOptions([{ value: search, label: "+ Add New" }, ...existedArtistOptions])
          })
      }, 1000)
    )
  }

  const processCatalogNoString = (str: string) => {
    let catalogNo = str.replace(/[çÇğĞıİöÖşŞüÜ]/g, (match) => turkishCharMap[match])
    catalogNo = catalogNo.replace(/[aeiouAEIOU ]/g, "").toUpperCase()
    while (catalogNo.length < 8) {
      catalogNo += "0"
    }
    const labelId = releaseForm.product.data.relationships.label?.data?.id

    catalogNo = catalogNo.slice(0, 8)

    return `${catalogNo}${labelId}`
  }

  const saveProductForm = async () => {
    const productTypeId = releaseForm.product.data.relationships["product-type"]?.data?.id
    if (!productTypeId) {
      setErrorText("Please select product type")
      return false
    }

    setLoading(true)
    const productRelationships = {}
    Object.entries(releaseForm.product.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedProductRelationships.includes(key)) {
        productRelationships[key] = { data: value["data"] }
      }
    })
    const productAttributes = {}
    Object.entries(releaseForm.product.data.attributes).forEach(([key, value]) => {
      if (value && !notAllowedProductAttributes.includes(key)) productAttributes[key] = value
    })
    const offerRelationships = {}
    Object.entries(releaseForm.offer.data.relationships).forEach(([key, value]) => {
      if (value["data"] && !notAllowedOfferRelationships.includes(key)) {
        offerRelationships[key] = { data: value["data"] }
      }
    })
    const offerAttributes = {}
    Object.entries(releaseForm.offer.data.attributes).forEach(([key, value]) => {
      if (value && !notAllowedOfferAttributes.includes(key)) offerAttributes[key] = value
    })

    const labelInternalReference = processCatalogNoString(productAttributes["name"])
    const trackPriceRelationship = { data: { type: "distributor-price-codes", id: "35136" } }

    let distributorPriceId = ""
    if (productTypeId === "1") distributorPriceId = "35130" // Album => BA - $5.99 USD
    else if (productTypeId === "2") distributorPriceId = "35126" // Single => S1 - $0.99 USD
    else if (productTypeId === "8") distributorPriceId = "35127" // EP => BEP - $1.99 USD

    const distributorPriceRelationship = { data: { type: "distributor-price-codes", id: distributorPriceId } }

    const filteredReleaseForm = {
      label_name: selectedLabelName,
      product: {
        data: {
          id: releaseForm.product.data.id,
          attributes: {
            ...productAttributes,
            "label-internal-reference": labelInternalReference,
            "is-compilation": false
          },
          relationships: productRelationships,
          type: "products"
        }
      },
      offer: {
        data: {
          id: releaseForm.offer.data.id,
          attributes: offerAttributes,
          relationships: {
            ...offerRelationships,
            "track-price-code": trackPriceRelationship,
            "price-code": distributorPriceRelationship
          },
          type: "offers"
        }
      }
    }

    if (appleDigitalMaster) {
      filteredReleaseForm["apple_digital_master"] = appleDigitalMaster
    }

    try {
      const productResponse = await releases(`/release/label_camp_product_save/`, "post", { ...filteredReleaseForm })
      if (!releaseForm.product.data.id && productResponse.data.release_id) {
        navigate(`/catalog/release-registry/${hashids.encode(productResponse.data.release_id.toString() || "")}`)
      }

      if (!productResponse.data.success) {
        if (productResponse.data.upc && productResponse.data.product_id) {
          setReleaseForm({
            ...releaseForm,
            product: {
              ...releaseForm.product,
              data: {
                ...releaseForm.product.data,
                id: productResponse.data.product_id,
                attributes: {
                  ...releaseForm.product.data.attributes,
                  "upc-code": productResponse.data.upc
                }
              }
            }
          })
        }
        setErrorText(`${JSON.stringify(productResponse.data.result)}`)
        setLoading(false)
        return false
      }
      const data = productResponse.data.result
      setReleaseForm({
        ...releaseForm,
        product: data.product,
        offer: data.offer
      })
      // updateArtistAndContributors(data.product.data.attributes)
      setLoading(false)
      return true
    } catch (e) {
      console.error("Error saving product")
      setErrorText(e)
      // toast.error(`Error Saving Product`, errorFilledToastOptions)
      setLoading(false)
      return false
    }
  }

  const orchestratorValue: ReleaseRegistryOrchestrator = {
    releaseForm,
    setReleaseForm,
    updateProduct,
    updateOffer,
    updateOfferRelationships,
    updateProductRelationships,
    updateTrackRelationships,
    errorText,
    setErrorText,
    applyArtistToAll,
    loading,
    setLoading,
    onSearchArtist,
    artistOptions,
    options: props.options,
    residualForm,
    setResidualForm,
    selectedNavigationTab: props.selectedTab,
    setSelectedNavigationTab: props.setSelectedTab,
    saveTrackPrimitive,
    createTrack,
    saveTrackAndOffers,
    selectedTrack,
    setSelectedTrack,
    selectedTrackOffer,
    setSelectedTrackOffer,
    onSelectTrack,
    updateTrack,
    updateTrackMultiple,
    deleteTracks,
    includedContributors,
    setIncludedContributors,
    trackShareHolderList,
    setTrackShareHolderList,
    publishingShareHolderList,
    rotorAccessToken,
    setRotorAccessToken,
    setPublishingShareHolderList,
    trackRegistryConsentFileList,
    setTrackRegistryConsentFileList,
    trackShareHolderRoleOptions,
    lyricsWidget: props.lyricsWidget,
    processingTrackIds,
    audioSrcMap,
    spotifyEditorialPitching: props.spotifyEditorialPitching,
    setSpotifyEditorialPitching: props.setSpotifyEditorialPitching,
    redirectedFromSummary,
    setRedirectedFromSummary,
    appleDigitalMaster,
    setAppleDigitalMaster,
    setProcessingTrackIds,
    selectedLabelName,
    setSelectedLabelName,
    saveProductForm,
    isPublishing: props.isPublishing,
    summaryPublishingText: props.summaryPublishingText,
    bmwCustomIdKeyword: props.bmwCustomIdKeyword,
    distributedRights,
    setDistributedRights
  }

  return (
    <ReleaseRegistryOrchestratorContext.Provider value={orchestratorValue}>
      {props.children}
    </ReleaseRegistryOrchestratorContext.Provider>
  )
}
