import "../styles/Login.css"
import React, { FC, useEffect, useState } from "react"
import LoginForm from "./LoginForm"
import LoginLayout from "../LoginLayout"
import { connect } from "react-redux"
import { LoginFormValues, LoginPageProps } from "../../../../types"
import { useNavigate } from "react-router-dom"
import { DEFAULT_ROUTE } from "../../../../constants"
import { emailValidation, login, validateCode } from "../../../../actions/loginActions"
import { KButton, KCodeInput, KInput, KSpan, KTitleSpan } from "kahuna-base-react-components"
//@ts-ignore
import EmailIcon from "../../../ui/img/auth/e-mail.svg"
//@ts-ignore
import PasswordIcon from "../../../ui/img/auth/password.svg"
//@ts-ignore
import EyeIcon from "../../../ui/img/auth/eye.svg"
//@ts-ignore
import EyeSlashIcon from "../../../ui/img/auth/eye-slash.svg"
import { lang } from "../../../../constants/languages"
import axios from "axios"

enum LOGIN_STEPS {
  PASSWORD,
  CODE_VALIDATION
}

const Login: FC<LoginPageProps> = (props) => {
  const [form, setForm] = useState<LoginFormValues>({ username: "", password: "" })
  const [showPassword, setShowPassword] = useState(false)
  const [step, setStep] = useState(LOGIN_STEPS.PASSWORD)
  const [time, setTime] = useState(0)
  const [code, setCode] = useState("")

  const { login } = props
  const history = useNavigate()

  const handleSubmit = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")

    props.emailValidation(
      { email: form.username, username: form.username, password: form.password, ipAddress: res.data.ip },
      true,
      true,
      "Code Validation",
      (overrideCodeValidation: boolean) => {
        if (overrideCodeValidation) {
          login(form, () => history(DEFAULT_ROUTE))
        } else {
          setStep(LOGIN_STEPS.CODE_VALIDATION)
          setTime(180)
        }
      }
    )
  }

  useEffect(() => {
    if (code.length === 6) {
      props.validateCode({ email: form.username, code }, () => {
        login(form, () => history(DEFAULT_ROUTE))
      })
    }
  }, [code])

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [time])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`
  }

  const renderCodeValidation = () => {
    return (
      <div className={"grid align-middle justify-center items-center"} style={{ width: "100%", height: "90%" }}>
        <div className={"grid"} style={{ width: "500px" }}>
          <div className={"flex"}>
            <KTitleSpan text={lang.login.title} />
          </div>
          <div className="mb-8 mt-4">
            <KSpan text={lang.reset_password.code_placeholder} />
          </div>
          <KCodeInput onChange={(text: string) => setCode(text)} autoBlur={true} fitInContainer height={64} />
          <div className="flex justify-center items-center h-11">
            <KSpan text={formatTime(time)} lineHeight="14px" />
          </div>
        </div>
      </div>
    )
  }

  const renderAuthentication = () => {
    return (
      <div className={"grid align-middle justify-center items-center"} style={{ width: "100%", height: "90%" }}>
        <div className={"grid"}>
          <div className={"flex"}>
            <KTitleSpan text={lang.login.title} />
          </div>
          <div className={"flex mt-4"} style={{ width: 500 }}>
            <KSpan text={lang.login.desc} />
          </div>
          <div className={"mt-8"}>
            <KInput
              value={form.username || ""}
              onChange={(text) => setForm({ ...form, username: text })}
              placeholder={lang.login.username_placeholder}
              leftIcon={EmailIcon}
            />
          </div>
          <div className={"mt-4"}>
            <KInput
              value={form.password || ""}
              onChange={(text) => setForm({ ...form, password: text })}
              type={showPassword ? "text" : "password"}
              placeholder={lang.login.password_placeholder}
              leftIcon={PasswordIcon}
              rightIconClick={() => setShowPassword(!showPassword)}
              rightIcon={showPassword ? EyeIcon : EyeSlashIcon}
            />
          </div>
          <div className={"mt-4"}>
            <KButton
              onClick={() => handleSubmit()}
              text={lang.login.sign_in_button}
              background="black"
              textColor="white"
            />
            <div className="mt-2">
              <KButton
                shadowDisabled={true}
                background="transparent"
                onClick={() => history(`/forgot-password?email=${form.username}`)}
                text={lang.login.forgot_password_button}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <LoginLayout>
      <div style={{ height: "100%" }}>
        {step === LOGIN_STEPS.PASSWORD && renderAuthentication()}
        {step === LOGIN_STEPS.CODE_VALIDATION && renderCodeValidation()}
      </div>
    </LoginLayout>
  )
}

export default connect(null, { login, emailValidation, validateCode })(Login)
