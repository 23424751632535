import { Dialog } from "@headlessui/react"
import { KTitleSpan, KSpan, KButton, KInput } from "kahuna-base-react-components"
import React, { useEffect, useState } from "react"
import { LCReleaseForm, Release, SpotifyEditorialPitching } from "../../../../../types"
import { releases } from "../../../../../apis"
import { errorFilledToastOptions, NEW_RELEASE_TYPES, successFilledToastOptions } from "../../../../../constants"
import AudioPlayer from "../../../catalog/track-detail/AudioPlayer"
import Loading from "../../../../ui/Loading"
import {
  buildBMVTrackList,
  buildMesamMsgTrackList,
  buildState51TrackList
} from "../../../../../utility/newReleaseHelpler"
import { toast } from "react-toastify"
import { mapToCamelCase } from "../../../../../utility"

const initReleaseForm: LCReleaseForm = {
  product: {
    data: {
      id: undefined,
      attributes: {
        artist: []
      },
      relationships: {},
      type: "products"
    }
  },
  offer: {
    data: {
      id: undefined,
      attributes: {},
      relationships: {
        product: {}
      },
      type: "offers"
    }
  },
  tracks: [],
  import_tasks: [],
  import_sub_tasks: [],
  records: [],
  track_offers: [],
  logs: []
}

const ForceUploadModal = ({ release, setRelease }: { release?: Release; setRelease: (release?: Release) => void }) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<any>()
  const [releaseForm, setReleaseForm] = useState<LCReleaseForm>(initReleaseForm)
  const [playingAudioRefs, setPlayingAudioRefs] = useState<HTMLAudioElement[]>([])
  const [userPermission, setUserPermission] = useState("")
  const [publishingShare, setPublishingShare] = useState("")
  const [state51BatchId, setState51BatchId] = useState("")
  const [bmvCustomIdKeyword, setBmvCustomIdKeyword] = useState("")
  const [spotifyEditorialPitching, setSpotifyEditorialPitching] = useState<SpotifyEditorialPitching>({
    genre: [],
    mood: [],
    style: [],
    instrumentation: [],
    description: ""
  })

  const fetchProductAndOptions = async () => {
    if (!release) {
      return
    }
    setLoading(true)

    if (!options) {
      const optionsResponse = await releases(`/release/label_camp_options/?force_upload_user_id=0`, "get")

      const labelOptions = optionsResponse.data["label_options"]
      const productTypeOptions = optionsResponse.data["product_type_options"]
      const languageOptions = optionsResponse.data["language_options"]
      const metadataLanguageOptions = optionsResponse.data["metadata_language_options"]
      const productGenreOptions = optionsResponse.data["product_genre_options"]
      const distributorProductSubGenreOptions = optionsResponse.data["distributor_product_sub_genre_options"]
      const distributorPriceCodeOptions = optionsResponse.data["distributor_price_code_options"]
      const distributorTrackPriceOptions = optionsResponse.data["distributor_track_price_options"]
      const roleOptions = optionsResponse.data["roles"]

      setOptions({
        labelOptions,
        productTypeOptions,
        languageOptions,
        metadataLanguageOptions,
        productGenreOptions,
        distributorProductSubGenreOptions,
        distributorPriceCodeOptions,
        distributorTrackPriceOptions,
        roleOptions
      })
    }

    const productResponse = await releases(
      `/release/label_camp_product_get/?product_id=${release.productId}&force_upload_user_id=0`,
      "get"
    )
    if (productResponse.data.result) {
      setReleaseForm({ ...productResponse.data.result })
    }

    setLoading(false)
  }

  useEffect(() => {
    const productTypeList = [NEW_RELEASE_TYPES.BMV, NEW_RELEASE_TYPES.MESAM_MSG, NEW_RELEASE_TYPES.STATE_51]
    if (productTypeList.includes(release?.forceUploadType || "-")) {
      fetchProductAndOptions()
    }
    if (release?.forceUploadType === NEW_RELEASE_TYPES.EDITORIAL_PITCHING) {
      setLoading(true)
      releases(`/release/list_spotify_editorial_pitching/?release_id=${release.id}`, "get").then((resp) => {
        if (resp.data.result) {
          setSpotifyEditorialPitching(mapToCamelCase(resp.data.result))
        }
        setLoading(false)
      })
    }
  }, [release])

  const handleSubmit = () => {
    const trackIdList = releaseForm.tracks.map((track) => track.data.id)
    const filteredReleaseForm = { upc: release?.upc, type: release?.forceUploadType, track_id_list: trackIdList }
    if (release?.forceUploadType === NEW_RELEASE_TYPES.BMV) {
      filteredReleaseForm["bmv_track_list"] = buildBMVTrackList(releaseForm, options, bmvCustomIdKeyword)
    } else if (release?.forceUploadType === NEW_RELEASE_TYPES.STATE_51) {
      filteredReleaseForm["state_51_form"] = buildState51TrackList(releaseForm, options)
      filteredReleaseForm["state_51_batch_id"] = state51BatchId
    } else if (release?.forceUploadType === NEW_RELEASE_TYPES.MESAM_MSG) {
      filteredReleaseForm["mesam_msg_track_list"] = buildMesamMsgTrackList(releaseForm, options)
      filteredReleaseForm["user_permission"] = userPermission
      filteredReleaseForm["publishing_share"] = publishingShare
    }

    try {
      setLoading(true)
      releases(`/release/new_release_force_upload/`, "post", { ...filteredReleaseForm })
        .then((resp) => {
          setLoading(false)
          if (resp.data.success) {
            toast.success("Successfully Uploaded. Refresh The Page to see result!", successFilledToastOptions)
            setRelease(undefined)
          } else {
            toast.error("Error!", errorFilledToastOptions)
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error("Error!", errorFilledToastOptions)
        })
    } catch (e) {
      setLoading(false)
      toast.error("Error!", errorFilledToastOptions)
    }
  }

  const checkIfSubmitDisabled = () => {
    if (release?.forceUploadType === NEW_RELEASE_TYPES.MESAM_MSG) {
      if (!publishingShare) {
        return true
      }
      if (userPermission !== "5" && userPermission !== "6") {
        return true
      }
    } else if (release?.forceUploadType === NEW_RELEASE_TYPES.STATE_51 && !state51BatchId) {
      return true
    } else if (release?.forceUploadType === NEW_RELEASE_TYPES.BMV && !bmvCustomIdKeyword) {
      return true
    }
    return loading
  }

  const renderAuidoFileView = () => {
    return (
      <div className="flex flex-col gap-2 items-center justify-center">
        <span className="w-[96px] aspect-square rounded-[48px] shadow-md flex justify-center items-center">
          <img className="w-12 h-12" src="/account_icons/music-note-sparkle.svg"></img>
        </span>
        <KTitleSpan text={`Upload ${release?.forceUploadType}`} fontSize={24} lineHeight="32px" />
        <KSpan text="Check if audio files exist and correct!" />
        <div className="mt-8">
          {releaseForm.tracks.map((track) => {
            const attributes = track.data.attributes
            return (
              <div
                className="flex pt-1 pb-1"
                key={track.data.id}
                style={{ width: "700px", borderBottom: "1px dashed grey" }}
              >
                <div style={{ width: "50%" }}>
                  <KSpan text={attributes["title"]} />
                </div>
                <div className="flex items-center gap-1" style={{ width: "25%" }}>
                  <KSpan text="Original Song: " />
                  <AudioPlayer
                    src={attributes["preview-url"]}
                    playingAudioRefs={playingAudioRefs}
                    setPlayingAudioRefs={setPlayingAudioRefs}
                  />
                </div>
                <div className="flex items-center gap-1" style={{ width: "25%" }}>
                  <KSpan text="Will Be Upload: " />
                  <AudioPlayer
                    src={`https://royalty-application.s3.eu-central-1.amazonaws.com/audio/${track.data.id}.flac`}
                    playingAudioRefs={playingAudioRefs}
                    setPlayingAudioRefs={setPlayingAudioRefs}
                  />
                </div>
              </div>
            )
          })}
        </div>
        {release?.forceUploadType === NEW_RELEASE_TYPES.BMV && (
          <div className="mt-8">
            <KInput
              width={300}
              value={bmvCustomIdKeyword}
              onChange={(value) => setBmvCustomIdKeyword(value)}
              placeholder="BMV Custom Id (Min 2, Max 3) Example: BO"
            />
          </div>
        )}
        {release?.forceUploadType === NEW_RELEASE_TYPES.STATE_51 && (
          <div className="mt-8">
            <KInput
              width={300}
              value={state51BatchId}
              onChange={(value) => setState51BatchId(value)}
              placeholder="Batch ID"
            />
          </div>
        )}
        {release?.forceUploadType === NEW_RELEASE_TYPES.MESAM_MSG && (
          <div className="mt-8 flex gap-4">
            <KInput
              width={300}
              value={userPermission}
              onChange={(value) => setUserPermission(value)}
              placeholder="Mesam (5)/ MSG (6), Enter 5 or 6 only"
            />
            <KInput
              width={300}
              value={publishingShare}
              onChange={(value) => setPublishingShare(value)}
              placeholder="Publishing Share"
            />
          </div>
        )}
      </div>
    )
  }

  const renderEditorialPitchingView = () => {
    return (
      <div className="flex flex-col gap-2 items-center justify-center" style={{ maxWidth: 800 }}>
        <span className="w-[96px] aspect-square rounded-[48px] shadow-md flex justify-center items-center">
          <img className="w-12 h-12" src="/account_icons/music-note-sparkle.svg"></img>
        </span>
        <KTitleSpan text={`Upload ${release?.forceUploadType}`} fontSize={24} lineHeight="32px" />
        <div className="mt-8">
          <div className="flex justify-between gap-20 mb-3">
            <KSpan text="ISRC:" fontWeight={600} />
            <KSpan text={spotifyEditorialPitching.trackIsrc || "-"} fontWeight={400} />
          </div>
          <div className="flex justify-between gap-20 mb-3">
            <KSpan text="Genre:" fontWeight={600} />
            <KSpan text={spotifyEditorialPitching.genre.join(", ")} fontWeight={400} />
          </div>
          <div className="flex justify-between mb-3 gap-20">
            <KSpan text="Mood:" fontWeight={600} />
            <KSpan text={spotifyEditorialPitching.mood.join(", ")} fontWeight={400} />
          </div>
          <div className="flex justify-between mb-3 gap-20">
            <KSpan text="Style:" fontWeight={600} />
            <KSpan text={spotifyEditorialPitching.style.join(", ")} fontWeight={400} />
          </div>
          <div className="flex justify-between mb-3 gap-20">
            <KSpan text="Instrument:" fontWeight={600} />
            <KSpan text={spotifyEditorialPitching.instrumentation.join(", ")} fontWeight={400} />
          </div>
          <div className="flex justify-between gap-20">
            <KSpan text="Description:" fontWeight={600} />
            <KSpan text={spotifyEditorialPitching.description} fontWeight={400} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Dialog open={release !== undefined} onClose={() => setRelease(undefined)}>
      {loading && <Loading />}
      <div className="fixed w-screen h-screen top-0 left-0 z-250 flex items-center justify-center bg-[#0000004d] ">
        <div className="p-6 shadow-md rounded-[10px] bg-[#fff]" style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <div className="flex flex-col gap-6">
            {release?.forceUploadType === NEW_RELEASE_TYPES.EDITORIAL_PITCHING
              ? renderEditorialPitchingView()
              : renderAuidoFileView()}
            <div className="flex gap-3 justify-center items-center mt-4">
              <KButton text="Cancel" onClick={() => setRelease(undefined)} background="#fff" shadowDisabled={true} />
              <KButton
                text="Submit"
                textColor="white"
                background="black"
                disabled={checkIfSubmitDisabled()}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ForceUploadModal
